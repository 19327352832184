import { API_URL } from './AppConstants'

export const NUMBER_OF_ITEMS_TO_LOAD = 25

// -----------------------------------------------------------------------------
// Action constants
// -----------------------------------------------------------------------------

export const USER_DATA_SAVE_TO_FAVS_ACTION = 'USER_DATA_SAVE_TO_FAVS_ACTION'

export const USER_DATA_PUSH_LAST_ACTION = 'USER_DATA_PUSH_LAST_ACTION'

export const USER_DATA_SAVE_TO_LIST_ACTION = 'USER_DATA_SAVE_TO_LIST_ACTION'

export const USER_DATA_REMOVE_FROM_LIST_ACTION =
	'USER_DATA_REMOVE_FROM_LIST_ACTION'

export const USER_DATA_ACCEPT_COOKIES_ACTION = 'USER_DATA_ACCEPT_COOKIES_ACTION'

export const USER_DATA_EMPTY_USER_LIST_ACTION =
	'USER_DATA_EMPTY_USER_LIST_ACTION'

export const USER_DATA_EMPTY_FAVS_LIST_ACTION =
	'USER_DATA_EMPTY_FAVS_LIST_ACTION'

export const USER_DATA_LOAD_FAVS_START_ACTION =
	'USER_DATA_LOAD_FAVS_START_ACTION'

export const USER_DATA_LOAD_FAVS_SUCCESS_ACTION =
	'USER_DATA_LOAD_FAVS_SUCCESS_ACTION'

export const USER_DATA_LOAD_FAILURE_ACTION = 'USER_DATA_LOAD_FAILURE_ACTION'

export const USER_DATA_LOAD_SEARCHES_START_ACTION =
	'USER_DATA_LOAD_SEARCHES_START_ACTION'

export const USER_DATA_LOAD_SEARCHES_SUCCESS_ACTION =
	'USER_DATA_LOAD_SEARCHES_SUCCESS_ACTION'

export const USER_DATA_LOAD_SEARCHES_FAILURE_ACTION =
	'USER_DATA_LOAD_SEARCHES_FAILURE_ACTION'

export const USER_DATA_FAVS_AND_SEARCHES_COUNT_ACTION =
	'USER_DATA_FAVS_AND_SEARCHES_COUNT_ACTION'

export const USER_DATA_SYNC_IN_PROGRESS_ACTION =
	'USER_DATA_SYNC_IN_PROGRESS_ACTION'

export const USER_DATA_UPDATED_FAVS_COUNT_ACTION =
	'USER_DATA_UPDATED_FAVS_COUNT_ACTION'

export const USER_DATA_NEW_OFFERS_COUNT_ACTION =
	'USER_DATA_NEW_OFFERS_COUNT_ACTION'

export const USER_DATA_LOAD_UPDATED_FAVS_START =
	'USER_DATA_LOAD_UPDATED_FAVS_START'

export const USER_DATA_LOAD_UPDATED_FAVS_SUCCESS =
	'USER_DATA_LOAD_UPDATED_FAVS_SUCCESS'

export const USER_DATA_LOAD_UPDATED_FAVS_FAILURE =
	'USER_DATA_LOAD_UPDATED_FAVS_FAILURE'

export const USER_DATA_LOAD_NEW_OFFERS_START = 'USER_DATA_LOAD_NEW_OFFERS_START'

export const USER_DATA_LOAD_NEW_OFFERS_SUCCESS =
	'USER_DATA_LOAD_NEW_OFFERS_SUCCESS'

export const USER_DATA_LOAD_NEW_OFFERS_FAILURE =
	'USER_DATA_LOAD_NEW_OFFERS_FAILURE'

// -----------------------------------------------------------------------------
// URL constants
// -----------------------------------------------------------------------------

export const LOAD_FAVS_URL = `${API_URL}/ajax/ulozene/nabidky/?take=25`

export const LOAD_SEARCHES_URL = `${API_URL}/ajax/ulozene/hledani/`

export const SAVE_TO_FAVS_URL = `${API_URL}/moje-reality/bookmark`

export const EMPTY_FAVS_URL = `${API_URL}/moje-reality/nabidky/vycistit/`

export const EMPTY_SEARCHES_URL = `${API_URL}/moje-reality/hledani/vycistit`

export const SAVE_SEARCH_URL = `${API_URL}/moje-reality/profil-0/zalozit/`

export const REMOVE_SEARCH_URL = `${API_URL}/moje-reality/profil-`

export const UPDATED_FAVS_COUNT_URL = `${API_URL}/ajax/notifikace/pocet/zmeny/`

export const NEW_OFFERS_COUNT_URL = `${API_URL}/ajax/notifikace/pocet/novinky/`

export const UPDATED_FAVS_URL = `${API_URL}/ajax/odeslane/zmeny/?take=${NUMBER_OF_ITEMS_TO_LOAD}`

export const NEW_OFFERS_URL = `${API_URL}/ajax/odeslane/novinky/?take=${NUMBER_OF_ITEMS_TO_LOAD}`

export const MARK_UPDATED_AS_READ_URL = `${API_URL}/ajax/notifikace/precteno/zmeny/`

export const MARK_NEW_AS_READ_URL = `${API_URL}/ajax/notifikace/precteno/novinky/`
