import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './DeleteAccount.module.scss'
import Page from '../../component/Page'
import Button from '../../component/Button'
import OpenCardHeader from '../../component/OpenCardHeader'
import Form from '../../component/Form'
import Title from '../../component/Title'
import Field from '../../component/Field'
import Row from '../../component/Row'
import Col from '../../component/Col'
import InputPassword from '../../component/InputPassword'
import Overlay from '../../component/Overlay'

class DeleteAccount extends Component {
	constructor(props) {
		super(props)

		this.state = {
			data: {
				password: '',
			},
			wasSent: false,
			isLoading: false,
		}
	}

	onClose = () => {
		this.props.actions.closeOpenCard()
	}

	removeAccount = (e) => {
		e.preventDefault()

		const password = this.state.data.password
		const verifiedBySN = this.props.verifiedBySN
		if (!password && !verifiedBySN) {
			return
		}

		this.setState({ isLoading: true })

		this.props.actions
			.deleteAccount(password, verifiedBySN)
			.then(() => {
				this.setState({ isLoading: false })
				this.onClose()
				this.context.router.push('/')
			})
			.catch(() => {
				this.setState({ wasSent: true, isLoading: false })
			})
	}

	handleChange = (e) => {
		this.setState({ data: { password: e.target.value } })
	}

	render() {
		const verifiedBySN = this.props.verifiedBySN

		return (
			<div className={styles.base}>
				<div className={styles.section}>
					<Page>
						<OpenCardHeader onBack={this.onClose} title="Zrušení účtu" />

						<Form onSubmit={this.removeAccount} className={styles.content}>
							{!verifiedBySN && (
								<Row>
									<Col xs="12/12">
										<Title type="p6" className={styles.title} display="block">
											Zadejte své aktuální platné heslo
										</Title>
									</Col>
								</Row>
							)}

							{!verifiedBySN && (
								<Row>
									<Col xs="12/12" className={styles['form-group']}>
										<Field label="">
											<InputPassword
												id="password"
												onChange={this.handleChange}
												required={!verifiedBySN}
											/>
											{this.state.wasSent && (
												<div className={styles['error']}>
													{this.props.error}
												</div>
											)}
										</Field>
									</Col>
								</Row>
							)}

							{verifiedBySN && (
								<Row>
									<Col xs="12/12">
										<Title type="p6" className={styles.title} display="block">
											Opravdu chcete zrušit účet?
										</Title>
									</Col>
								</Row>
							)}

							<Row className={styles['button-row']}>
								<Col xs="12/12">
									<Button formType="submit" size="cover" type="negative">
										Zrušit účet
									</Button>
								</Col>
							</Row>
						</Form>

						<Overlay shown={this.state.isLoading} withSpinner />
					</Page>
				</div>
			</div>
		)
	}
}

DeleteAccount.propTypes = {
	verifiedBySN: PropTypes.bool,
	actions: PropTypes.object,
	error: PropTypes.string,
}

DeleteAccount.contextTypes = {
	router: PropTypes.object,
}

export default DeleteAccount
