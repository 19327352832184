import React from 'react'
import PropTypes from 'prop-types'
import styles from './CheckBoxMatrix.module.scss'
import Checkbox from '../CheckBox'

const CheckBoxMatrix = (props) => {
	const isChecked = (valueToCheck) =>
		props.selected &&
		!!props.selected.find(
			(selectedValue) => selectedValue.url === valueToCheck.url
		)

	return (
		<div className={styles.base}>
			<div className={styles.row}>
				{props.data.map((v, k) => {
					return (
						<div
							key={`${k}${v.url}`}
							className={isChecked(v) ? styles.selected : styles.item}>
							<Checkbox
								id={props.prefix + k}
								onClick={() => props.onClick(v)}
								checked={isChecked(v)}
								label={props.data[k].v}
							/>
						</div>
					)
				})}
			</div>
		</div>
	)
}

CheckBoxMatrix.defaultProps = {
	prefix: '',
}

CheckBoxMatrix.propTypes = {
	data: PropTypes.array.isRequired,
	selected: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	onClick: PropTypes.func,
	prefix: PropTypes.string,
}

export default CheckBoxMatrix
