import Hamburger from './Hamburger'

import { connect } from 'react-redux'
import { sendAxiosErrorValue } from '../../utils/fetcher'

const mapStateToProps = (state) => {
	if (!state.userData || !state.userData.get) {
		sendAxiosErrorValue({
			context: 'state.userData-Hamburger',
			type: typeof state.userData,
			data: state.userData,
			additional: state,
		})
	}
	return {
		updatedFavsSize: state.userData.get('updatedFavsSize'),
		newOffersSize: state.userData.get('newOffersSize'),
	}
}

export default connect(mapStateToProps)(Hamburger)
