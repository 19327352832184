import React from 'react'
import PropTypes from 'prop-types'
import styles from './FieldHelpIcon.module.scss'
import createClassName from '../../utils/createClassName'

const FieldHelpIcon = (props) => {
	let p = createClassName(props, styles)
	let iconClass = styles.base + p('type') + p('className', false)
	let svg
	switch (props.type) {
		case 'positive':
			svg = (
				<svg
					className="positive"
					xmlns="http://www.w3.org/2000/svg"
					version="1.1"
					id="Layer_1"
					x="0px"
					y="0px"
					width="20px"
					height="20px"
					fill="#288E28"
					viewBox="0 0 512 512"
					enableBackground="new 0 0 512 512">
					<polygon points="432,48 192,288 80,176 0,256 192,448 512,128 " />
				</svg>
			)
			break
		case 'negative':
			svg = (
				<svg
					className="negative"
					xmlns="http://www.w3.org/2000/svg"
					width="18px"
					height="18px"
					viewBox="0 0 512 512"
					fill="#B23232">
					<path
						d={`M 491.536,108.384l-147.152,148.048l 147.152,147.168c 6.16,6.144, 6.16,16.112,0.00,22.272 l-66.816,
          66.80c-6.128,6.16-16.096,6.16-22.256,0.00l-146.912-146.912l-146.032,146.912c-6.144,6.16-16.112,6.16-22.272,
          0.00l-66.80-66.80 c-6.144-6.16-6.144-16.128,0.00-22.272l 146.032-146.912L 20.464,
          110.672c-6.144-6.144-6.144-16.112,0.00-22.272l 66.80-66.80 c 6.144-6.16, 16.112-6.16, 22.272,0.00l 145.76,
          145.76l 147.184-148.048c 6.16-6.16, 16.128-6.16, 22.256,0.00l 66.816,66.80 C 497.68,92.272, 497.68,102.24,
          491.536,108.384z`}
					/>
				</svg>
			)
			break
		default:
			svg = ''
	}
	return (
		<div className={iconClass} onClick={props.onClick}>
			{svg}
		</div>
	)
}

FieldHelpIcon.defaultProps = {}

FieldHelpIcon.propTypes = {
	type: PropTypes.oneOf(['positive', 'negative', 'default']),
	onClick: PropTypes.func,
}

export default FieldHelpIcon
