import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import { persistStore, autoRehydrate } from 'redux-persist'
import immutableTransform from 'redux-persist-transform-immutable'
import thunk from 'redux-thunk'
import reducers from './store/reducers'
import ConfigurationService from './service/ConfigurationService'
import * as Sentry from '@sentry/browser'
import NotificationRequestService from './service/NotificationRequestService'
import { migration } from './store/migrations'
import createSentryMiddleware from 'redux-sentry-middleware'

const isDevelopment = () => window.location.origin === 'http://localhost:3000'

export function configureStore(initialState = {}, history) {
	Sentry.init({ dsn: ConfigurationService.sentryKey })

	let middleware = applyMiddleware(
		thunk,
		routerMiddleware(history),
		NotificationRequestService.getNotificationMiddleware(),
		createSentryMiddleware(Sentry, {})
	)
	let store

	if (isDevelopment()) {
		const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

		if (typeof devToolsExtension === 'function') {
			middleware = compose(
				middleware,
				devToolsExtension()
			)
		}
	}

	Sentry.init({ dsn: ConfigurationService.sentryKey })

	store = createStore(
		reducers(),
		initialState,
		compose(
			migration(initialState),
			autoRehydrate(),
			middleware
		)
	)

	store.asyncReducers = {}

	const persistor = persistStore(store, {
		key: 'root',
		blacklist: [
			'addressApi',
			'detailApi',
			'listApi',
			'routing',
			'searchConfig',
			'openCard',
		],
		transforms: [immutableTransform()],
	})

	/**
	 * Subscribe to every state change.
	 * If user is authorized the persistor will be paused, so no data is
	 * written to the localStorage on state change.
	 */

	let savedStore = store.getState()

	let prevState
	let wasAuthenticated =
		savedStore.authApi && savedStore.authApi.get('isAuthenticated')

	store.subscribe(() => {
		let state = store.getState()
		let isAuthenticated = false
		if (state.authApi && state.authApi.hasOwnProperty('size')) {
			isAuthenticated = state.authApi.get('isAuthenticated')
		} else {
			isAuthenticated = state.authApi.isAuthenticated
		}

		if (isAuthenticated && !wasAuthenticated) {
			wasAuthenticated = true
			savedStore = prevState
			persistor.pause()
		} else if (!isAuthenticated && wasAuthenticated) {
			wasAuthenticated = false
			savedStore.display = state.display
			persistor.rehydrate(savedStore)
			persistor.resume()
		}

		prevState = state
	})

	if (module && module.hot) {
		module.hot.accept('./store/reducers', () => {
			const reducers = require('./store/reducers').default
			store.replaceReducer(reducers)
		})
	}

	return store
}
