import Wrapper from './Wrapper'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators({}, dispatch),
})

const mapStateToProps = (state) => ({
	opencard: state.openCard.get('openCard'),
})

export default connect(
	mapStateToProps,
	mapActionCreators
)(Wrapper)
