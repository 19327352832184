import React from 'react'
import PropTypes from 'prop-types'
import styles from './ApiImage.module.scss'
import { WWW_URL } from '../../constants/AppConstants'

const getOptimalImageSize = (div = 1) => {
	let dh = document.documentElement.clientHeight
	let dw = document.documentElement.clientWidth / div

	let w = dh > dw ? dh : dw
	let h = Math.floor((w * 10) / 16)

	return w + 'x' + h
}

const ApiImage = (props) => {
	const { background, onLoad, src, className, div, print } = props
	const path = `${WWW_URL}${src}?L` + getOptimalImageSize(div)
	let element = <div />

	if (!background) {
		element = <img src={path} onLoad={onLoad} className={className} alt="api" />
	} else if (!print) {
		element = (
			<div
				style={{ backgroundImage: 'url(' + path + ')' }}
				className={className}
			/>
		)
	} else {
		element = (
			<div className={styles.printable}>
				<div
					style={{ backgroundImage: 'url(' + path + ')' }}
					className={className}
				/>
				<img src={path} alt="api" />
			</div>
		)
	}

	return element
}

ApiImage.defaultProps = {
	height: 250,
	div: 1,
}

ApiImage.propTypes = {
	src: PropTypes.string,
	background: PropTypes.bool,
	onLoad: PropTypes.func,
	height: PropTypes.number,
	div: PropTypes.number,
	print: PropTypes.bool, // if true printable image is still available
}

export default ApiImage
