import addressApi from './AddressApiReducer'
import listApi from './ListApiReducer'
import detailApi from './DetailApiReducer'
import searchConfig from './SearchConfigReducer'
import userData from './UserDataReducer'
import appData from './AppDataReducer'
import display from './DisplayReducer'
import openCard from './OpenCardReducer'
import authApi from './AuthApiReducer'
import settings from './SettingsReducer'

export default {
  addressApi,
  searchConfig,
  listApi,
  detailApi,
  userData,
  appData,
  display,
  openCard,
  authApi,
  settings
}
