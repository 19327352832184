import React from 'react'
import PropTypes from 'prop-types'
import styles from './CookieBanner.module.scss'
import Paragraph from '../Paragraph/'
import Row from '../Row/'
import Col from '../Col/'
import Button from '../Button'

const CookieBanner = (props) => {
	// auto hidden after 10 seconds
	!props.cookie && setTimeout(props.actions.acceptCookies, 10000)

	return (
		<div
			className={styles.base + ' ' + (props.cookie ? styles.base__hidden : '')}>
			<Row>
				<Col xs="6/8">
					<Paragraph type="cookies">
						Využitím těchto webových stránek souhlasíte s použitím souborů
						cookie.
					</Paragraph>
				</Col>
				<Col xs="2/8">
					<div className={styles.button}>
						<Button onClick={props.actions.acceptCookies} size="small">
							ok
						</Button>
					</div>
				</Col>
			</Row>
		</div>
	)
}

CookieBanner.defaultProps = {}

CookieBanner.propTypes = {
	cookie: PropTypes.bool,
	actions: PropTypes.shape({
		acceptCookies: PropTypes.func,
	}),
}

export default CookieBanner
