import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './InfinityList.module.scss'
import { AutoSizer, InfiniteLoader, VirtualScroll } from 'react-virtualized'
import Title from '../Title'
import { Link } from 'react-router'
import getListCardHeight from '../../utils/getListCardHeight'

class InfinityList extends Component {
	constructor(props) {
		super(props)
		this.invalidHeight = true
		this.topHeight = 270
		this.isPortrait = this.getClientOrientation()
		this.virtualScroll = ''
		this.list = []
		this.to = 0
		this.scrollToIndex = 0
		this.actualWidth = 0
	}

	componentDidMount() {
		this.props.setInstance(this)
		this.topHeight = 0
		this.actualWidth = this.refs.wrapperLayout.clientWidth
		this.scrollToIndex = this.props.scrollTop
	}

	componentDidUpdate() {
		this.recaulculateHeight()
		this.actualWidth = this.refs.wrapperLayout.clientWidth
	}

	componentWillReceiveProps({ display }) {
		if (
			display.width !== this.props.display.width ||
			display.height !== this.props.display.height
		) {
			this.virtualScroll && this.virtualScroll.recomputeRowHeights()
		}
	}

	getClientOrientation = () => {
		return (
			process.env.BROWSER &&
			document.documentElement.clientHeight >
				document.documentElement.clientWidth
		)
	}

	recaulculateHeight = () => {
		let orientation = this.getClientOrientation()
		let willSetHeight = false

		this.invalidHeight = true
		if (this.refs.topList) {
			// console.log('top list is there')
			let tplheight = this.refs.topList.clientHeight
			if (tplheight > 0) {
				// console.log('tplheight > 0')
				this.invalidHeight = false
				// only if height change
				if (this.topHeight !== tplheight) {
					this.topHeight = tplheight
					willSetHeight = true
				}
			}
		}

		if (this.virtualScroll) {
			if (willSetHeight || orientation !== this.isPortrait) {
				this.isPortrait = orientation
				this.virtualScroll.recomputeRowHeights()
				this.forceUpdate()
				this.invalidHeight = false
			}
		}

		return this.topHeight
	}

	forceRender = () => {
		this.topHeight = 0
		this.recaulculateHeight()
	}

	loadMoreRows = ({ stopIndex }) => {
		let index = Math.floor(stopIndex / 25) * 25
		if (
			this.list.length > 0 &&
			index >= this.list.length - 1 &&
			!this.props.isLoading
		) {
			this.props.loadData({ skip: index }, false)
		}
	}

	getRowHeight = ({ index }) => {
		if (index === 0) {
			return (this.invalidHeight && this.recaulculateHeight()) || this.topHeight
		}

		// 100 = height of component Row with text under image
		return getListCardHeight(this.props.display) + 100
	}

	setRef = (ref) => {
		if (ref) {
			this.virtualScroll = ref
		}
	}

	render() {
		this.list = [
			<div ref="topList" key="infinity-list-default">
				{this.props.children}
			</div>,
			...this.props.list,
		]

		if (this.props.rowsCount > 0 && this.list.length >= this.props.rowsCount) {
			this.list.push(
				<div className={styles.nomore}>
					<Title type="h2" display="block">
						Více inzerátů není
					</Title>
					<Link to="/search">
						<Title type="p3" display="block" className={styles.link2out}>
							Změnit parametry vyhledávání
						</Title>
					</Link>
				</div>
			)
		}

		return (
			<div
				className={styles.base}
				data-super={this.list.length}
				ref="wrapperLayout">
				<InfiniteLoader
					isRowLoaded={({ index }) => !!this.list[index]}
					loadMoreRows={this.loadMoreRows}
					rowCount={this.props.rowsCount}>
					{({ onRowsRendered }) => (
						<AutoSizer onResize={this.recaulculateHeight}>
							{({ height, width }) => (
								<VirtualScroll
									className={styles.virtualScroll}
									ref={this.setRef}
									width={process.env.BROWSER ? width : this.actualWidth}
									height={process.env.BROWSER ? height : 10000}
									scrollToIndex={this.scrollToIndex}
									rowCount={this.list.length}
									rowHeight={this.getRowHeight}
									onScroll={this.onScroll}
									rowRenderer={({ index, isScrolling }) =>
										isScrolling ? this.list[index] : this.list[index]
									}
									overscanRowCount={0}
									onRowsRendered={(res) => {
										onRowsRendered(res)
										this.scrollToIndex = -1
										/**/
										if (res.overscanStartIndex === 0 && this.invalidHeight) {
											this.recaulculateHeight()
										}
										/**/
									}}
								/>
							)}
						</AutoSizer>
					)}
				</InfiniteLoader>
			</div>
		)
	}
}

InfinityList.defaultProps = {}

InfinityList.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	rowsCount: PropTypes.number,
	list: PropTypes.array,
	loadData: PropTypes.func,
	children: PropTypes.node,
	scrollTop: PropTypes.number,
	display: PropTypes.object,
	setInstance: PropTypes.func,
}

export default InfinityList
