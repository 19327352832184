import React from 'react'
import PropTypes from 'prop-types'
import styles from './SearchFormAddressList.module.scss'
import regions from '../../utils/regions.json'
import getAddressName from '../../utils/getAddressName'

function groupAddress(address) {
	let result = {}
	for (let j in address) {
		let found = false

		if (!address[j]) {
			continue
		}

		for (let i in regions.regions) {
			if (regions.regions[i].url === address[j].url) {
				if (!result[address[j].url]) {
					found = true
					result[address[j].url] = {
						name: regions.regions[i].name,
						url: regions.regions[i].url,
						parts: [],
					}
					break
				}
			} else {
				for (let k in regions.regions[i].parts) {
					if (regions.regions[i].parts[k].url === address[j].url) {
						if (!result[regions.regions[i].url]) {
							found = true
							result[regions.regions[i].url] = {
								name: regions.regions[i].name,
								url: regions.regions[i].url,
								parts: [address[j]],
							}
							break
						} else {
							found = true
							result[regions.regions[i].url].parts.push(address[j])
							break
						}
					}
				}

				if (found) {
					break
				}
			}
		}

		// not found
		if (!found) {
			result[address[j].url] = {
				name: address[j].fullname,
				url: address[j].url,
				parts: [],
			}
		}
	}

	return Object.keys(result).map((k) => result[k])
}

function SearchFormAddressList(props) {
	return (
		<div className={styles.base}>
			{groupAddress(props.address).map((v, k) => {
				return (
					<div
						className={
							v.parts.length > 0
								? styles.addressitem__twoline
								: styles.addressitem
						}
						key={k}>
						<strong>{v.name || v.url.replace(/\./g, ' ')}</strong>
						<div className={styles.sublist}>
							{v.parts.map((p, l) => {
								return (
									<span key={l}>
										{p.fullname || getAddressName(p.url).fullname}
									</span>
								)
							})}
						</div>
						<span
							className={styles.removeAddress}
							onClick={() =>
								props.onRemove([
									{ url: v.url },
									...v.parts.map((p) => ({ url: p.url })),
								])
							}
						/>
					</div>
				)
			})}
		</div>
	)
}

SearchFormAddressList.defaultProps = {}

SearchFormAddressList.propTypes = {
	address: PropTypes.array,
	onRemove: PropTypes.func,
}

export default SearchFormAddressList
