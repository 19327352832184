import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './SearchFormKinds.module.scss'
import { PROPERTY_TYPE } from '../../constants/SearchConfigConstants'

class SearchFormKinds extends Component {
	onSelect = (kind) => {
		this.props.actions.setKind(kind)
		this.props.onChange()
	}

	render() {
		return (
			<div className={styles.base}>
				<div
					onClick={() => this.onSelect(PROPERTY_TYPE.FLATS)}
					className={
						styles.flat +
						' ' +
						(this.props.kind === PROPERTY_TYPE.FLATS
							? styles.selected
							: styles.item)
					}>
					<span
						className={
							this.props.kind === PROPERTY_TYPE.FLATS
								? styles.selected_dot
								: styles.dot
						}
					/>
					<span>Byty</span>
				</div>

				<div
					onClick={() => this.onSelect(PROPERTY_TYPE.HOUSES)}
					className={
						styles.house +
						' ' +
						(this.props.kind === PROPERTY_TYPE.HOUSES
							? styles.selected
							: styles.item)
					}>
					<span
						className={
							this.props.kind === PROPERTY_TYPE.HOUSES
								? styles.selected_dot
								: styles.dot
						}
					/>
					<span>Domy</span>
				</div>

				<div
					onClick={() => this.onSelect(PROPERTY_TYPE.RECREATION)}
					className={
						styles.recreation +
						' ' +
						(this.props.kind === PROPERTY_TYPE.RECREATION
							? styles.selected
							: styles.item)
					}>
					<span
						className={
							this.props.kind === PROPERTY_TYPE.RECREATION
								? styles.selected_dot
								: styles.dot
						}
					/>
					<span>Rekreace</span>
				</div>

				<div
					onClick={() => this.onSelect(PROPERTY_TYPE.LAND)}
					className={
						styles.area +
						' ' +
						(this.props.kind === PROPERTY_TYPE.LAND
							? styles.selected
							: styles.item)
					}>
					<span
						className={
							this.props.kind === PROPERTY_TYPE.LAND
								? styles.selected_dot
								: styles.dot
						}
					/>
					<span>Pozemky</span>
				</div>

				<div
					onClick={() => this.onSelect(PROPERTY_TYPE.COMMERCIAL)}
					className={
						styles.commercial +
						' ' +
						(this.props.kind === PROPERTY_TYPE.COMMERCIAL
							? styles.selected
							: styles.item)
					}>
					<span
						className={
							this.props.kind === PROPERTY_TYPE.COMMERCIAL
								? styles.selected_dot
								: styles.dot
						}
					/>
					<span>Komerční</span>
				</div>

				<div
					onClick={() => this.onSelect(PROPERTY_TYPE.OTHERS)}
					className={
						styles.others +
						' ' +
						(this.props.kind === PROPERTY_TYPE.OTHERS
							? styles.selected
							: styles.item)
					}>
					<span
						className={
							this.props.kind === PROPERTY_TYPE.OTHERS
								? styles.selected_dot
								: styles.dot
						}
					/>
					<span>Ostatní</span>
				</div>
			</div>
		)
	}
}

SearchFormKinds.defaultProps = {}

SearchFormKinds.propTypes = {
	onChange: PropTypes.func.isRequired,
	kind: PropTypes.string,
	actions: PropTypes.shape({
		setKind: PropTypes.func,
	}),
}

export default SearchFormKinds
