import * as Immutable from 'immutable'
import { createAction, handleActions } from 'redux-actions'

// -----------------------------------------------------------------------------
// Initial state
// -----------------------------------------------------------------------------

const initialState = Immutable.fromJS({
	adaptiveFilterIsOpen: true,
	scrollTop: 0,
	modal: '',
	modal_state: false,
	modal_input: '',
})

// -----------------------------------------------------------------------------
// Actions
// -----------------------------------------------------------------------------

export const toggleAdaptiveFilter = createAction('TOGGLE_ADAPTIVE_FILTER')
export const setScrolltop = createAction('SET_SCROLL_TOP')
export const setModal = createAction('SET_MODAL')
export const setModalState = createAction('SET_MODAL_STATE')
export const setModalInput = createAction('SET_MODAL_INPUT')

// -----------------------------------------------------------------------------
// Action handlers
// -----------------------------------------------------------------------------

export default handleActions(
	{
		TOGGLE_ADAPTIVE_FILTER: (state) =>
			state.merge({
				adaptiveFilterIsOpen: !state.get('adaptiveFilterIsOpen'),
			}),

		SET_SCROLL_TOP: (state, action) =>
			state.merge({
				scrollTop: action.payload,
			}),

		SET_MODAL: (state, action) =>
			state.merge({
				modal: action.payload,
			}),

		SET_MODAL_STATE: (state, action) =>
			state.merge({
				modal_state: action.payload,
			}),

		SET_MODAL_INPUT: (state, action) =>
			state.merge({
				modal_input: action.payload,
			}),
	},
	initialState
)
