import React from 'react'
import PropTypes from 'prop-types'
import styles from './LastSearchItem.module.scss'
import realityUrlBuilder from '../../utils/realityUrlBuilder'
import { Link } from 'react-router'
import { trackGoogleAnalytics } from '../../App'
import { OfferNames, KindNames } from '../../constants/SearchConfigConstants'

const LastSearchItem = (props) => {
	const { children, data } = props

	const getTextItem = () => {
		let texts =
			(data.disposition &&
				data.disposition.length > 0 &&
				data.disposition.map((v) => v.v)) ||
			[]

		data.offer && texts.push(OfferNames[data.offer] || data.offer)
		data.kind && texts.push(KindNames[data.kind] || data.kind)
		data.address[0] && texts.push(data.address[0].fullname)

		return texts
	}

	return (
		<Link
			to={realityUrlBuilder(data)}
			className={styles.item}
			onClick={() => {
				trackGoogleAnalytics([
					'send',
					'event',
					'home',
					'otevreni posledniho hledani',
				])
			}}>
			{children}
			{getTextItem().map((v, k) => {
				return (
					<span key={k} className={styles.part}>
						{v}
					</span>
				)
			})}
		</Link>
	)
}

LastSearchItem.defaultProps = {}

LastSearchItem.propTypes = {
	children: PropTypes.node,
	data: PropTypes.any,
}

export default LastSearchItem
