export const SelectionTypes = [
	'firma',
	'drazby',
	'zahranici',
	'novinky',
	'co-uz-nekoupite',
	'slevy',
	'top50',
	'makleri-doporucuji',
	'nabidky',
]

export const OFFER_TYPE = {
	SELL: 'prodej',
	RENT: 'pronajem',
}

export const OfferTypes = [OFFER_TYPE.SELL, OFFER_TYPE.RENT]

export const OfferNames = {
	[OFFER_TYPE.RENT]: 'pronájem',
}

export const PROPERTY_TYPE = {
	FLATS: 'byty',
	HOUSES: 'domy',
	RECREATION: 'rekreace',
	LAND: 'pozemky',
	COMMERCIAL: 'komercni',
	OTHERS: 'ostatni',
}

export const KindTypes = [
	PROPERTY_TYPE.FLATS,
	PROPERTY_TYPE.HOUSES,
	PROPERTY_TYPE.RECREATION,
	PROPERTY_TYPE.LAND,
	PROPERTY_TYPE.COMMERCIAL,
	PROPERTY_TYPE.OTHERS,
]

export const KindNames = {
	[PROPERTY_TYPE.COMMERCIAL]: 'komerční',
	[PROPERTY_TYPE.OTHERS]: 'ostatní',
}

export const PRICE = 'cena-'

export const FILTER_ORDER_TYPE = {
	BEST_MATCH: '1',
	NEWEST: '2',
	CHEAPEST: '3',
	MOST_EXPENSIVE: '4',
	CLOSEST: '5',
}

export const disposition = [
	{ v: 'garsonka', url: 11 },
	{ v: '1+kk', url: 12 },
	{ v: '1+1', url: 13 },
	{ v: '2+kk', url: 14 },
	{ v: '2+1', url: 15 },
	{ v: '3+kk', url: 16 },
	{ v: '3+1', url: 17 },
	{ v: '4+kk', url: 18 },
	{ v: '4+1', url: 19 },
	{ v: '5+kk', url: 20 },
	{ v: '5+1 a větší', url: 21 },
	{ v: 'loft', url: 22 },
]

export const dispositionHouse = [
	{ v: '1+kk', url: 11 },
	{ v: '1+1', url: 12 },
	{ v: '2+kk', url: 13 },
	{ v: '2+1', url: 14 },
	{ v: '3+kk', url: 15 },
	{ v: '3+1', url: 16 },
	{ v: '4+kk', url: 17 },
	{ v: '4+1', url: 18 },
	{ v: '5+kk', url: 20 },
	{ v: '5+1', url: 21 },
	{ v: '6+kk', url: 23 },
	{ v: '6+1', url: 24 },
	{ v: '7+kk', url: 26 },
	{ v: '7+1', url: 27 },
	{ v: '8+kk', url: 29 },
	{ v: '8+1', url: 30 },
	{ v: '9+1 a větší', url: 3132 },
]

export const apartmentArea = [
	{ v: '0 - 40 m²', url: 11 },
	{ v: '40 - 50 m²', url: 12 },
	{ v: '50 - 60 m²', url: 13 },
	{ v: '60 - 70 m²', url: 14 },
	{ v: '70 - 80 m²', url: 15 },
	{ v: '80 - 100 m²', url: 16 },
	{ v: '100 - 150 m²', url: 17 },
	{ v: 'nad 150 m²', url: 18 },
]

export const floor = [
	{ v: '0 - 50 m²', url: 11 },
	{ v: '50 - 100 m²', url: 12 },
	{ v: '100 - 150 m²', url: 13 },
	{ v: '150 - 200 m²', url: 14 },
	{ v: '200 - 250 m²', url: 15 },
	{ v: '250 - 350 m²', url: 16 },
	{ v: '350 - 500 m²', url: 17 },
	{ v: '500 - 700 m²', url: 18 },
	{ v: '700 - 1000 m²', url: 19 },
	{ v: '1 000 - 2 000 m²', url: 20 },
	{ v: 'nad 2.000 m²', url: 21 },
]

export const ground = [
	{ v: '0 - 500 m²', url: 11 },
	{ v: '500 - 750 m²', url: 12 },
	{ v: '750 - 1 000 m²', url: 13 },
	{ v: '1 000 - 1 250 m²', url: 14 },
	{ v: '1 250 - 1 500 m²', url: 15 },
	{ v: '1 500 - 2 000 m²', url: 16 },
	{ v: '2 000 - 3 000 m²', url: 17 },
	{ v: '3 000 - 5 000 m²', url: 18 },
	{ v: '5 000 - 10 000 m²', url: 19 },
	{ v: '10 000 - 20 000 m²', url: 20 },
	{ v: '20 000 - 50 000 m²', url: 21 },
	{ v: 'nad 50 000 m²', url: 22 },
]

export const others = [
	{ v: 'Výtah', url: 'h11' },
	{ v: 'Zařízení nábytkem', url: 'i1113' },
	{ v: 'Parkovací stání', url: 'U111215' },
	{ v: 'Sklep', url: 'X111314' },
]

export const othersHouse = [
	{ v: 'Garáž', url: 'S111314' },
	{ v: 'Sklep', url: 'X111314' },
]

// query D
export const types = [
	{ v: 'Venkovské stavení', url: 8 },
	{ v: 'Rodinný dům', url: 9 },
	{ v: 'Rodinná vila', url: 10 },
	{ v: 'Nájemní dům', url: 11 },
]

// query D (same as types)
export const kindofplot = [
	{ v: 'pozemky zemědělské a lesní', url: 1 },
	{ v: 'zahrady', url: 2 },
	{ v: 'Stavební pozemky pro bydlení a rekreaci', url: 3 },
	{ v: 'Stavební pozemky pro komerční využití', url: 4 },
]

// query D (same as types)
export const typeOfProperty = [
	{ v: 'garáž', url: 13 },
	{ v: 'památky', url: 20 },
	{ v: 'zvláštní nemovitosti', url: 21 },
]

export const ownership = [
	{ v: 'osobní', url: 11 },
	{ v: 'družstevní', url: 12 },
	{ v: 'ostatní', url: 13 },
]

export const buildingtype = [
	{ v: 'cihlová', url: 11 },
	{ v: 'panelová', url: 12 },
	{ v: 'skeletová', url: 13 },
	{ v: 'dřevostavba', url: 14 },
	{ v: 'kamenná', url: 15 },
	{ v: 'montovaná', url: 16 },
]

export const recreation = [
	{ v: 'chata', url: 11 },
	{ v: 'chalupa', url: 12 },
	{ v: 'apartmán', url: 13 },
]

export const commercial = [
	{ v: 'restaurace, bary', url: 111213141516 },
	{ v: 'ubytovací zařízení', url: 1718192021 },
	{ v: 'kanceláře a služby', url: 222324 },
	{ v: 'Obchodní prostory', url: 2526 },
	{ v: 'Ostatní (výroba, sklad)', url: '272829303132333435' },
]

export const restriction = [
	{ v: 'jen novinky', url: 'i' },
	{ v: 'jen ve slevě', url: 's' },
]

export const basicTypes = [
	{ v: 'byty', url: PROPERTY_TYPE.FLATS },
	{ v: 'domy', url: PROPERTY_TYPE.HOUSES },
	{ v: 'rekreace', url: PROPERTY_TYPE.RECREATION },
	{ v: 'pozemky', url: PROPERTY_TYPE.LAND },
	{ v: 'komerční', url: PROPERTY_TYPE.COMMERCIAL },
	{ v: 'ostatní', url: PROPERTY_TYPE.OTHERS },
]
