import React from 'react'
import PropTypes from 'prop-types'
import blacklist from 'blacklist'
import styles from './Row.module.scss'

const Row = ({ children, className, gutter, style }) => {
	let rowStyle = {
		width: '100%',
		marginLeft: 0, // (gutter / -2),
		marginRight: 0, // (gutter / -2)
	}

	let props = blacklist(
		{ className, gutter, style },
		'className',
		'gutter',
		'style'
	)

	return (
		<div
			{...props}
			style={Object.assign({}, rowStyle, style)}
			className={styles.Row + '  ' + className}>
			{children}
		</div>
	)
}

Row.defaultProps = {
	gutter: 20,
	className: '',
}

Row.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	gutter: PropTypes.number,
	style: PropTypes.object,
}

export default Row
