import Home from './Home'
import VerifyAccount from './VerifyAccount'
import NotFound from './NotFound'
import List from './List'
import Lists from './Lists'
import Search from './Search'
import Favs from './Favs'
import ForgottenPassword from './ForgottenPassword'
import Settings from './Settings'
import Auth from './Auth'
import Detail from './Detail'

const Routes = {
	Home,
	VerifyAccount,
	NotFound,
	List,
	Lists,
	Search,
	Favs,
	ForgottenPassword,
	Settings,
	Auth,
	Detail,
}

export default Routes
