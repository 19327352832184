import SearchForm from './SearchForm'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { load, clearCounter } from '../../reducer/ListApiReducer'
import {
	reset,
	setFulltext,
	setOffer,
	setKind,
	setFrom,
	setTo,
	setDisposition,
	setFloor,
	setGround,
	setOthers,
	setOthersHouse,
	setTypes,
	setApartmentArea,
	removeAddress,
	setOwnerShip,
	setBuildingType,
	setDispositionHouse,
	setRecreation,
	setKindOfPlot,
	setCommercial,
	setTypeOfProperty,
	setRestriction,
	setReferrer,
	setUnit,
} from '../../reducer/SearchConfigReducer'
import { pushLast } from '../../reducer/UserDataReducer'
import { setOpenCard } from '../../reducer/OpenCardReducer'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators(
		{
			load,
			reset,
			setOpenCard,
			clearCounter,
			pushLast,
			setFulltext,
			setOffer,
			setKind,
			setFrom,
			setTo,
			setDisposition,
			setFloor,
			setGround,
			setOthers,
			setOthersHouse,
			setTypes,
			setApartmentArea,
			removeAddress,
			setOwnerShip,
			setBuildingType,
			setDispositionHouse,
			setRecreation,
			setKindOfPlot,
			setCommercial,
			setTypeOfProperty,
			setRestriction,
			setReferrer,
			setUnit,
		},
		dispatch
	),
})

const mapStateToProps = (state) => ({
	searchConfig: state.searchConfig,
	preCounter: state.listApi.get('data').count,
	preCounterIsLoading: state.listApi.get('isLoading'),
	opencard: state.openCard.get('openCard'),
})

export default connect(
	mapStateToProps,
	mapActionCreators
)(SearchForm)
