import {
	OFFER_TYPE,
	disposition as disp,
	dispositionHouse as dispHouse,
} from '../constants/SearchConfigConstants'

function isNumeric(n) {
	return !isNaN(parseFloat(n)) && isFinite(n)
}

/**
 * Finding text description according to the value in the url.
 * @params {Array} list List of values by consts.js
 * @params {url} string Parameter from url
 * @return {string} text description of url parameter
 */
function getValueByUrl(list, url) {
	for (let i in list) {
		if (list[i].url === url) {
			return list[i].v
		}
	}
}

function parseQueryF(query) {
	let disposition = []
	let dispositionHouse = []
	let apartmentArea = []
	let ownership = []
	let buildingtype = []
	let others = []
	let othersHouse = []
	let floor = []
	let ground = []
	let recreation = []
	let commercial = []
	let q = []
	let key = ''
	let combine = []

	for (let i in query) {
		if (!isNumeric(query[i])) {
			key = query[i]
			q[key] = ''
		} else {
			q[key] += query[i]
		}
	}

	for (let i in q) {
		for (let j = 0; j < q[i].length; j++) {
			if (j % 2 === 0) {
				switch (i) {
					case 'D':
						disposition.push({
							url: q[i][j] + '' + q[i][j + 1],
							v: getValueByUrl(disp, q[i][j] + '' + q[i][j + 1]),
						})
						break
					case 'E':
						// exception for 4 decimal number 3132
						if (
							q[i][j] === '3' &&
							q[i][j + 1] === '1' &&
							q[i][j + 2] === '3' &&
							q[i][j + 3] === '2'
						) {
							dispositionHouse.push({
								url: '3132',
								v: getValueByUrl(dispHouse, 3132),
							})
							break
						}
						dispositionHouse.push({
							url: q[i][j] + '' + q[i][j + 1],
							v: getValueByUrl(dispHouse, q[i][j] + '' + q[i][j + 1]),
						})
						break
					case 'F':
						apartmentArea.push({ url: q[i][j] + '' + q[i][j + 1] })
						break
					case 'J':
						floor.push({ url: q[i][j] + '' + q[i][j + 1] })
						break
					case 'j':
						recreation.push({ url: q[i][j] + '' + q[i][j + 1] })
						break
					case 'k':
						// exception for more decimal number
						if (q[i].substr(j, 18) === '272829303132333435') {
							commercial.push({ url: '272829303132333435' })
							break
						}

						if (q[i].substr(j, 12) === '111213141516') {
							commercial.push({ url: '111213141516' })
							break
						}

						if (q[i].substr(j, 10) === '1718192021') {
							commercial.push({ url: '1718192021' })
							break
						}

						if (q[i].substr(j, 6) === '222324') {
							commercial.push({ url: '222324' })
							break
						}

						if (q[i].substr(j, 4) === '2526') {
							commercial.push({ url: '2526' })
							break
						}

						break
					case 'L':
						ground.push({ url: q[i][j] + '' + q[i][j + 1] })
						break
					case 'N':
						ownership.push({ url: q[i][j] + '' + q[i][j + 1] })
						break
					case 'Q':
						buildingtype.push({ url: q[i][j] + '' + q[i][j + 1] })
						break
					case 'h':
					case 'X':
					case 'i':
					case 'U':
					case 'S':
						if (!combine[i]) {
							others.push({ url: i + q[i] })
							othersHouse.push({ url: i + q[i] })
							combine[i] = true
						}
						break
					default:
						break
				}
			}
		}
	}

	let r = {
		disposition,
		dispositionHouse,
		apartmentArea,
		ownership,
		buildingtype,
		others,
		othersHouse,
		floor,
		ground,
		recreation,
		commercial,
	}

	return r
}

function parseQueryP(query) {
	let r = []
	query && query.split('').forEach((v) => r.push({ url: v }))
	return {
		restriction: r,
	}
}

function parseQueryD(query) {
	let t = []
	query && query.split(',').forEach((v) => t.push({ url: v }))
	return {
		types: t,
		kindofplot: t,
		typeOfProperty: t,
	}
}

function parseQueryX(query) {
	return {
		fulltext: query,
	}
}

function parseQueryGeo(params) {
	if (params.maplat && params.maplng) {
		return {
			geo: {
				maplat: params.maplat,
				maplng: params.maplng,
			},
		}
	}
}

/**
 * parse address in string and return them as an array
 * @param {String} s Mix of address separated by '_'
 * @return {Array} Address
 **/
export function parseAddress(s) {
	return s.split('_')
}

/**
 * parse disposition in string and return them as an array
 * @param {String} q Mix of params (in url is this query f) separated by a char
 * @return {Array} disposition
 **/
export function parseDisposition(q) {
	return parseQueryF(q).disposition
}

/**
 * parse URL queries and return same object as is in the state
 * @param {Array} params Url params
 * @return {Object}
 *
 **/
export default function(params) {
	var p = Object.assign(
		{
			selection: params.selection || '',
			seldata: params.seldata || '',
			offer: params.offer || '',
			kind: params.kind || '',
			address: params.address
				? parseAddress(params.address).map((addr) => ({
						url: addr,
				  }))
				: '',
			from: params.price ? params.price.split('-')[1] : 0,
			to: params.price ? params.price.split('-')[2] : 'max',
			unit: params.price
				? params.price.split('-')[3]
				: params.offer === OFFER_TYPE.SELL
				? 2
				: 4,
			order: params.s,
			skip: params.skip,
		},
		parseQueryF(params.f),
		parseQueryP(params.p),
		parseQueryD(params.d),
		parseQueryX(params.x),
		parseQueryGeo(params)
	)

	return p
}
