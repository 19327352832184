import React from 'react'
import PropTypes from 'prop-types'
import styles from './InputGroupAddon.module.scss'

const InputGroupAddon = (props) => (
	<div className={styles.base}>{props.children}</div>
)

InputGroupAddon.defaultProps = {}

InputGroupAddon.propTypes = {
	children: PropTypes.node,
}

export default InputGroupAddon
