import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import styles from './Menu.module.scss'
import Page from '../Page'
import BottomLinks from '../BottomLinks'

const Menu = (props) => (
	<nav className={styles.base}>
		<Page>
			{props.isAuthenticated && (
				<Link
					className={
						styles.link +
						' ' +
						(!props.loggedUser.picture
							? styles['profile-no-picture']
							: styles['profile-picture'])
					}
					to="/settings"
					onClick={props.onClick}>
					{props.loggedUser.picture && (
						<div
							style={{ backgroundImage: `url("${props.loggedUser.picture}")` }}
						/>
					)}

					{props.loggedUser.first_name && props.loggedUser.last_name
						? `${props.loggedUser.first_name} ${props.loggedUser.last_name}`
						: props.loggedUser.email}
				</Link>
			)}

			{!props.isAuthenticated && (
				<Link
					className={styles.link + ' ' + styles.login}
					to="/auth"
					onClick={props.onClick}>
					Přihlášení
				</Link>
			)}

			<Link
				className={styles.link + ' ' + styles.search}
				to="/search"
				onClick={() => {
					// close menu
					props.onClick()
					// clear search form
					props.actions.reset()
				}}>
				Nové hledání
			</Link>

			<Link
				className={styles.link + ' ' + styles.list}
				to="/lists"
				onClick={props.onClick}>
				{props.isAuthenticated ? 'Moje hledání' : 'Uložená hledání'}

				<span
					className={
						styles.counter +
						' ' +
						(props.listSize === 0 ? styles.counter__zero : '')
					}>
					{props.listSize}
				</span>

				{props.newOffersSize ? (
					<span
						className={styles.counter + ' ' + styles['counter-notification']}>
						{props.newOffersSize}
					</span>
				) : null}
			</Link>

			<Link
				className={styles.link + ' ' + styles.favs}
				to="/favs"
				onClick={props.onClick}>
				{props.isAuthenticated ? 'Moje nabídky' : 'Oblíbené'}

				<span
					className={
						styles.counter +
						' ' +
						(props.favsSize === 0 ? styles.counter__zero : '')
					}>
					{props.favsSize}
				</span>

				{props.updatedFavsSize ? (
					<span
						className={styles.counter + ' ' + styles['counter-notification']}>
						{props.updatedFavsSize}
					</span>
				) : null}
			</Link>

			{props.isAuthenticated ? (
				<Link
					className={styles.link + ' ' + styles.settings}
					to="/settings"
					onClick={props.onClick}>
					Nastavení
				</Link>
			) : null}
		</Page>

		<BottomLinks />
	</nav>
)

Menu.propTypes = {
	favsSize: PropTypes.number,
	listSize: PropTypes.number,
	isAuthenticated: PropTypes.bool,
	updatedFavsSize: PropTypes.number,
	newOffersSize: PropTypes.number,
	loggedUser: PropTypes.object,
	onClick: PropTypes.func,
	actions: PropTypes.object,
}

export default Menu
