import React from 'react'
import PropTypes from 'prop-types'
import styles from './Hamburger.module.scss'

function Hamburger(props) {
	const { children, updatedFavsSize, newOffersSize } = props

	return (
		<div className={styles.base}>
			{children}
			<span className={props.isOpen ? styles.isOpen : styles.isClose}>
				<span />
				{!props.isOpen && (!!updatedFavsSize || !!newOffersSize) && (
					<div className={styles['notifications-badge']} />
				)}
			</span>
		</div>
	)
}

Hamburger.defaultProps = {}

Hamburger.propTypes = {
	children: PropTypes.node,
	isOpen: PropTypes.bool,
	updatedFavsSize: PropTypes.number,
	newOffersSize: PropTypes.number,
}

export default Hamburger
