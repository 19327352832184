import { push } from 'react-router-redux'
import RealityUrlBuilder from '../utils/realityUrlBuilder'
import * as Immutable from 'immutable'
import { createAction, handleActions } from 'redux-actions'
import { OFFER_TYPE } from '../constants/SearchConfigConstants'
import { sendAxiosErrorValue } from '../utils/fetcher'

// -----------------------------------------------------------------------------
// Initial state
// -----------------------------------------------------------------------------
const initialState = Immutable.fromJS({
	address: [] /* {url: 'hlavni-mesto-Praha', fullname: 'hlavní město Praha'} */,
	offer: OFFER_TYPE.SELL,
	kind: '',
	from: 0,
	to: 'max',
	unit: 0,
	disposition: [],
	floor: [],
	ground: [],
	others: [],
	othersHouse: [],
	types: [],
	apartmentArea: [],
	ownership: [],
	buildingtype: [],
	dispositionHouse: [],
	recreation: [],
	kindofplot: [],
	commercial: [],
	typeOfProperty: [],
	restriction: [],
	order: 0,
	geo: {
		maplat: 0,
		maplng: 0,
	},
	fulltext: '',
	referrer: '',
})

// -----------------------------------------------------------------------------
// Actions
// -----------------------------------------------------------------------------

export const setAddress = createAction('SET_ADDRESS')
export const toggleMultipleAddress = createAction('TOGGLE_MULTIPLE_ADDRESS')
export const setMultipleAddress = createAction('SET_MULTIPLE_ADDRESS')
export const setOffer = createAction('SET_OFFER')
export const setKind = createAction('SET_KIND')
export const setFrom = createAction('SET_FROM')
export const setTo = createAction('SET_TO')
export const setDisposition = createAction('SET_DISPOSITION')
export const setFloor = createAction('SET_FLOOR')
export const setGround = createAction('SET_GROUND')
export const setOthers = createAction('SET_OTHERS')
export const setOthersHouse = createAction('SET_OTHERS_HOUSE')
export const setTypes = createAction('SET_TYPES')
export const setApartmentArea = createAction('SET_APARTMENT_AREA')
export const removeAddress = createAction('REMOVE_ADDRESS')
export const setOrder = createAction('SET_ORDER')
export const setOwnerShip = createAction('SET_OWNERSHIP')
export const setBuildingType = createAction('SET_BUILDING_TYPE')
export const setDispositionHouse = createAction('SET_DISPOSITION_HOUSE')
export const setRecreation = createAction('SET_RECREATION')
export const setKindOfPlot = createAction('SET_KIND_OF_PLOT')
export const setCommercial = createAction('SET_COMMERCIAL')
export const setTypeOfProperty = createAction('SET_TYPE_OF_PROPERTY')
export const setRestriction = createAction('SET_RESTRICTION')
export const reset = createAction('RESET')
export const setGeoLocation = createAction('SET_GEO_LOCATION')
export const setFulltext = createAction('SET_FULLTEXT')
export const setReferrer = createAction('SET_REFERRER')
export const setUnit = createAction('SET_UNIT')
export const setAll = createAction('SET_ALL')

// -----------------------------------------------------------------------------
// Helper functions
// -----------------------------------------------------------------------------
function toggleListByURL(state, attr, url, payload) {
	const list = state.get(attr)
	const i = list.findIndex((v) => String(v.url) === String(url))
	const newValue = {}
	if (!list || !list.delete) {
		sendAxiosErrorValue({
			context: 'list.delete-SearchConfigReducer',
			type: typeof list,
			data: list,
			additional: attr,
		})
	}
	newValue[attr] = i > -1 ? list.delete(i) : list.push(payload)

	if (attr === 'address' && !list.hasOwnProperty('size')) {
		newValue[attr] = Immutable.List(list)
	}

	return newValue
}

function formatPrice(string) {
	return (string.replace(/[^0-9]/gim, '') + '.0')
		.replace(/(\d)(?=(\d{3})+\.)/g, '$1 ')
		.slice(0, -2)
}

export function updateConfigAndRedirect(updaterFn) {
	updaterFn()
	return (dispatch, getState) => {
		dispatch(push(RealityUrlBuilder(getState().searchConfig.toJS())))
	}
}

// -----------------------------------------------------------------------------
// Action handlers
// -----------------------------------------------------------------------------

export default handleActions(
	{
		SET_ADDRESS: (state, action) => {
			state.get('address').clear()

			return state.merge({
				address: Immutable.List([action.payload]),
			})
		},
		TOGGLE_MULTIPLE_ADDRESS: (state, action) => {
			return state.merge(
				toggleListByURL(state, 'address', action.payload.url, action.payload)
			)
		},
		SET_MULTIPLE_ADDRESS: (state, action) => {
			return state.merge({
				address: Immutable.List(action.payload),
			})
		},
		REMOVE_ADDRESS: (state, action) => {
			let address = state.get('address')
			const i = address.findIndex((v) => v.url === action.payload.url)

			if (i > -1) {
				if (address.hasOwnProperty('size')) {
					address = address.delete(i)
				} else {
					address.splice(i, 1)
				}
			}

			return state.merge({
				address: Immutable.List(address),
			})
		},
		SET_OFFER: (state, action) => {
			return state.merge({
				offer: action.payload,
			})
		},
		SET_KIND: (state, action) => {
			return state.merge({
				kind: action.payload,
			})
		},
		SET_FROM: (state, action) => {
			return state.merge({
				from: formatPrice(action.payload), // (isNaN(parseInt(action.payload)) ? '' : parseInt(action.payload))
			})
		},
		SET_TO: (state, action) => {
			return state.merge({
				to: action.payload === 'max' ? 'max' : formatPrice(action.payload),
			})
		},
		SET_ORDER: (state, action) => {
			return state.merge({
				order: action.payload,
			})
		},
		SET_FULLTEXT: (state, action) => {
			return state.merge({
				fulltext: action.payload,
			})
		},
		SET_DISPOSITION: (state, action) => {
			return state.merge(
				toggleListByURL(
					state,
					'disposition',
					action.payload.url,
					action.payload
				)
			)
		},
		SET_FLOOR: (state, action) => {
			return state.merge(
				toggleListByURL(state, 'floor', action.payload.url, action.payload)
			)
		},
		SET_GROUND: (state, action) => {
			return state.merge(
				toggleListByURL(state, 'ground', action.payload.url, action.payload)
			)
		},
		SET_OTHERS: (state, action) => {
			return state.merge(
				toggleListByURL(state, 'others', action.payload.url, action.payload)
			)
		},
		SET_OTHERS_HOUSE: (state, action) => {
			return state.merge(
				toggleListByURL(
					state,
					'othersHouse',
					action.payload.url,
					action.payload
				)
			)
		},
		SET_TYPES: (state, action) => {
			return state.merge(
				toggleListByURL(state, 'types', action.payload.url, action.payload)
			)
		},
		SET_APARTMENT_AREA: (state, action) => {
			return state.merge(
				toggleListByURL(
					state,
					'apartmentArea',
					action.payload.url,
					action.payload
				)
			)
		},
		SET_OWNERSHIP: (state, action) => {
			return state.merge(
				toggleListByURL(state, 'ownership', action.payload.url, action.payload)
			)
		},
		SET_BUILDING_TYPE: (state, action) => {
			return state.merge(
				toggleListByURL(
					state,
					'buildingtype',
					action.payload.url,
					action.payload
				)
			)
		},
		SET_DISPOSITION_HOUSE: (state, action) => {
			return state.merge(
				toggleListByURL(
					state,
					'dispositionHouse',
					action.payload.url,
					action.payload
				)
			)
		},
		SET_RECREATION: (state, action) => {
			return state.merge(
				toggleListByURL(state, 'recreation', action.payload.url, action.payload)
			)
		},
		SET_KIND_OF_PLOT: (state, action) => {
			return state.merge(
				toggleListByURL(state, 'kindofplot', action.payload.url, action.payload)
			)
		},
		SET_COMMERCIAL: (state, action) => {
			return state.merge(
				toggleListByURL(state, 'commercial', action.payload.url, action.payload)
			)
		},
		SET_TYPE_OF_PROPERTY: (state, action) => {
			return state.merge(
				toggleListByURL(
					state,
					'typeOfProperty',
					action.payload.url,
					action.payload
				)
			)
		},
		SET_RESTRICTION: (state, action) => {
			return state.merge(
				toggleListByURL(
					state,
					'restriction',
					action.payload.url,
					action.payload
				)
			)
		},
		SET_GEO_LOCATION: (state, action) => {
			return state.merge({
				geo: action.payload,
			})
		},
		SET_REFERRER: (state, action) =>
			state.merge({
				referrer: action.payload,
			}),
		SET_UNIT: (state, action) =>
			state.merge({
				unit: action.payload,
			}),
		RESET: (state) => {
			return (
				// keep data from geo location
				initialState.set('geo', state.get('geo'))
			)
		},
		SET_ALL: (state, action) => {
			let data = {}
			Object.keys(action.payload).forEach((key) => {
				let value = action.payload[key]

				data[key] = value instanceof Array ? Immutable.List(value) : value
			})
			return Immutable.Map(data).set('geo', state.get('geo'))
		},
	},
	initialState
)
