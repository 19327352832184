import Lists from './container/ListsContainer'
export default Lists

// export default (store) => ({
//   path: 'lists', // Path should be dash-lower-case
//   getComponent(nextState, next) {
//     require.ensure(
//       ['./container/ListsContainer'],
//       (require) => {
//         // Don't forget to add router to `src/route/index`
//         const Lists = require('./container/ListsContainer').default

//         next(null, Lists)
//       })
//   }
// })
