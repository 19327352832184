import * as Immutable from 'immutable'
import { createAction, handleActions } from 'redux-actions'
import { getUrl } from '../utils/getApiUrl'
import createFormUrlEncoded from '../utils/createFormUrlEncoded'
import { favsAndSearchesCount } from './UserDataReducer'
import {
	AUTH_API_LOAD_FAILURE_ACTION,
	AUTH_API_LOAD_START_ACTION,
	AUTH_API_LOAD_SUCCESS_ACTION,
	AUTH_API_LOGOUT_SUCCESS_ACTION,
	AUTH_API_DELETE_ACCOUNT_START_ACTION,
	VERIFY_ACCOUNT_START_ACTION,
	VERIFY_ACCOUNT_SUCCESS_ACTION,
	VERIFY_ACCOUNT_FAILURE_ACTION,
	RESET_PASSWORD_START_ACTION,
	RESET_PASSWORD_SUCCESS_ACTION,
	RESET_PASSWORD_FAILURE_ACTION,
	REGISTRATION_URL,
	LOGIN_WITH_FACEBOOK_URL,
	LOGIN_WITH_GOOGLE_URL,
	LOGIN_URL,
	DELETE_ACCOUNT_URL,
	VERIFY_ACCOUNT_URL,
	RESET_PASSWORD_URL,
} from '../constants/AuthConstants'
import { sendAxiosGetRequest, sendAxiosPostRequest } from '../utils/fetcher'

const initialState = Immutable.fromJS({
	loggedUser: null,
	isAuthenticated: false,
	isBeingVerified: false,
	verificationError: null,
	resetPasswordError: null,
	error: null,
})

const loginStart = createAction(AUTH_API_LOAD_START_ACTION)
const loginSuccess = createAction(AUTH_API_LOAD_SUCCESS_ACTION)
const loadFailure = createAction(AUTH_API_LOAD_FAILURE_ACTION)
const logoutSuccess = createAction(AUTH_API_LOGOUT_SUCCESS_ACTION)

const deleteAccountStart = createAction(AUTH_API_DELETE_ACCOUNT_START_ACTION)

const verifyAccountStart = createAction(VERIFY_ACCOUNT_START_ACTION)
const verifyAccountSuccess = createAction(VERIFY_ACCOUNT_SUCCESS_ACTION)
const verifyAccountFailure = createAction(VERIFY_ACCOUNT_FAILURE_ACTION)

const resetPasswordStart = createAction(RESET_PASSWORD_START_ACTION)
const resetPasswordSuccess = createAction(RESET_PASSWORD_SUCCESS_ACTION)
const resetPasswordFailure = createAction(RESET_PASSWORD_FAILURE_ACTION)

// -----------------------------------------------------------------------------
// LOCAL FUNCTIONS
// -----------------------------------------------------------------------------
function handleLoginResponse(dispatch, data) {
	if (data.err && data.err.length > 0) {
		dispatch(loadFailure(data))
	} else {
		dispatch(loginSuccess(data))
		dispatch(favsAndSearchesCount(data))
	}
}

function handleResetPasswordResponse(response) {
	return response.err && response.err.length > 0
		? resetPasswordFailure(response)
		: resetPasswordSuccess(response)
}

// -----------------------------------------------------------------------------
// SIDE EFFECTS
// -----------------------------------------------------------------------------
export function login({ email, password, token }) {
	return async (dispatch) => {
		const endpoint = getUrl(LOGIN_URL)
		dispatch(loginStart())

		try {
			const { data } = await sendAxiosPostRequest(endpoint, {
				mrregemail: email,
				mrregh: password,
				'g-recaptcha-response': token,
			})

			handleLoginResponse(dispatch, data)
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

export function register({ email, password, token }) {
	return async (dispatch) => {
		const endpoint = getUrl(REGISTRATION_URL)
		dispatch(loginStart())

		try {
			const { data } = await sendAxiosPostRequest(endpoint, {
				mrregemail: email,
				mrregh: password,
				'g-recaptcha-response': token,
				SEC_KEY: 'mobil',
			})

			handleLoginResponse(dispatch, data)
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

export function logout() {
	return (dispatch) => {
		document.cookie = `sid=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
		dispatch(logoutSuccess())
	}
}

export function loginWithFacebook(token) {
	return async (dispatch) => {
		const endpoint = getUrl(LOGIN_WITH_FACEBOOK_URL)
		dispatch(loginStart())

		try {
			const { data } = await sendAxiosPostRequest(endpoint, { fbtoken: token })
			handleLoginResponse(dispatch, data)
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

export function loginWithGoogle(token) {
	return async (dispatch) => {
		const endpoint = getUrl(LOGIN_WITH_GOOGLE_URL)
		dispatch(loginStart())

		try {
			const { data } = await sendAxiosPostRequest(endpoint, { gtoken: token })
			handleLoginResponse(dispatch, data)
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

/**
 * If the account has been created with Facebook or Google+,
 * current account password is not needed to confirm.
 */
export function deleteAccount(password, verifiedBySN) {
	return async (dispatch) => {
		const endpoint = getUrl(DELETE_ACCOUNT_URL)
		dispatch(deleteAccountStart())

		let body = ''
		if (!verifiedBySN) {
			body = createFormUrlEncoded({ confirm: password })
		}

		try {
			const { data } = await sendAxiosPostRequest(endpoint, body)
			const parsedResponse = typeof data === 'string' ? JSON.parse(data) : data

			if (parsedResponse.err) {
				dispatch(loadFailure(parsedResponse))
				return Promise.reject()
			} else {
				dispatch(logout())
				return Promise.resolve()
			}
		} catch (exception) {
			dispatch(loadFailure(exception))
			return Promise.reject()
		}
	}
}

export function verifyAccount(verificationToken) {
	return async (dispatch) => {
		const endpointUrl = getUrl(
			VERIFY_ACCOUNT_URL.replace(':verificationToken', verificationToken)
		)
		dispatch(verifyAccountStart())

		try {
			const { data } = await sendAxiosGetRequest(endpointUrl)
			dispatch(verifyAccountSuccess(data))
		} catch (exception) {
			dispatch(verifyAccountFailure(exception))
		}
	}
}

export function resetPassword({ email, token }) {
	return async (dispatch) => {
		const endpoint = getUrl(RESET_PASSWORD_URL)
		dispatch(resetPasswordStart())

		try {
			const { data } = await sendAxiosPostRequest(endpoint, {
				mrregemail: email,
				'g-recaptcha-response': token,
				SEC_KEY: 'mobil',
			})
			dispatch(handleResetPasswordResponse(data))
			return RESET_PASSWORD_SUCCESS_ACTION
		} catch (exception) {
			dispatch(resetPasswordFailure(exception))
			return RESET_PASSWORD_FAILURE_ACTION
		}
	}
}

// -----------------------------------------------------------------------------
// Action handlers
// -----------------------------------------------------------------------------
export default handleActions(
	{
		[AUTH_API_LOAD_START_ACTION]: (state) => {
			return state.merge({
				loggedUser: null,
				error: null,
			})
		},

		[AUTH_API_LOAD_SUCCESS_ACTION]: (state, action) => {
			return state.merge({
				loggedUser: action.payload.profile,
				isAuthenticated: true,
				error: null,
			})
		},

		[AUTH_API_LOAD_FAILURE_ACTION]: (state, action) => {
			return state.merge({
				error: action.payload.err,
			})
		},

		[AUTH_API_LOGOUT_SUCCESS_ACTION]: (state) => {
			return state.merge({
				loggedUser: null,
				isAuthenticated: false,
			})
		},

		[AUTH_API_DELETE_ACCOUNT_START_ACTION]: (state) => {
			return state.merge({
				error: null,
			})
		},

		[VERIFY_ACCOUNT_START_ACTION]: (state) => {
			return state.merge({
				isBeingVerified: true,
			})
		},

		[VERIFY_ACCOUNT_SUCCESS_ACTION]: (state, action) => {
			return state.merge({
				isBeingVerified: false,
				verificationError: action.payload.err,
			})
		},

		[VERIFY_ACCOUNT_FAILURE_ACTION]: (state, action) => {
			return state.merge({
				isBeingVerified: false,
				verificationError: action.payload.err,
			})
		},

		[RESET_PASSWORD_START_ACTION]: (state) => {
			return state.merge({
				resetPasswordError: null,
			})
		},

		[RESET_PASSWORD_SUCCESS_ACTION]: (state) => {
			return state
		},

		[RESET_PASSWORD_FAILURE_ACTION]: (state, action) => {
			return state.merge({
				resetPasswordError: action.payload.err,
			})
		},
	},
	initialState
)
