import React from 'react'
import PropTypes from 'prop-types'
import TextAreaAutosize from 'react-textarea-autosize'

const TextArea = (props) => <TextAreaAutosize {...props} />

TextArea.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	onHeightChange: PropTypes.func,
	useCacheForDOMMeasurements: PropTypes.bool,
	rows: PropTypes.number,
	minRows: PropTypes.number,
	maxRows: PropTypes.number,
	inputRef: PropTypes.func,
}

export default TextArea
