import React from 'react'
import PropTypes from 'prop-types'
import Page from '../../../component/Page'
import ListCard from '../../../component/ListCard'
import Spinner from '../../../component/Spinner'
import AdaptiveFiltering from '../../../component/AdaptiveFiltering'
import ListTitle from '../../../component/ListTitle'
import ListSubTitle from '../../../component/ListSubTitle'
import ListSelectSort from '../../../component/ListSelectSort'
import InfinityList from '../../../component/InfinityList'
import Modal from '../../../component/Modal'
import Field from '../../../component/Field'
import InputText from '../../../component/InputText'
import Button from '../../../component/Button'
import ZeroResult from '../../../component/ZeroResult'
import Helmet from 'react-helmet'
import RealityUrlBuilder from '../../../utils/realityUrlBuilder'
import parseUrl from '../../../utils/urlParser'
import styles from './List.module.scss'
import { trackGoogleAnalytics } from '../../../App'
import { FILTER_ORDER_TYPE } from '../../../constants/SearchConfigConstants'

class List extends React.Component {
	constructor(props) {
		super(props)
		this.parseData = {}
		this.lastURL = ''
		this.lastFetched = 0

		this.state = {
			geowaiting: false,
		}
	}

	componentWillMount() {
		// save last URL
		this.lastURL = this.props.location.pathname + this.props.location.search

		// parse data from URL
		this.parseData = parseUrl(
			Object.assign({}, this.props.location, { params: this.props.params })
		)
		// scroll to last open card
		if (
			this.props.searchConfig.get('referrer') === 'detail' &&
			this.props.data.advertisements
		) {
			this.props.actions.setReferrer('')
		} else {
			this.props.actions.setScrolltop(0)
		}
	}

	componentDidUpdate() {
		// if URL change (after add sorting params)
		if (
			this.props.location.pathname + this.props.location.search !==
			this.lastURL
		) {
			this.lastURL = this.props.location.pathname + this.props.location.search

			// set URL params
			this.parseData = parseUrl(
				Object.assign({}, this.props.location, { params: this.props.params })
			)

			this.props.actions.load(Object.assign({}, this.parseData))
		}
	}

	handleNewDataForFilter = (config) => {
		this.props.actions.emptyList()
		this.props.actions.load(Object.assign({}, this.parseData, config))
		this.props.actions.setOrder(config.order)
		// change URL with new order parameter
		this.props.actions.push(
			RealityUrlBuilder(Object.assign({}, this.parseData, config))
		)
	}

	setOrder = (filterOrder) => {
		// sort by GPS
		if (filterOrder === FILTER_ORDER_TYPE.CLOSEST) {
			// geolocation is supported
			if (navigator.geolocation) {
				this.setState({ geowaiting: true })
				// get GPS
				navigator.geolocation.getCurrentPosition(
					(position) => {
						this.setState({ geowaiting: false })
						this.handleNewDataForFilter({
							order: filterOrder,
							geo: {
								maplat: position.coords.latitude,
								maplng: position.coords.longitude,
							},
						})
					},
					(error) => {
						this.setState({ geowaiting: false })
						alert('Je nám líto, ale vaší polohu nebylo možné rozpoznat')
						console.error(error)
					}
				)
			} else {
				console.log('navigator.geolocation not exists')
			}
			return
		}

		this.handleNewDataForFilter({ order: filterOrder })
	}

	loadData = (config = {}, push = false) => {
		this.props.actions.load(
			Object.assign({}, this.parseData, config),
			false,
			push
		)
	}

	saveSearch = (item) => {
		if (this.props.isAuthenticated) {
			this.props.actions.saveSearch(item)
		} else {
			this.props.actions.saveSearchLocally(item)
		}
	}

	saveList = () => {
		this.saveSearch({
			url: this.props.location.pathname + this.props.location.search,
			desc: this.props.modal_input,
			config: this.props.searchConfig,
		})
		this.props.actions.setModalState(true)
		setTimeout(() => {
			this.props.actions.setModal('')
			this.props.actions.setModalState(false)
		}, 2000)

		trackGoogleAnalytics(['send', 'event', 'Hledani', 'Ulozeni hledani'])
	}

	render() {
		var advertisements = []

		const { data, isLoading, searchConfig, location } = this.props

		this.parseData.topped = data.topped

		if (data.advertisements) {
			advertisements = data.advertisements.map((value, key) => {
				return (
					<ListCard
						data={value}
						key={value.id}
						order={advertisements.length + key + 1}
					/>
				)
			})
		}
		return (
			<Page className={styles.base}>
				{data.description && <Helmet title={data.description} />}

				{isLoading && advertisements.length === 0 && (
					<Spinner className={styles.spinner} />
				)}

				{advertisements.length === 0 && !isLoading && <ZeroResult />}

				{advertisements.length > 0 && (
					<InfinityList
						isLoading={isLoading}
						rowsCount={data.count}
						list={advertisements}
						loadData={(config) => this.loadData(config, true)}
						setInstance={(inst) => {
							this.infinityList = inst
						}}>
						<div>
							<ListTitle
								description={data.description}
								additional={[
									this.parseData.disposition,
									this.parseData.dispositionHouse,
								]}
								count={data.count}
							/>

							<ListSubTitle
								referrer={searchConfig.get('referrer')}
								url_to_save={location.pathname + location.search}
								desc={data.description}
							/>

							<AdaptiveFiltering
								count={data.count || 0}
								config={this.parseData}
								context={this}
								onToggle={() =>
									setTimeout(() => this.infinityList.forceRender(), 50)
								}
							/>
						</div>

						<div className={styles.listselectsort}>
							{advertisements.length > 1 && (
								<ListSelectSort
									onChange={this.setOrder}
									order={this.parseData.order}
									loading={this.state.geowaiting}
								/>
							)}
						</div>
					</InfinityList>
				)}

				{this.props.isLoading && advertisements.length > 0 && (
					<Spinner className={styles.spinner__loadmore} size="small" />
				)}

				{this.props.modal === 'saveList' && (
					<Modal type="fixed" background>
						{!this.props.modal_state && (
							<Field>
								<InputText
									value={this.props.modal_input}
									onChange={(e) =>
										this.props.actions.setModalInput(e.target.value)
									}
								/>
								<br />
								<br />
								<Button onClick={this.saveList}>Uložit</Button>
								<Button
									onClick={() => this.props.actions.setModal('')}
									type="secondary">
									Zpět
								</Button>
							</Field>
						)}

						{this.props.modal_state && <div>Seznam byl úspěšně uložen</div>}
					</Modal>
				)}
			</Page>
		)
	}
}

List.propTypes = {
	actions: PropTypes.object,
	data: PropTypes.object,
	searchConfig: PropTypes.object,
	isLoading: PropTypes.bool,
	modal: PropTypes.string,
	modal_state: PropTypes.any,
	modal_input: PropTypes.any,
	location: PropTypes.object,
	isAuthenticated: PropTypes.bool,
	params: PropTypes.any,
}

export default List
