import React from 'react'
import styles from './ZeroResult.module.scss'
import Title from '../Title'
import { Link } from 'react-router'

function ZeroResult() {
	return (
		<div className={styles.base}>
			<Title type="h1">
				Vašim parametrům momentálně neopovídá žádný inzerát.
			</Title>
			<br />
			<br />
			<Title type="h2">
				<Link to="/search">Zkuste upravit parametry vyhledávání</Link>
			</Title>
		</div>
	)
}

ZeroResult.defaultProps = {}

ZeroResult.propTypes = {}

export default ZeroResult
