import * as Immutable from 'immutable'
import { getUrl } from '../utils/getApiUrl'
import { createAction, handleActions } from 'redux-actions'
import realityUrlBuilder from '../utils/realityUrlBuilder'
import {
	EMPTY_FAVS_URL,
	EMPTY_SEARCHES_URL,
	LOAD_FAVS_URL,
	LOAD_SEARCHES_URL,
	NEW_OFFERS_COUNT_URL,
	NEW_OFFERS_URL,
	REMOVE_SEARCH_URL,
	SAVE_SEARCH_URL,
	SAVE_TO_FAVS_URL,
	UPDATED_FAVS_COUNT_URL,
	UPDATED_FAVS_URL,
	USER_DATA_ACCEPT_COOKIES_ACTION,
	USER_DATA_EMPTY_FAVS_LIST_ACTION,
	USER_DATA_EMPTY_USER_LIST_ACTION,
	USER_DATA_FAVS_AND_SEARCHES_COUNT_ACTION,
	USER_DATA_LOAD_FAILURE_ACTION,
	USER_DATA_LOAD_FAVS_START_ACTION,
	USER_DATA_LOAD_FAVS_SUCCESS_ACTION,
	USER_DATA_LOAD_NEW_OFFERS_FAILURE,
	USER_DATA_LOAD_NEW_OFFERS_START,
	USER_DATA_LOAD_NEW_OFFERS_SUCCESS,
	USER_DATA_LOAD_SEARCHES_START_ACTION,
	USER_DATA_LOAD_SEARCHES_SUCCESS_ACTION,
	USER_DATA_LOAD_UPDATED_FAVS_FAILURE,
	USER_DATA_LOAD_UPDATED_FAVS_START,
	USER_DATA_LOAD_UPDATED_FAVS_SUCCESS,
	USER_DATA_NEW_OFFERS_COUNT_ACTION,
	USER_DATA_PUSH_LAST_ACTION,
	USER_DATA_REMOVE_FROM_LIST_ACTION,
	USER_DATA_SAVE_TO_FAVS_ACTION,
	USER_DATA_SAVE_TO_LIST_ACTION,
	USER_DATA_SYNC_IN_PROGRESS_ACTION,
	USER_DATA_UPDATED_FAVS_COUNT_ACTION,
} from '../constants/UserDataConstants'
import { sendAxiosErrorValue, sendAxiosGetRequest } from '../utils/fetcher'

// -----------------------------------------------------------------------------
// Initial state
// -----------------------------------------------------------------------------
const initialState = Immutable.fromJS({
	favs: [],
	favsSize: 0,
	updatedFavs: [],
	updatedFavsSize: 0,
	newOffers: [],
	newOffersSize: 0,
	list: [],
	listSize: 0,
	last: [],
	error: null,
	cookie: false,
})

// -----------------------------------------------------------------------------
// Actions
// -----------------------------------------------------------------------------
export const loadFailure = createAction(USER_DATA_LOAD_FAILURE_ACTION)
export const loadFavsStart = createAction(USER_DATA_LOAD_FAVS_START_ACTION)
export const loadFavsSuccess = createAction(USER_DATA_LOAD_FAVS_SUCCESS_ACTION)
export const loadSearchesStart = createAction(
	USER_DATA_LOAD_SEARCHES_START_ACTION
)
export const loadSearchesSuccess = createAction(
	USER_DATA_LOAD_SEARCHES_SUCCESS_ACTION
)
export const saveToFavsLocally = createAction(USER_DATA_SAVE_TO_FAVS_ACTION)
export const emptyFavsListLocally = createAction(
	USER_DATA_EMPTY_FAVS_LIST_ACTION
)
export const emptySearchesListLocally = createAction(
	USER_DATA_EMPTY_USER_LIST_ACTION
)
export const removeSearchLocally = createAction(
	USER_DATA_REMOVE_FROM_LIST_ACTION
)
export const saveSearchLocally = createAction(USER_DATA_SAVE_TO_LIST_ACTION)
export const favsAndSearchesCount = createAction(
	USER_DATA_FAVS_AND_SEARCHES_COUNT_ACTION
)
export const pushLast = createAction(USER_DATA_PUSH_LAST_ACTION)
export const acceptCookies = createAction(USER_DATA_ACCEPT_COOKIES_ACTION)
export const syncInProgress = createAction(USER_DATA_SYNC_IN_PROGRESS_ACTION)
export const updatedFavsCount = createAction(
	USER_DATA_UPDATED_FAVS_COUNT_ACTION
)
export const newOffersCount = createAction(USER_DATA_NEW_OFFERS_COUNT_ACTION)
export const loadUpdatedFavsStart = createAction(
	USER_DATA_LOAD_UPDATED_FAVS_START
)
export const loadUpdatedFavsSuccess = createAction(
	USER_DATA_LOAD_UPDATED_FAVS_SUCCESS
)
export const loadUpdatedFavsFailure = createAction(
	USER_DATA_LOAD_UPDATED_FAVS_FAILURE
)
export const loadNewOffersStart = createAction(USER_DATA_LOAD_NEW_OFFERS_START)
export const loadNewOffersSuccess = createAction(
	USER_DATA_LOAD_NEW_OFFERS_SUCCESS
)
export const loadNewOffersFailure = createAction(
	USER_DATA_LOAD_NEW_OFFERS_FAILURE
)

/**
 * Load favourites from backend if user is logged in.
 */
export function loadFavs(config = {}) {
	return async (dispatch) => {
		let endpoint = getUrl(LOAD_FAVS_URL)

		if (config.skip) {
			endpoint = `${endpoint}&skip=${config.skip}`
		}
		dispatch(loadFavsStart())

		try {
			const { data } = await sendAxiosGetRequest(endpoint)
			let res = Object.assign({}, data)

			dispatch(loadFavsSuccess(res))
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

/**
 * Load saved searches from backend if user is logged in.
 */
export function loadSearches() {
	return async (dispatch) => {
		const endpoint = getUrl(LOAD_SEARCHES_URL)
		dispatch(loadSearchesStart())

		try {
			const { data } = await sendAxiosGetRequest(endpoint)
			dispatch(loadSearchesSuccess(data))
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

export function emptyFavsList() {
	return async (dispatch) => {
		const endpoint = getUrl(EMPTY_FAVS_URL)

		try {
			await sendAxiosGetRequest(endpoint)
			dispatch(emptyFavsListLocally())
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

export function emptySearchesList() {
	return async (dispatch) => {
		const endpoint = getUrl(EMPTY_SEARCHES_URL)

		try {
			await sendAxiosGetRequest(endpoint)
			dispatch(emptySearchesListLocally())
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

/**
 * Add advertisement to favourites if it hasn't been already added.
 * Else remove it from favourites.
 */
export function saveToFavs(item) {
	return async (dispatch) => {
		const endpoint = getUrl(`${SAVE_TO_FAVS_URL}-${item.id}/`)

		try {
			await sendAxiosGetRequest(endpoint)
			dispatch(saveToFavsLocally(item))
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

export function saveSearch(item) {
	return async (dispatch) => {
		const endpoint = getUrl(`${SAVE_SEARCH_URL}${item.url}`)

		try {
			await sendAxiosGetRequest(endpoint)
			dispatch(saveSearchLocally(item))
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

export function removeSearch(item, index) {
	return async (dispatch) => {
		const regexForProfile = /profil-\d+/
		const urlParams = item.url.split('/')
		const profileParam = urlParams.find((value) => value.match(regexForProfile))
		let profileNum = 1

		if (profileParam) {
			profileNum = profileParam.split('-')[1]
		} else {
			profileNum = index + 1
		}

		const endpoint = getUrl(`${REMOVE_SEARCH_URL}${profileNum}/vymazat/`)

		try {
			await sendAxiosGetRequest(endpoint)
			dispatch(removeSearchLocally(item))
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

/**
 * Send all favs and saved searches to the backend.
 * Used only after registration.
 */
export function synchronizeData(favsToSave, searchesToSave) {
	return async (dispatch) => {
		favsToSave.forEach(async (fav) => {
			const endpoint = getUrl(`${SAVE_TO_FAVS_URL}-${fav.id}/`)

			try {
				const { data } = await sendAxiosGetRequest(endpoint)
				dispatch(syncInProgress(), favsAndSearchesCount(data))
			} catch (exception) {}
		})

		searchesToSave.forEach(async (search) => {
			const endpoint = getUrl(`${SAVE_SEARCH_URL}${search.url}/`)

			try {
				const { data } = await sendAxiosGetRequest(endpoint)
				dispatch(syncInProgress(), favsAndSearchesCount(data))
			} catch (exception) {}
		})
	}
}

export function getUpdatedFavsCount() {
	return async (dispatch) => {
		const endpoint = getUrl(UPDATED_FAVS_COUNT_URL)

		try {
			const { data } = await sendAxiosGetRequest(endpoint)
			dispatch(updatedFavsCount(data), favsAndSearchesCount(data))
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

export function getNewOffersCount() {
	return async (dispatch) => {
		const endpoint = getUrl(NEW_OFFERS_COUNT_URL)

		try {
			const { data } = await sendAxiosGetRequest(endpoint)
			dispatch(newOffersCount(data), favsAndSearchesCount(data))
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

export function loadUpdatedFavs(config = {}) {
	return async (dispatch) => {
		let endpoint = getUrl(UPDATED_FAVS_URL)

		if (config.skip) {
			endpoint = `${endpoint}&skip=${config.skip}`
		}
		dispatch(loadUpdatedFavsStart())

		try {
			const { data } = await sendAxiosGetRequest(endpoint)
			let res = Object.assign({}, data)

			res.push = !!config.push

			dispatch(loadUpdatedFavsSuccess(res))
		} catch (exception) {
			dispatch(loadUpdatedFavsFailure(exception))
		}
	}
}

export function loadNewOffers(config = {}) {
	return async (dispatch) => {
		let endpoint = getUrl(NEW_OFFERS_URL)

		if (config.skip) {
			endpoint = `${endpoint}&skip=${config.skip}`
		}
		dispatch(loadNewOffersStart())

		try {
			const { data } = await sendAxiosGetRequest(endpoint)
			let res = Object.assign({}, data)

			res.push = !!config.push

			dispatch(loadNewOffersSuccess(res))
		} catch (exception) {
			dispatch(loadNewOffersFailure(exception))
		}
	}
}

// -----------------------------------------------------------------------------
// Action handlers
// -----------------------------------------------------------------------------
export default handleActions(
	{
		[USER_DATA_LOAD_FAVS_START_ACTION]: (state) => {
			return state.merge({
				error: null,
				favsSize: 0,
			})
		},

		[USER_DATA_LOAD_FAVS_SUCCESS_ACTION]: (state, action) => {
			let list = action.payload.advertisements || []

			return state.merge({
				favs: list,
				favsSize: list.length,
			})
		},

		[USER_DATA_LOAD_FAILURE_ACTION]: (state, action) => {
			return state.merge({
				error: action.payload.err,
			})
		},

		[USER_DATA_LOAD_SEARCHES_START_ACTION]: (state) => {
			return state.merge({
				error: null,
			})
		},

		[USER_DATA_FAVS_AND_SEARCHES_COUNT_ACTION]: (state, action) => {
			return state.merge({
				listSize: action.payload.profile.searches,
				favsSize: action.payload.profile.advertisements_active,
			})
		},

		[USER_DATA_LOAD_SEARCHES_SUCCESS_ACTION]: (state, action) => {
			let searches = action.payload.search_list
			let list = !Array.isArray(searches)
				? []
				: searches.map((value) => {
						return {
							// Server is returning url starting with '//'
							url: value.url.substring(1),
							config: Immutable.Map(value.description),
							desc: value.summary,
						}
				  })

			return state.merge({
				list: list,
				listSize: list.length,
			})
		},

		[USER_DATA_SAVE_TO_FAVS_ACTION]: (state, action) => {
			let list = state.get('favs')
			list = list.hasOwnProperty('size') ? list.toJS() : list
			let indexOfFav = list.map((v) => v.id).indexOf(action.payload.id)

			if (indexOfFav >= 0) {
				list.splice(indexOfFav, 1)
			} else {
				list.push(action.payload)
			}

			return state.merge({
				favs: list,
				favsSize: list.length,
			})
		},

		[USER_DATA_PUSH_LAST_ACTION]: (state, action) => {
			let last = state.get('last')
			return state.merge({
				// push payload only if not there
				last:
					last
						.map((v) => realityUrlBuilder(v))
						.indexOf(realityUrlBuilder(action.payload)) > -1
						? last
						: last.push(action.payload).slice(-3),
			})
		},

		[USER_DATA_SAVE_TO_LIST_ACTION]: (state, action) => {
			let list = state.get('list')
			list = list.hasOwnProperty('size') ? list.toJS() : list
			list.push(action.payload)

			return state.merge({
				list: list,
				listSize: list.length,
			})
		},

		[USER_DATA_REMOVE_FROM_LIST_ACTION]: (state, action) => {
			let list = state.get('list')
			let indexOfSearch = list.map((v) => v.url).indexOf(action.payload.url)

			if (indexOfSearch >= 0) {
				list.splice(indexOfSearch, 1)
			}

			return state.merge({
				list: list,
				listSize: list.length,
			})
		},

		[USER_DATA_EMPTY_USER_LIST_ACTION]: (state) => {
			return state.merge({
				list: [],
				listSize: 0,
			})
		},

		[USER_DATA_EMPTY_FAVS_LIST_ACTION]: (state) => {
			return state.merge({
				favs: [],
				favsSize: 0,
			})
		},

		[USER_DATA_ACCEPT_COOKIES_ACTION]: (state) => {
			if (!state || !state.merge) {
				sendAxiosErrorValue({
					context: 'state.merge-UserDataReducer',
					type: typeof state,
					data: state,
				})
				return state
			}
			return state.merge({
				cookie: true,
			})
		},

		[USER_DATA_SYNC_IN_PROGRESS_ACTION]: (state) => {
			return state
		},

		[USER_DATA_UPDATED_FAVS_COUNT_ACTION]: (state, action) => {
			return state.merge({
				updatedFavsSize: action.payload.count,
			})
		},

		[USER_DATA_NEW_OFFERS_COUNT_ACTION]: (state, action) => {
			return state.merge({
				newOffersSize: action.payload.count,
			})
		},

		[USER_DATA_LOAD_UPDATED_FAVS_START]: (state) => {
			return state.merge({
				error: null,
			})
		},

		[USER_DATA_LOAD_UPDATED_FAVS_SUCCESS]: (state, action) => {
			let list = action.payload.advertisements || []

			list = action.payload.push
				? [...state.get('updatedFavs').toJS(), ...list]
				: list

			return state.merge({
				updatedFavs: list,
			})
		},

		[USER_DATA_LOAD_UPDATED_FAVS_FAILURE]: (state, action) => {
			return state.merge({
				error: action.payload.err,
			})
		},

		[USER_DATA_LOAD_NEW_OFFERS_START]: (state) => {
			return state.merge({
				error: null,
			})
		},

		[USER_DATA_LOAD_NEW_OFFERS_SUCCESS]: (state, action) => {
			let list = action.payload.advertisements || []

			return state.merge({
				newOffers: Immutable.List(list),
			})
		},

		[USER_DATA_LOAD_NEW_OFFERS_FAILURE]: (state, action) => {
			return state.merge({
				error: action.payload.err,
			})
		},
	},
	initialState
)
