import {
	OFFER_TYPE,
	PROPERTY_TYPE,
	PRICE,
} from '../constants/SearchConfigConstants'

// default values of parameters
function getPathElement(el) {
	return el ? el + '/' : ''
}

/**
 * mix multiple address by separator '_'
 * @param {Array} address
 * @return {string} mixed address
 **/
function getAddress(address) {
	let mixaddress = ''

	if (!address || address.length === 0) {
		return ''
	}

	for (var i in address) {
		if (address[i] && address[i].url) {
			mixaddress = (mixaddress !== '' ? mixaddress + '_' : '') + address[i].url
		}
	}

	return mixaddress + '/'
}

/**
 * format price to template cena-<from>-<to>-<unit>
 * @param {Object} params with keys offer, from and to
 * @return {string} price in valid format
 **/
function getPrice({ unit, offer, to, from }) {
	unit = unit || (offer === OFFER_TYPE.SELL ? 2 : 4)
	to = !to || to === '' ? 0 : to === 'max' ? 0 : to.replace(/[^0-9]/gim, '') - 0
	from = !from || from === '' ? 0 : from.replace(/[^0-9]/gim, '') - 0

	if (to < from || to <= 0) {
		to = 'max'
	}

	if (from < 0) {
		from = 0
	}

	if (from === 0 && to === 'max') {
		return ''
	} else {
		return PRICE + from + '-' + to + '-' + unit + '/'
	}
}

function getTopped(params) {
	if (params.topped) {
		return '&topped=' + params.topped
	}

	return ''
}

/**
 * format url queries (f, d, p, s)
 * @param {Object} params to URL
 * @return {string} mix of queries
 **/
function getQuery(params) {
	var q = ''

	// data after ?f=
	var buildQueryF = (data, prefix) => {
		if (data && data.length > 0) {
			q = q + (q.substr(0, 3) !== '?f=' ? '?f=' : '') + prefix
			data.forEach((v) => {
				q += v.url
			})
		}
	}

	// data after ?d=
	var buildQueryD = (data) => {
		if (data && data.length > 0) {
			q += q === '' ? '?d=' : '&d='
			data.forEach((v) => {
				q += v.url + ','
			})
			q = q.slice(0, -1)
		}
	}

	// data after ?p=
	var buildQueryP = (data) => {
		if (data && data.length > 0) {
			q += q === '' ? '?p=' : '&p='
			data.forEach((v) => {
				q += v.url
			})
		}
	}

	var buildQueryAny = (data, p) => {
		if (data) {
			q = q === '' ? '?' + p + '=' + data : q + '&' + p + '=' + data
		}
	}

	var buildQueryGeo = (data) => {
		if (data && data.maplat && data.maplng) {
			q =
				(q === '' ? '?maplat=' + data.maplat : q + '&maplat=' + data.maplat) +
				'&maplng=' +
				data.maplng
		}
	}

	/*
   // sorting - data after ?s=
   var buildQueryS = (data) => {
   if (data) {
   q = (q === '') ? ('?s=' + data) : (q + '&s=' + data)
   }
   }

   // fulltext - data after ?x=
   var buildQueryX = (data) => {
   if (data) {
   q = (q === '') ? ('?x=' + data) : (q + '&x=' + data)
   }
   }
   */

	switch (params.kind) {
		case PROPERTY_TYPE.FLATS:
			buildQueryF(params.apartmentArea, 'F')
			buildQueryF(params.disposition, 'D')
			buildQueryF(params.ownership, 'N')
			buildQueryF(params.buildingtype, 'Q')
			buildQueryF(params.others, '')
			break
		case PROPERTY_TYPE.HOUSES:
			buildQueryF(params.dispositionHouse, 'E')
			buildQueryF(params.floor, 'J')
			buildQueryF(params.ground, 'L')
			buildQueryF(params.othersHouse, '')
			buildQueryD(params.types)
			break
		case PROPERTY_TYPE.RECREATION:
			buildQueryF(params.recreation, 'j')
			buildQueryF(params.ground, 'L')
			buildQueryF(params.floor, 'J')
			break
		case PROPERTY_TYPE.LAND:
			buildQueryD(params.kindofplot)
			buildQueryF(params.ground, 'L')
			break
		case PROPERTY_TYPE.COMMERCIAL:
			buildQueryF(params.commercial, 'k')
			buildQueryF(params.floor, 'J')
			break
		case PROPERTY_TYPE.OTHERS:
			buildQueryD(params.typeOfProperty)
			break
		default:
			break
	}

	buildQueryP(params.restriction)

	// sort
	buildQueryAny(params.order, 's')

	// fulltext
	buildQueryAny(params.fulltext, 'x')

	// count
	buildQueryAny(params.count, 'count')

	// skip
	buildQueryAny(params.skip, 'skip')

	// geo
	buildQueryGeo(params.geo)

	return q
}

export default function(params) {
	return (
		'/' +
		getPathElement(params.selection) +
		getPathElement(params.seldata) +
		getPathElement(params.offer) +
		getPathElement(params.kind) +
		getAddress(params.address) +
		getPrice(params) +
		getQuery(params) +
		getTopped(params)
	)
}
