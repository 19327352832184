import React from 'react'
import PropTypes from 'prop-types'
import styles from './Select.module.scss'
import { omit, map } from 'lodash'

const Select = (props) => (
	<select className={styles.base} {...omit(props, ['options'])}>
		{props.options &&
			map(props.options, (i) => (
				<option key={i.value} value={i.value}>
					{i.label}
				</option>
			))}
	</select>
)

Select.propTypes = {
	options: PropTypes.arrayOf(PropTypes.object),
}

export default Select
