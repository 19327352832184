import { combineReducers } from 'redux'
import { routerReducer as routing } from 'react-router-redux'
import appReducers from '../reducer'
import { reducer as form } from 'redux-form'
import { VERSION_REDUCER_KEY } from './migrations'

export const reducers = (asyncReducers) => {
	return combineReducers({
		// Add sync reducers here
		[VERSION_REDUCER_KEY]: (state = {}) => state,
		routing,
		form,
		...appReducers,
		...asyncReducers,
	})
}

export const injectReducer = (store, { key, reducer }) => {
	store.asyncReducers[key] = reducer
	store.replaceReducer(reducers(store.asyncReducers))
}

export default reducers
