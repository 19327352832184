import React from 'react'
import PropTypes from 'prop-types'
import styles from './Field.module.scss'
import FieldHelp from '../FieldHelp'
import FieldHelpIcon from '../FieldHelpIcon'
import FieldError from '../FieldError'
import createClassName from '../../utils/createClassName'
import InputGroupAddon from '../InputGroupAddon'
import Spinner from '../Spinner'

function Field(props) {
	let p = createClassName(props, styles)
	let fieldClass =
		styles.base +
		p('type') +
		p('size') +
		p('position') +
		p('className', false) +
		(props.emptyAble ? ' ' + styles.emptyAble : '')
	let holderClass
	if (props.groupAddonLeft || props.groupAddonRight) {
		holderClass = styles.inputHolder + ' ' + styles.hasGroupAddon
	} else {
		holderClass = styles.inputHolder
	}
	return (
		<div className={fieldClass}>
			{props.label && <label>{props.label}</label>}

			<div className={holderClass}>
				{props.groupAddonLeft && (
					<InputGroupAddon>{props.groupAddonLeft}</InputGroupAddon>
				)}
				{props.children}
				{props.groupAddonRight && (
					<InputGroupAddon>{props.groupAddonRight}</InputGroupAddon>
				)}

				{props.helpIcon && props.type === 'positive' && (
					<FieldHelpIcon type={props.type} />
				)}
				{props.emptyAble && (
					<FieldHelpIcon type="negative" onClick={props.emptyAble} />
				)}
				{props.type === 'loading' && (
					<Spinner className={styles.loader} size="small" />
				)}
			</div>

			<div className={styles.helpHolder}>
				{props.enableError && props.type === 'negative' && (
					<FieldError>{props.fieldError}</FieldError>
				)}
				{props.fieldHelp && <FieldHelp>{props.fieldHelp}</FieldHelp>}
			</div>
		</div>
	)
}

Field.defaultProps = {
	size: 'default',
	type: 'default',
	position: 'default',
	helpIcon: true,
	fieldError: 'Tento vstup není validní.',
	enableError: true,
}

Field.propTypes = {
	type: PropTypes.oneOf([
		'default',
		'readonly',
		'disabled',
		'positive',
		'negative',
		'loading',
	]),
	helpIcon: PropTypes.any,
	fieldHelp: PropTypes.any,
	groupAddonRight: PropTypes.any,
	groupAddonLeft: PropTypes.any,
	label: PropTypes.string,
	children: PropTypes.any,
	enableError: PropTypes.bool,
	fieldError: PropTypes.string,
	emptyAble: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
}

export default Field
