import { get } from 'lodash'
/**
 * [default description]
 * @method default
 * @param  {[type]} propName [description]
 * @param  {[type]} prefix   =             true [description]
 * @return {[type]} [description]
 */
export default (props, styles) => (propName, prefix = true) => {
  let styleName = (prefix ? `${propName}__${get(props, propName, '')}` : get(props, propName, ''))
  let r = get(styles, styleName, '')
  return r === '' ? '' : ` ${r}`
}
