import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './LocationAdder.module.scss'
import AddressAutocomplete from '../AddressAutocomplete'
import Page from '../Page'
import CheckBoxMatrix from '../CheckBoxMatrix'
import Button from '../Button'
import regions from '../../utils/regions.json'
import OpenCardHeader from '../OpenCardHeader'
import Row from '../Row'
import Col from '../Col'
import CheckBox from '../CheckBox'
import Title from '../Title'
import ReactDOM from 'react-dom'

class LocationAdder extends Component {
	constructor(props) {
		super(props)

		this.state = {
			page: 1,
			parent: {},
			parts: [],
		}
	}

	firstStep = () => {
		this.setState({ page: 1 })
	}

	nextStep = (region) => {
		// scroll parent (open card component) to TOP
		ReactDOM.findDOMNode(this).parentNode.parentNode.scrollTop = 0

		this.setState({
			page: 2,
			parent: { fullname: region.name, url: region.url },
			parts: region.parts.map((v) => ({ v: v.name, url: v.url })),
		})

		// if parent is selected, we should selected all childs (district)
		let address = this.props.searchConfig.get('address')
		let i = address.findIndex((v) => v.url === region.url)
		if (i > -1) {
			// remove temporarily parent
			this.props.actions.toggleMultipleAddress({
				fullname: region.name,
				url: region.url,
			})

			// and select all childs
			region.parts.forEach((v) => {
				this.props.actions.toggleMultipleAddress({
					fullname: v.name,
					url: v.url,
				})
			})
		}
	}

	saveAutoComplete = (v) => {
		this.props.success()
		this.props.actions.toggleMultipleAddress(v)
		this.props.actions.closeOpenCard()
	}

	addValue = (v) => {
		this.props.actions.toggleMultipleAddress({ fullname: v.v, url: v.url })
	}

	finish = () => {
		// scroll to first slide
		this.firstStep()

		// could we have a reduced value?
		let selected = this.props.searchConfig.get('address').map((v) => v.url)
		let parent = {
			fullname: this.state.parent.fullname,
			url: this.state.parent.url,
		}

		for (let i in this.state.parts) {
			if (selected.indexOf(this.state.parts[i].url) === -1) {
				// answer is no, not every district selected
				this.props.actions.removeAddress(parent)
				this.props.success()
				this.props.actions.closeOpenCard()
				return
			}
		}

		// yes, we could reduce selected values
		for (let i in this.state.parts) {
			// remove parts (district) of address
			this.props.actions.removeAddress({
				fullname: this.state.parts[i].v,
				url: this.state.parts[i].url,
			})
		}

		// add parent address
		this.props.actions.toggleMultipleAddress(parent)
		this.props.success()
		this.props.actions.closeOpenCard()
	}

	onClose = () => {
		// scroll to first slide
		this.firstStep()
		this.props.success()
		this.props.actions.closeOpenCard()
	}

	isSelectedAll = () => {
		const addresses = this.props.searchConfig.get('address')
		let selected = addresses.size > 0 ? addresses.toJS().map((v) => v.url) : []

		for (let i in this.state.parts) {
			if (selected.indexOf(this.state.parts[i].url) === -1) {
				return false
			}
		}
		return true
	}

	toggleAll = () => {
		const addresses = this.props.searchConfig.get('address')
		let selected = addresses.size > 0 ? addresses.toJS().map((v) => v.url) : []
		let selectedAll = this.isSelectedAll()

		for (let i in this.state.parts) {
			if (!selectedAll && selected.indexOf(this.state.parts[i].url) === -1) {
				this.addValue({
					url: this.state.parts[i].url,
					v: this.state.parts[i].name,
				})
			}

			if (selectedAll) {
				this.addValue({
					url: this.state.parts[i].url,
					v: this.state.parts[i].name,
				})
			}
		}
	}

	render() {
		return (
			<div className={styles.base + ' ' + styles['step' + this.state.page]}>
				<div className={styles.section}>
					<Page>
						<OpenCardHeader onBack={this.onClose} title="Výběr lokality" />
						<Title
							type="p6"
							className={styles.autocomplete_title}
							display="block">
							Hledat město, ulici
						</Title>
						<AddressAutocomplete saveValue={this.saveAutoComplete} />

						<Title type="p2" display="block" className={styles.region_title}>
							Dle krajů
						</Title>

						<ul className={styles.list}>
							{regions.regions.map((v, k) => {
								return (
									<li key={k} onClick={() => this.nextStep(v)}>
										{v.name}
									</li>
								)
							})}
						</ul>
					</Page>
				</div>

				{Object.keys(this.state.parts).length > 0 && (
					<div className={styles.section2}>
						<Page>
							{this.state.page === 2 && (
								<OpenCardHeader
									onBack={this.firstStep}
									title={this.state.parent.fullname}
									onClose={this.onClose}
									titleClassName={styles.titleHeader}
								/>
							)}
							<CheckBoxMatrix
								data={this.state.parts}
								onClick={this.addValue}
								prefix="region"
								selected={this.props.searchConfig.get('address').map((v) => ({
									v: v.fullname,
									url: v.url,
								}))}
							/>
							<div className={styles.toggleAll}>
								<CheckBox
									onClick={this.toggleAll}
									label="Označit vše"
									id={'toggleAll'}
									checked={this.isSelectedAll()}
								/>
							</div>

							<Row>
								<Col xs="12/12">
									<Button onClick={this.finish} size="cover">
										Vybrat
									</Button>
								</Col>
							</Row>
						</Page>
					</div>
				)}
			</div>
		)
	}
}

LocationAdder.defaultProps = {}

LocationAdder.propTypes = {
	actions: PropTypes.object,
	searchConfig: PropTypes.object,
	success: PropTypes.func,
}

export default LocationAdder
