import React from 'react'
import PropTypes from 'prop-types'
import styles from './Modal.module.scss'

const Modal = (props) => (
	<div>
		{props.background && <div className={styles.bgr} />}

		<div
			className={`${styles.modal} ${
				props.type ? styles['modal--' + props.type] : ''
			}`}>
			<div className={styles.container}>{props.children}</div>
		</div>
	</div>
)

Modal.defaultProps = {
	children: 'default text in modal',
}

Modal.propTypes = {
	children: PropTypes.any,
	background: PropTypes.any,
	type: PropTypes.string,
}

export default Modal
