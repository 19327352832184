import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './ListSubTitle.module.scss'
import { Link } from 'react-router'

class ListSubTitle extends Component {
	constructor(props) {
		super(props)

		this.state = {
			item: null,
		}
	}

	componentDidMount() {
		this.updateSavedSearch(this.props)
	}

	componentWillReceiveProps(nextProps) {
		this.updateSavedSearch(nextProps)
	}

	updateSavedSearch = (props) => {
		const item = props.lists.filter(
			(v) => v.url.replace(/profil-\d+\//, '') === this.props.url_to_save
		)
		this.setState({ item: item[0] })
	}

	removeSavedSearch = (item) => {
		if (this.props.isAuthenticated) {
			this.props.actions.removeSearch(item, this.props.lists.indexOf(item))
		} else {
			this.props.actions.removeSearchLocally(item)
		}
		this.setState({ item: null })
	}

	showModal = (desc) => {
		this.props.actions.setModal('saveList')
		this.props.actions.setModalInput(desc)
	}

	render() {
		return (
			<div className={styles.base}>
				{this.state.item ? (
					<span
						className={styles.saveToList}
						onClick={() => {
							this.removeSavedSearch(this.state.item)
						}}>
						Odstranit z uložených
					</span>
				) : (
					<span
						className={styles.saveToList}
						onClick={() => this.showModal(this.props.desc)}>
						Uložit hledání
					</span>
				)}

				<Link to="/search" className={styles.saveToList}>
					Upřesnit hledání
				</Link>
			</div>
		)
	}
}

ListSubTitle.defaultProps = {}

ListSubTitle.propTypes = {
	url_to_save: PropTypes.string,
	desc: PropTypes.string,
	isAuthenticated: PropTypes.bool,
	lists: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	actions: PropTypes.shape({
		setModal: PropTypes.func,
		setModalInput: PropTypes.func,
		removeSearch: PropTypes.func,
		removeSearchLocally: PropTypes.func,
	}),
}

export default ListSubTitle
