import { bindActionCreators } from 'redux'
import {
	getUpdatedFavsCount,
	getNewOffersCount,
} from '../reducer/UserDataReducer'

class NotificationRequestService {
	constructor() {
		this.intervalId = null
		this.actions = null
	}

	getNotificationMiddleware = () => {
		return ({ dispatch, getState }) => (next) => (action) => {
			const authApi = getState().authApi
			let isAuthenticated = false
			if (authApi.hasOwnProperty('size')) {
				isAuthenticated = authApi.get('isAuthenticated')
			} else {
				isAuthenticated = authApi.isAuthenticated
			}

			if (isAuthenticated && !this.intervalId) {
				this.getNotificationsCount(dispatch)
				this.intervalId = setInterval(
					() => this.getNotificationsCount(dispatch),
					10000
				)
			} else if (!isAuthenticated && this.intervalId) {
				clearInterval(this.intervalId)
				this.intervalId = null
			}

			return next(action)
		}
	}

	getNotificationsCount = (dispatch) => {
		if (!this.actions) {
			this.actions = bindActionCreators(
				{
					getUpdatedFavsCount,
					getNewOffersCount,
				},
				dispatch
			)
		}

		this.actions.getUpdatedFavsCount()
		this.actions.getNewOffersCount()
	}
}

export default new NotificationRequestService()
