import { sendAxiosGetRequest } from '../utils/fetcher'
import * as Immutable from 'immutable'
import { createAction, handleActions } from 'redux-actions'
import { getUrl } from '../utils/getApiUrl'
import { API_URL } from '../constants/AppConstants'

// -----------------------------------------------------------------------------
// Initial state
// -----------------------------------------------------------------------------

const initialState = Immutable.fromJS({
	isLoading: false,
	lastFetched: null,
	error: null,
	data: [],
})

// -----------------------------------------------------------------------------
// Actions
// -----------------------------------------------------------------------------

const loadStart = createAction('realityapi_LOAD_START')
const loadSuccess = createAction('realityapi_LOAD_SUCCESS')
const loadFailure = createAction('realityapi_LOAD_FAILED')

export const load = (v = 'praha') => {
	return async (dispatch) => {
		dispatch(loadStart())

		try {
			const { data } = await sendAxiosGetRequest(
				getUrl(API_URL + '/auto/?vyraz=' + removeDiacritic(v) + '/')
			)
			dispatch(loadSuccess(data))
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

// -----------------------------------------------------------------------------
// Action handlers
// -----------------------------------------------------------------------------

export default handleActions(
	{
		realityapi_LOAD_START: (state) =>
			state.merge({
				isLoading: true,
			}),

		realityapi_LOAD_SUCCESS: (state, action) => {
			return state.merge({
				isLoading: false,
				data: action.payload === '' ? [] : action.payload,
				lastFetched: new Date().getTime(),
				error: null,
			})
		},

		realityapi_LOAD_FAILED: (state, action) =>
			state.merge({
				isLoading: false,
				error: action.payload,
				data: [],
			}),
	},
	initialState
)

const removeDiacritic = (txt) => {
	const sdiak = 'áäčďéěíĺľňóô öŕšťúů üýřžÁÄČĎÉĚÍĹĽŇÓÔ ÖŔŠŤÚŮ ÜÝŘŽ'
	const bdiak = 'aacdeeillnoo orstuu uyrzAACDEEILLNOO ORSTUU UYRZ'
	let tx = ''
	for (let p = 0; p < txt.length; p++) {
		if (sdiak.indexOf(txt.charAt(p)) !== -1) {
			tx += bdiak.charAt(sdiak.indexOf(txt.charAt(p)))
		} else {
			tx += txt.charAt(p)
		}
	}
	return tx
}
