import * as React from 'react'
import Page from '../../component/Page'
import Page404 from '../../component/Page404'

export const NotFound = () => (
	<Page>
		<Page404 title="Stránka nenalezena" />
	</Page>
)

export default NotFound
