import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import styles from './Navigation.module.scss'

const Navigation = (props) => (
	<nav className={styles.Navigation} role="navigation">
		<Link to="/favs" className={styles.fav}>
			<span
				className={`${styles.star} ${
					props.favscounter % 2 === 0
						? styles.star__animated
						: styles.star__animated2
				}`}
				alt="oblibene"
			/>
			<span
				className={`${styles.counter} ${
					props.favscounter === 0 ? styles.counter__zero : ''
				}`}>
				{props.favscounter}
			</span>
		</Link>

		<Link to="/search">
			<span className={styles.magnifier} />
		</Link>
	</nav>
)

Navigation.propTypes = {
	favscounter: PropTypes.number,
}

export default Navigation
