import React from 'react'
import PropTypes from 'prop-types'
import styles from './Price.module.scss'
import NumberFormat from '../ReactNumberFormatFork'

function Price(props) {
	const { className } = props

	return (
		<span className={styles.base + ' ' + className}>
			{props.price && (
				<span>
					{props.price.sale && (
						<NumberFormat
							value={props.price.sale.price}
							displayType={'text'}
							suffix={' ' + props.price.sale.unit}
							thousandSeperator
							separatorChar={' '}
						/>
					)}

					{props.price.rent && (
						<NumberFormat
							value={props.price.rent.price}
							displayType={'text'}
							suffix={' ' + props.price.rent.unit}
							thousandSeperator
							separatorChar={' '}
						/>
					)}
				</span>
			)}

			{(!props.price || (!props.price.sale && !props.price.rent)) && (
				<strong>Cena neuvedena</strong>
			)}
		</span>
	)
}

Price.defaultProps = {
	className: '',
}

Price.propTypes = {
	price: PropTypes.object,
	className: PropTypes.string,
}

export default Price
