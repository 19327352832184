import React from 'react'
import PropTypes from 'prop-types'
import styles from './FieldHelp.module.scss'

const FieldHelp = (props) => <div className={styles.base}>{props.children}</div>

FieldHelp.defaultProps = {}

FieldHelp.propTypes = {
	children: PropTypes.node,
}

export default FieldHelp
