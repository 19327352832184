import React from 'react'
import PropTypes from 'prop-types'
import blacklist from 'blacklist'
import UI from './config'
import * as _ from 'lodash'

class Col extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
		}
	}

	componentDidMount() {
		if (typeof window !== 'undefined') {
			window.addEventListener('resize', this.handleResize)
		}
	}

	componentWillUnmount() {
		if (typeof window !== 'undefined') {
			window.removeEventListener('resize', this.handleResize)
		}
	}

	handleResize = _.debounce(() => {
		this.setState({ windowWidth: window.innerWidth })
	}, 500)

	render() {
		let { basis, gutter, xs, sm, md, lg } = this.props
		let { windowWidth } = this.state

		let columnStyle = {
			minHeight: 1,
			paddingLeft: gutter / 2 + '%',
			paddingRight: gutter / 2 + '%',
		}

		// if no width control is provided fill available space
		if (!basis && !xs && !sm && !md && !lg) {
			columnStyle.flex = '1 1 auto'
			columnStyle.msFlex = '1 1 auto'
			columnStyle.WebkitFlex = '1 1 auto'
		}

		// set widths / flex-basis
		if (basis) {
			columnStyle.flex = '1 0 ' + basis
			columnStyle.msFlex = '1 0 ' + basis
			columnStyle.WebkitFlex = '1 0 ' + basis
		} else if (windowWidth < UI.breakpoint.xs) {
			columnStyle.width = xs
		} else if (windowWidth < UI.breakpoint.sm) {
			columnStyle.width = sm || xs
		} else if (windowWidth < UI.breakpoint.md) {
			columnStyle.width = md || sm || xs
		} else {
			columnStyle.width = lg || md || sm || xs
		}

		if (!columnStyle.width) {
			columnStyle.width = '100%'
		}

		if (columnStyle.width in UI.fractions) {
			columnStyle.width = UI.fractions[columnStyle.width]
		}

		let props = blacklist(
			this.props,
			'basis',
			'gutter',
			'style',
			'xs',
			'sm',
			'md',
			'lg'
		)

		return (
			<div style={Object.assign(columnStyle, this.props.style)} {...props} />
		)
	}
}

Col.defaultProps = {
	gutter: UI.width.gutter,
}

Col.propTypes = {
	basis: PropTypes.oneOfType([
		PropTypes.number, // allow pixels
		PropTypes.string, // allow percentage
	]),
	gutter: PropTypes.number,
	style: PropTypes.object,
	lg: PropTypes.string, // width as a percentage or fraction
	md: PropTypes.string, // width as a percentage or fraction
	sm: PropTypes.string, // width as a percentage or fraction
	xs: PropTypes.string, // width as a percentage or fraction
}

export default Col
