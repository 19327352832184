import React from 'react'
import PropTypes from 'prop-types'
import styles from './Page.module.scss'

const Page = (props) => (
	<main className={styles.Page + ' ' + props.className}>{props.children}</main>
)

Page.defaultProps = {
	className: '',
}

Page.propTypes = {
	className: PropTypes.string,
	children: PropTypes.any,
}

export default Page
