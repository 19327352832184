import address from './regions.json'

const composeAddress = (url, fullname) => {
  return {
    fullname,
    url
  }
}

const getAddressName = (url) => {
  for (var i in address.regions) {
    if (address.regions[i].url === url) {
      return composeAddress(url, address.regions[i].name)
    } else {
      for (var j in address.regions[i].parts) {
        if (address.regions[i].parts[j].url === url) {
          return composeAddress(url, address.regions[i].parts[j].name)
        }
      }
    }
  }

  return composeAddress(url, '')
}

export default getAddressName
