import React from 'react'
import PropTypes from 'prop-types'
import styles from './Title.module.scss'

function Title(props) {
	const { children } = props

	return (
		<span
			className={`${styles[props.type]} ${styles[props.display]} ${
				props.className
			}`}
			style={props.styles}
			onClick={props.onClick}>
			{children}
		</span>
	)
}

Title.defaultProps = {
	type: 'default',
	display: 'inline',
	className: '',
}

Title.propTypes = {
	children: PropTypes.node,
	type: PropTypes.string,
	display: PropTypes.oneOf(['', 'block', 'inline', 'inline-block']),
	className: PropTypes.string,
	styles: PropTypes.object,
	onClick: PropTypes.func,
}

export default Title
