import React from 'react'
import PropTypes from 'prop-types'
import styles from './Wrapper.module.scss'

function Wrapper(props) {
	const { children } = props
	let isActive = props.opencard.length ? styles.opencard : ''
	return <div className={`${styles.base} ${isActive}`}>{children}</div>
}

Wrapper.defaultProps = {}

Wrapper.propTypes = {
	children: PropTypes.node,
	opencard: PropTypes.string,
}

export default Wrapper
