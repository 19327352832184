import List from './container/ListContainer'
export default List

/*
 allowed URL combinations:
 <offer> / <address> /
 <offer> / <address> / <price>/
 <offer> / <kind> / <address>/
 <offer> / <kind> / <address>/<price>
 */

// export default (store, kind) => ({
//   path: ':offer' + kind + '/:address(/:price)/', // Path should be dash-lower-case
//   getComponent (nextState, next) {
//     require.ensure(
//       ['./container/ListContainer'],
//       (require) => {
//         // Don't forget to add router to `src/route/index`
//         const List = require('./container/ListContainer').default

//         next(null, List)
//       })
//   }
// })
