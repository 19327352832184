import LocationAdder from './LocationAdder'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { load } from '../../reducer/AddressApiReducer'
import {
	toggleMultipleAddress,
	removeAddress,
} from '../../reducer/SearchConfigReducer'
import { closeOpenCard } from '../../reducer/OpenCardReducer'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators(
		{ load, toggleMultipleAddress, removeAddress, closeOpenCard },
		dispatch
	),
})

const mapStateToProps = (state) => ({
	data: state.addressApi.get('data'),
	searchConfig: state.searchConfig,
	opencard: state.openCard.get('openCard'),
})

export default connect(
	mapStateToProps,
	mapActionCreators
)(LocationAdder)
