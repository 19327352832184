import React from 'react'
import PropTypes from 'prop-types'
import Recaptcha from 'react-grecaptcha'
import styles from './ForgottenPassword.module.scss'
import Page from '../../../component/Page'
import PageTitle from '../../../component/PageTitle'
import Form from '../../../component/Form'
import Row from '../../../component/Row'
import Col from '../../../component/Col'
import Field from '../../../component/Field'
import InputEmail from '../../../component/InputEmail'
import Button from '../../../component/Button'
import Overlay from '../../../component/Overlay'
import Title from '../../../component/Title'
import ConfigurationService from '../../../service/ConfigurationService'
import { RESET_PASSWORD_SUCCESS_ACTION } from '../../../constants/AuthConstants'

class ForgottenPassword extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			email: null,
			wasRequestSent: null,
			isLoading: false,
			recaptchaKey: null,
		}
	}

	componentDidMount() {
		if (this.props.isAuthenticated) {
			this.redirectToDefaultAuthenticatedRoute()
		} else {
			this.setState({
				recaptchaKey: ConfigurationService.recaptchaKey,
			})
		}
	}

	redirectToDefaultAuthenticatedRoute = () => {
		this.context.router.push('/')
	}

	executeRecaptcha = (e) => {
		e.preventDefault()

		if (this.state.wasRequestSent) {
			window.grecaptcha.reset()
		}

		window.grecaptcha.execute()
	}

	handleExpiredRecaptcha = () => {
		window.grecaptcha.reset()
	}

	resetPassword = async (token) => {
		this.setState({ isLoading: true })

		const data = {
			email: this.state.email,
			token: token,
		}

		const actionType = await this.props.actions.resetPassword(data)
		this.setState({ isLoading: false, wasRequestSent: true })

		if (actionType === RESET_PASSWORD_SUCCESS_ACTION) {
			this.context.router.push('/')
		}
	}

	handleChange = (e) => {
		this.setState({ email: e.target.value })
	}

	render() {
		return (
			<Page className={styles.base}>
				<PageTitle>Zapomenuté heslo</PageTitle>

				<Form
					className={styles['forgotten-password-form']}
					onSubmit={this.executeRecaptcha}>
					<Row>
						<Col xs="12/12">
							<Title type="p6" className={styles.title} display="block">
								E-mail
							</Title>
						</Col>
					</Row>

					<Row>
						<Col xs="12/12">
							<Field label="">
								<InputEmail id="email" onChange={this.handleChange} required />
								<div className={styles.error}>
									&nbsp;{this.state.wasRequestSent && this.props.error}
								</div>
							</Field>
						</Col>
					</Row>

					<Row className={styles.button}>
						<Col xs="12/12">
							<Button formType="submit" size="cover" type="info">
								Obnovit heslo
							</Button>
						</Col>
					</Row>
				</Form>

				<Overlay shown={this.state.isLoading} withSpinner />

				{this.state.recaptchaKey && (
					<div id="recaptcha" className={styles['recaptcha']}>
						<Recaptcha
							data-badge="inline"
							sitekey={this.state.recaptchaKey}
							callback={this.resetPassword}
							expiredCallback={this.handleExpiredRecaptcha}
							locale="cs"
							invisible
						/>
					</div>
				)}
			</Page>
		)
	}
}

ForgottenPassword.propTypes = {
	isAuthenticated: PropTypes.bool,
	error: PropTypes.any,
	actions: PropTypes.object,
}

ForgottenPassword.contextTypes = {
	router: PropTypes.object,
}

export default ForgottenPassword
