import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './AdaptiveFiltering.module.scss'
import Button from '../Button'
import realityUrlBuilder from '../../utils/realityUrlBuilder'
import { Link } from 'react-router'
import SelectHint from '../SelectHint'
import {
	PROPERTY_TYPE,
	disposition,
	dispositionHouse,
	apartmentArea,
	basicTypes,
	floor,
} from '../../constants/SearchConfigConstants'
import Close from '../Close'
import Title from '../Title'

const MIN = 10
const MAX = 100
class AdaptiveFiltering extends Component {
	toggleHint = () => {
		this.props.onToggle()
		this.props.actions.toggleAdaptiveFilter()
	}

	getHint = () => {
		var nohint = { visible: false }

		if (this.props.count <= MIN) {
			return this.getHintMIN() || nohint
		}

		if (this.props.count >= MAX) {
			return this.getHintMAX() || nohint
		}

		return nohint
	}

	getHintMIN = () => {
		if (this.props.config.fulltext && this.props.config.fulltext !== '') {
			return {
				visible: true,
				text:
					'Malý počet výsledků, chcete zkusit zrušit fulltext ' +
					this.props.config.fulltext +
					' a získat tak další nabídky?',
				link: realityUrlBuilder(
					Object.assign({}, this.props.config, { fulltext: '' })
				),
			}
		}

		if (this.props.config.others && this.props.config.others.length !== 0) {
			return {
				visible: true,
				text:
					'Malý počet výsledků, chcete zkusit zrušit dodatečné atributy a získat tak další nabídky?',
				link: realityUrlBuilder(
					Object.assign({}, this.props.config, { others: [] })
				),
			}
		}

		if (
			this.props.config.disposition &&
			this.props.config.disposition.length !== 0
		) {
			return {
				visible: true,
				text:
					'Malý počet výsledků, chcete zkusit zrušit dispozice a získat tak další nabídky?',
				link: realityUrlBuilder(
					Object.assign({}, this.props.config, { disposition: [] })
				),
			}
		}
	}

	getHintMAX = () => {
		if (!this.props.config.kind || this.props.config.kind === '') {
			return {
				visible: true,
				text: 'Jaký typ nemovitosti hledáte?',
				select: (
					<SelectHint
						options={basicTypes.map((v) => ({ label: v.v, value: v.url }))}
						config={this.props.config}
						context={this.props.context}
						attr="kind"
						multiple={false}
					/>
				),
			}
		}

		if (this.props.config.kind === PROPERTY_TYPE.FLATS) {
			if (
				!this.props.config.disposition ||
				this.props.config.disposition.length === 0
			) {
				return {
					visible: true,
					text: 'Jaké dispozice bytu hledáte?',
					select: (
						<SelectHint
							options={disposition.map((v) => ({ label: v.v, value: v.url }))}
							config={this.props.config}
							context={this.props.context}
							attr="disposition"
							multiple
						/>
					),
				}
			}

			if (
				!this.props.config.apartmentArea ||
				this.props.config.apartmentArea.length === 0
			) {
				return {
					visible: true,
					text: 'Jak velký byt hledáte?',
					select: (
						<SelectHint
							options={apartmentArea.map((v) => ({ label: v.v, value: v.url }))}
							config={this.props.config}
							context={this.props.context}
							attr="apartmentArea"
							multiple
						/>
					),
				}
			}
		}

		if (this.props.config.kind === PROPERTY_TYPE.HOUSES) {
			if (
				!this.props.config.dispositionHouse ||
				this.props.config.dispositionHouse.length === 0
			) {
				return {
					visible: true,
					text: 'Jaké dispozice domu hledáte?',
					select: (
						<SelectHint
							options={dispositionHouse.map((v) => ({
								label: v.v,
								value: v.url,
							}))}
							config={this.props.config}
							context={this.props.context}
							attr="dispositionHouse"
							multiple
						/>
					),
				}
			}

			if (!this.props.config.floor || this.props.config.floor.length === 0) {
				return {
					visible: true,
					text: 'Jak velký dům hledáte?',
					select: (
						<SelectHint
							options={floor.map((v) => ({ label: v.v, value: v.url }))}
							config={this.props.config}
							context={this.props.context}
							attr="floor"
							multiple
						/>
					),
				}
			}
		}
	}

	render() {
		let hint = this.getHint()

		return (
			<div className={styles.base}>
				{hint.visible && (
					<div
						className={`${styles.hint} ${
							this.props.isOpen ? styles.hint__open : styles.hint__close
						}`}>
						<Title type="h2" className={styles.title} display="block">
							{hint.text}
						</Title>

						{hint.link && (
							<div>
								<Link to={hint.link}>
									<Button>Ano</Button>
								</Link>
								&nbsp;
								<Button onClick={this.toggleHint}>Ne</Button>
							</div>
						)}

						{hint.select}

						{(this.props.isOpen && (
							<Close onClick={this.toggleHint} className={styles.toggleClose} />
						)) || (
							<span onClick={this.toggleHint} className={styles.toggleOpen}>
								Zobrazit filtr
							</span>
						)}
					</div>
				)}
			</div>
		)
	}
}

AdaptiveFiltering.defaultProps = {}

AdaptiveFiltering.propTypes = {
	count: PropTypes.number.isRequired,
	config: PropTypes.object.isRequired,
	context: PropTypes.object.isRequired,
	onToggle: PropTypes.func.isRequired,
	actions: PropTypes.object,
	isOpen: PropTypes.bool,
}

export default AdaptiveFiltering
