import React from 'react'
import Input from '../Input'

const InputPassword = (props) => (
  <Input type='password' {...props} />
)

InputPassword.propTypes = {}

export default InputPassword
