import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './NewOffersConfirm.module.scss'
import Page from '../../component/Page'
import Button from '../../component/Button'
import OpenCardHeader from '../../component/OpenCardHeader'
import Title from '../../component/Title'
import Row from '../../component/Row'
import Col from '../../component/Col'

class NewOffersConfirm extends Component {
	onClose = () => {
		this.props.actions.closeOpenCard()
	}

	redirectToNewOffers = () => {
		this.onClose()
		this.props.callback()
	}

	render() {
		return (
			<div className={styles.base}>
				<div className={styles.section}>
					<Page>
						<OpenCardHeader onBack={this.onClose} title="Nové nabídky" />

						<div className={styles.content}>
							<Row>
								<Col xs="12/12">
									<Title type="p6" className={styles.title} display="block">
										Přejete si zobrazit nové nabídky odpovídající parametrům
										Vašich uložených hledání?
									</Title>
								</Col>
							</Row>

							<Row className={styles['button-row']}>
								<Col xs="6/12">
									<Button
										formType="button"
										size="cover"
										type="positive"
										onClick={this.redirectToNewOffers}>
										Ano
									</Button>
								</Col>

								<Col xs="6/12">
									<Button
										formType="button"
										size="cover"
										type="negative"
										onClick={this.onClose}>
										Ne
									</Button>
								</Col>
							</Row>
						</div>
					</Page>
				</div>
			</div>
		)
	}
}

NewOffersConfirm.propTypes = {
	callback: PropTypes.func,
	actions: PropTypes.shape({
		closeOpenCard: PropTypes.func,
	}),
}

NewOffersConfirm.contextTypes = {
	router: PropTypes.object,
}

export default NewOffersConfirm
