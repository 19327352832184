import React from 'react'
import Input from '../Input'

const emailRegex = '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$'

const InputEmail = (props) => (
  <Input
    type='email'
    pattern={emailRegex}
    {...props} />
)

InputEmail.propTypes = {}

export default InputEmail
