import { API_URL } from './AppConstants'

// -----------------------------------------------------------------------------
// Action constants
// -----------------------------------------------------------------------------

export const AUTH_API_LOAD_START_ACTION = 'AUTH_API_LOAD_START_ACTION'

export const AUTH_API_LOAD_SUCCESS_ACTION = 'AUTH_API_LOAD_SUCCESS_ACTION'

export const AUTH_API_LOAD_FAILURE_ACTION = 'AUTH_API_LOAD_FAILURE_ACTION'

export const AUTH_API_LOGOUT_SUCCESS_ACTION = 'AUTH_API_LOGOUT_SUCCESS_ACTION'

export const AUTH_API_DELETE_ACCOUNT_START_ACTION =
	'AUTH_API_DELETE_ACCOUNT_START_ACTION'

export const VERIFY_ACCOUNT_START_ACTION = 'VERIFY_ACCOUNT_START_ACTION'

export const VERIFY_ACCOUNT_SUCCESS_ACTION = 'VERIFY_ACCOUNT_SUCCESS_ACTION'

export const VERIFY_ACCOUNT_FAILURE_ACTION = 'VERIFY_ACCOUNT_FAILURE_ACTION'

export const RESET_PASSWORD_START_ACTION = 'RESET_PASSWORD_START_ACTION'

export const RESET_PASSWORD_SUCCESS_ACTION = 'RESET_PASSWORD_SUCCESS_ACTION'

export const RESET_PASSWORD_FAILURE_ACTION = 'RESET_PASSWORD_FAILURE_ACTION'

// -----------------------------------------------------------------------------
// URL constants
// -----------------------------------------------------------------------------

export const LOGIN_URL = `${API_URL}/moje-reality/prihlasit2/`

export const REGISTRATION_URL = `${API_URL}/moje-reality/registrace2/`

export const LOGIN_WITH_FACEBOOK_URL = `${API_URL}/moje-reality/fblogin/`

export const LOGIN_WITH_GOOGLE_URL = `${API_URL}/moje-reality/glogin/`

export const DELETE_ACCOUNT_URL = `${API_URL}/moje-reality/zruseni/`

export const VERIFY_ACCOUNT_URL = `${API_URL}/moje-reality/verify/:verificationToken`

export const RESET_PASSWORD_URL = `${API_URL}/moje-reality/pwreset2/`

// -----------------------------------------------------------------------------
// Events constants
// -----------------------------------------------------------------------------

export const LOGIN_EVENT = 'LOGIN_EVENT'

export const REGISTER_EVENT = 'REGISTER_EVENT'
