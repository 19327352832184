import { Link } from 'react-router'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Col from '../Col'
import Row from '../Row'
import Logo from '../Logo'
import Navigation from '../Navigation'
import styles from './Header.module.scss'
import Hamburger from '../Hamburger'
import Menu from '../Menu'
import Page from '../Page'

class Header extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isOpen: false,
		}
	}

	toggleMenu = () => {
		this.setState((prevState) => ({
			isOpen: !prevState.isOpen,
		}))
	}

	closeMenu = () => {
		this.setState({ isOpen: false })
	}

	render() {
		return (
			<div>
				<div className={styles.base}>
					<div
						className={
							this.state.isOpen ? styles.hamburger__isOpen : styles.hamburger
						}
						onClick={this.toggleMenu}>
						<Hamburger isOpen={this.state.isOpen} />
					</div>

					<Page>
						<Row>
							<Col xs="3/12">
								{/* <div className={styles.hamburger}>
                 	<Hamburger onClick={this.toggleMenu} isOpen={this.state.isOpen}/>
                 </div> */}
							</Col>
							<Col xs="6/12">
								<Link to="/" className={styles.logo} onClick={this.closeMenu}>
									<Logo />
								</Link>
							</Col>
							<Col xs="3/12">
								<div className={styles.nav} onClick={this.closeMenu}>
									<Navigation
										favscounter={this.props.userData.get('favsSize')}
									/>
								</div>
							</Col>
						</Row>
					</Page>
				</div>
				<div className={this.state.isOpen ? styles.isOpen : styles.isClose}>
					<Menu onClick={this.closeMenu} />
				</div>
			</div>
		)
	}
}

Header.propTypes = {
	userData: PropTypes.object,
}

export default Header
