// -----------------------------------------------------------------------------
// Open card type constants
// -----------------------------------------------------------------------------

export const OPEN_CARD_GALLERY = 'OPEN_CARD_GALLERY'

export const OPEN_CARD_MAP = 'OPEN_CARD_MAP'

export const OPEN_CARD_CONTACTS = 'OPEN_CARD_CONTACTS'

export const OPEN_CARD_CONTACT = 'OPEN_CARD_CONTACT'

export const OPEN_CARD_LOCATION = 'OPEN_CARD_LOCATION'

export const OPEN_CARD_DELETE_ACCOUNT = 'OPEN_CARD_DELETE_ACCOUNT'

export const OPEN_CARD_CHANGE_PASSWORD = 'OPEN_CARD_CHANGE_PASSWORD'

export const OPEN_CARD_LOGOUT_CONFIRM = 'OPEN_CARD_LOGOUT_CONFIRM'

export const OPEN_CARD_NEW_OFFERS_CONFIRM = 'OPEN_CARD_NEW_OFFERS_CONFIRM'

// -----------------------------------------------------------------------------
// Action constants
// -----------------------------------------------------------------------------

export const OPEN_CARD_SET_ACTION = 'OPEN_CARD_SET_ACTION'

export const OPEN_CARD_CLOSE_ACTION = 'OPEN_CARD_CLOSE_ACTION'
