import Add2Favs from './Add2Favs'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { saveToFavs, saveToFavsLocally } from '../../reducer/UserDataReducer'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators({ saveToFavs, saveToFavsLocally }, dispatch),
})

const mapStateToProps = (state) => ({
	favs: state.userData.get('favs'),
	isAuthenticated: state.authApi.get('isAuthenticated'),
})

export default connect(
	mapStateToProps,
	mapActionCreators
)(Add2Favs)
