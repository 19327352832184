export const API_DOMAIN = 'api.reality.cz'
export const API_URL = 'https://' + API_DOMAIN

export const WWW_DOMAIN = 'www.reality.cz'
export const WWW_URL = 'https://' + WWW_DOMAIN

export const APPLE_APP_LINK =
	'https://itunes.apple.com/cz/app/apple-store/id397680650?mt=8'

export const GOOGLE_APP_LINK =
	'https://play.google.com/store/apps/details?id=cz.ulikeit.reality&referrer=utm_source%3Dweb%26utm_medium%3Dinfo'

export const FACEBOOK_PAGE_LINK = 'https://www.facebook.com/reality.cz/'

export const TERMS_PAGE_LINK = WWW_URL + '/info/inzerce/duvody/'
export const CONTACTS_PAGE_LINK = WWW_URL + '/info/kontakty/'
export const MOBILE_APP_LINK = WWW_URL + '/info/mobilni/'

export const ERROR_REPORT_URL = WWW_URL + '/info/merror/'
