import React from 'react'
import Input from '../Input'

const InputText = (props) => (
  <Input type='text' {...props} />
)

InputText.propTypes = {}

export default InputText
