import AddressAutocomplete from './AddressAutocomplete'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { load } from '../../reducer/AddressApiReducer'
import { setGeoLocation } from '../../reducer/SearchConfigReducer'

const mapActionCreators = (dispatch) => ({
  actions: bindActionCreators({ load, setGeoLocation }, dispatch)
})

const mapStateToProps = (state) => ({
  data: state.addressApi.get('data'),
  isLoading: state.addressApi.get('isLoading')
})

export default connect(mapStateToProps, mapActionCreators)(AddressAutocomplete)

