import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './SearchForm.module.scss'
import InputText from '../InputText'
import Input from '../Input'
import Row from '../Row'
import Col from '../Col'
import { Link } from 'react-router'
import realityUrlBuilder from '../../utils/realityUrlBuilder'
import CheckBoxMatrix from '../CheckBoxMatrix'
import Field from '../Field'
import Button from '../Button'
import { OPEN_CARD_LOCATION } from '../../constants/OpenCardConstants'
import Select from '../Select'
import SearchFormAddressList from '../SearchFormAddressList'
import SearchFormKinds from '../SearchFormKinds'
import Title from '../Title'
import {
	OFFER_TYPE,
	PROPERTY_TYPE,
	disposition,
	floor,
	ground,
	others,
	othersHouse,
	types,
	apartmentArea,
	ownership,
	buildingtype,
	dispositionHouse,
	recreation,
	kindofplot,
	commercial,
	typeOfProperty,
	restriction,
} from '../../constants/SearchConfigConstants'

class SearchForm extends Component {
	constructor(props) {
		super(props)

		if (props.setRef) {
			props.setRef(this)
		}

		this.onIChange()
	}

	// get information about count of result before submit the form
	onIChange = () => {
		// debounce of state change
		clearTimeout(this.debounceCounter)

		this.debounceCounter = setTimeout(() => {
			if (this.isValid().ok) {
				this.props.actions.load(this.props.searchConfig.toJS(), true)
			}
		}, 400)
	}

	openLocationAdder = () => {
		this.props.actions.setOpenCard(OPEN_CARD_LOCATION)
	}

	removeAddress = (address) => {
		address.forEach((v) => this.props.actions.removeAddress(v))
		this.onIChange()
	}

	beforeSubmit = (e) => {
		let valid = this.isValid()

		if (!valid.ok || this.props.preCounter === 0) {
			e.preventDefault()
			e.stopPropagation()
			alert(valid.err)
			return false
		}
		// set we go to list from advanced search form
		this.props.actions.setReferrer('advance')
		// save params to list of last search result
		this.props.actions.pushLast(this.props.searchConfig.toJS())
	}

	isValid = () => {
		const address = this.props.searchConfig.get('address')
		if (
			(address.hasOwnProperty('size') && address.size === 0) ||
			(address.hasOwnProperty('length') && address.length === 0)
		) {
			return { ok: false, err: 'Musíte vyplnit alespoň jednu adresu' }
		}

		return { ok: true, err: 'Vašim parametrům neodpovídá žádná položka' }
	}

	reset = () => {
		this.props.actions.reset()
		this.props.actions.clearCounter()
	}

	render() {
		let a = this.props.actions
		let s = this.props.searchConfig.toJS()

		return (
			<div className={styles.base}>
				<div className={styles.offers}>
					<div>
						<button
							onClick={() => this.onIChange(a.setOffer(OFFER_TYPE.SELL))}
							className={s.offer === OFFER_TYPE.SELL ? styles.selected : ''}>
							Prodej
						</button>
						<button
							onClick={() => this.onIChange(a.setOffer(OFFER_TYPE.RENT))}
							className={s.offer === OFFER_TYPE.RENT ? styles.selected : ''}>
							Pronájem
						</button>
					</div>
				</div>

				<SearchFormKinds onChange={this.onIChange} />

				<Row>
					<Col xs="12/12">
						<Title type="h2" className={styles.title} display="block">
							Lokalita
						</Title>
					</Col>
				</Row>

				<SearchFormAddressList
					address={s.address}
					onRemove={this.removeAddress}
				/>

				<span className={styles.addLocation} onClick={this.openLocationAdder}>
					Přidat lokalitu
				</span>

				<section>
					<Row>
						<Col xs="12/12">
							<Title type="h2" className={styles.title} display="block">
								Cena
							</Title>
						</Col>
					</Row>

					<Row className={styles.Header}>
						<Col xs="1/2">Od</Col>
						<Col xs="1/2">Do</Col>
					</Row>

					<Row className={styles.Header}>
						<Col xs="1/2">
							<Field
								label=""
								type={s.from < 0 ? 'negative' : 'default'}
								fieldError="není kladné číslo">
								<Input
									type="text"
									onChange={(e) => this.onIChange(a.setFrom(e.target.value))}
									value={s.from === 0 ? '' : s.from}
									pattern="[0-9 ]+"
								/>
							</Field>
						</Col>
						<Col xs="1/2">
							<Field
								label=""
								type={s.to < 0 ? 'negative' : 'default'}
								fieldError="není kladné číslo">
								<Input
									type="text"
									onChange={(e) => this.onIChange(a.setTo(e.target.value))}
									value={s.to === 'max' ? '' : s.to}
									pattern="[0-9 ]+"
								/>
							</Field>
						</Col>
					</Row>
				</section>

				<section>
					<Row>
						<Col xs="12/12">
							<Title type="h2" className={styles.title} display="block">
								Jednotka
							</Title>
						</Col>
					</Row>

					<Row className={styles.Header}>
						<Col xs="2/2">
							{s.offer === OFFER_TYPE.RENT && (
								<Select
									value={s.unit}
									options={[
										{ label: 'Kč/měs', value: 4 },
										{ label: 'Kč/měs/m²', value: 2 },
										{
											label: '€/měsíc',
											value: 16,
										},
										{ label: '€/měsíc/m²', value: 14 },
									]}
									onChange={(e) => this.onIChange(a.setUnit(e.target.value))}
								/>
							)}

							{s.offer === OFFER_TYPE.SELL && (
								<Select
									value={s.unit}
									options={[
										{ label: 'Kč', value: 2 },
										{ label: 'Kč/m²', value: 1 },
										{
											label: '€',
											value: 8,
										},
										{ label: '€/m²', value: 7 },
									]}
									onChange={(e) => this.onIChange(a.setUnit(e.target.value))}
								/>
							)}
						</Col>
					</Row>
				</section>

				{s.kind === PROPERTY_TYPE.FLATS && (
					<Row>
						<Col xs="12/12">
							<Title type="h2" className={styles.title} display="block">
								Dispozice bytu
							</Title>
							<CheckBoxMatrix
								data={disposition}
								prefix="D"
								onClick={(e) => this.onIChange(a.setDisposition(e))}
								selected={s.disposition}
							/>

							<Title type="h2" className={styles.title} display="block">
								Plocha bytu
							</Title>
							<CheckBoxMatrix
								data={apartmentArea}
								prefix="AA"
								onClick={(e) => this.onIChange(a.setApartmentArea(e))}
								selected={s.apartmentArea}
							/>

							<Title type="h2" className={styles.title} display="block">
								Vlastnictví
							</Title>
							<CheckBoxMatrix
								data={ownership}
								prefix="V"
								onClick={(e) => this.onIChange(a.setOwnerShip(e))}
								selected={s.ownership}
							/>

							<Title type="h2" className={styles.title} display="block">
								Typ budovy
							</Title>
							<CheckBoxMatrix
								data={buildingtype}
								prefix="BT"
								onClick={(e) => this.onIChange(a.setBuildingType(e))}
								selected={s.buildingtype}
							/>

							<Title type="h2" className={styles.title} display="block">
								Ostatní
							</Title>
							<CheckBoxMatrix
								data={others}
								prefix="O"
								onClick={(e) => this.onIChange(a.setOthers(e))}
								selected={s.others}
							/>
						</Col>
					</Row>
				)}

				{s.kind === PROPERTY_TYPE.HOUSES && (
					<Row>
						<Col xs="12/12">
							<Title type="h2" className={styles.title} display="block">
								Dispozice Domu
							</Title>
							<CheckBoxMatrix
								data={dispositionHouse}
								prefix="D"
								onClick={(e) => this.onIChange(a.setDispositionHouse(e))}
								selected={s.dispositionHouse}
							/>

							<Title type="h2" className={styles.title} display="block">
								Užitná plocha domu
							</Title>
							<CheckBoxMatrix
								data={floor}
								prefix="F"
								onClick={(e) => this.onIChange(a.setFloor(e))}
								selected={s.floor}
							/>

							<Title type="h2" className={styles.title} display="block">
								Plocha pozemku
							</Title>
							<CheckBoxMatrix
								data={ground}
								prefix="G"
								onClick={(e) => this.onIChange(a.setGround(e))}
								selected={s.ground}
							/>

							<Title type="h2" className={styles.title} display="block">
								Ostatní
							</Title>
							<CheckBoxMatrix
								data={othersHouse}
								prefix="OH"
								onClick={(e) => this.onIChange(a.setOthersHouse(e))}
								selected={s.othersHouse}
							/>

							<Title type="h2" className={styles.title} display="block">
								Druh domu
							</Title>
							<CheckBoxMatrix
								data={types}
								prefix="T"
								onClick={(e) => this.onIChange(a.setTypes(e))}
								selected={s.types}
							/>
						</Col>
					</Row>
				)}

				{s.kind === PROPERTY_TYPE.RECREATION && (
					<Row>
						<Col xs="12/12">
							<Title type="h2" className={styles.title} display="block">
								Druh rekreace
							</Title>
							<CheckBoxMatrix
								data={recreation}
								prefix="R"
								onClick={(e) => this.onIChange(a.setRecreation(e))}
								selected={s.recreation}
							/>

							<Title type="h2" className={styles.title} display="block">
								Plocha pozemku
							</Title>
							<CheckBoxMatrix
								data={ground}
								prefix="G"
								onClick={(e) => this.onIChange(a.setGround(e))}
								selected={s.ground}
							/>

							<Title type="h2" className={styles.title} display="block">
								Užitná plocha domu
							</Title>
							<CheckBoxMatrix
								data={floor}
								prefix="F"
								onClick={(e) => this.onIChange(a.setFloor(e))}
								selected={s.floor}
							/>
						</Col>
					</Row>
				)}

				{s.kind === PROPERTY_TYPE.LAND && (
					<Row>
						<Col xs="12/12">
							<Title type="h2" className={styles.title} display="block">
								Druh pozemku
							</Title>
							<CheckBoxMatrix
								data={kindofplot}
								prefix="R"
								onClick={(e) => this.onIChange(a.setKindOfPlot(e))}
								selected={s.kindofplot}
							/>

							<Title type="h2" className={styles.title} display="block">
								Plocha pozemku
							</Title>
							<CheckBoxMatrix
								data={ground}
								prefix="G"
								onClick={(e) => this.onIChange(a.setGround(e))}
								selected={s.ground}
							/>
						</Col>
					</Row>
				)}

				{s.kind === PROPERTY_TYPE.COMMERCIAL && (
					<Row>
						<Col xs="12/12">
							<Title type="h2" className={styles.title} display="block">
								Komerční druh
							</Title>
							<CheckBoxMatrix
								data={commercial}
								prefix="R"
								onClick={(e) => this.onIChange(a.setCommercial(e))}
								selected={s.commercial}
							/>
							<Title type="h2" className={styles.title} display="block">
								Plocha
							</Title>
							<CheckBoxMatrix
								data={floor}
								prefix="F"
								onClick={(e) => this.onIChange(a.setFloor(e))}
								selected={s.floor}
							/>
						</Col>
					</Row>
				)}

				{s.kind === PROPERTY_TYPE.OTHERS && (
					<Row>
						<Col xs="12/12">
							<Title type="h2" className={styles.title} display="block">
								Druh nemovitosti
							</Title>
							<CheckBoxMatrix
								data={typeOfProperty}
								prefix="TOP"
								onClick={(e) => this.onIChange(a.setTypeOfProperty(e))}
								selected={s.typeOfProperty}
							/>
						</Col>
					</Row>
				)}

				<Row>
					<Col xs="12/12">
						<Title type="h2" className={styles.title} display="block">
							Omezení
						</Title>
						<CheckBoxMatrix
							data={restriction}
							prefix="REST"
							onClick={(e) => this.onIChange(a.setRestriction(e))}
							selected={s.restriction}
						/>
					</Col>
				</Row>

				<section>
					<Row>
						<Col xs="12/12">
							<Title type="h2" className={styles.title} display="block">
								Hledat v popisu
							</Title>
							<Field label="">
								<InputText
									placeholder="Např. bazén, krb, atd."
									onChange={(e) =>
										this.onIChange(a.setFulltext(e.target.value))
									}
									value={s.fulltext}
								/>
							</Field>
						</Col>
					</Row>
				</section>

				<Row>
					<Col
						xs="12/12"
						sm="10/12"
						style={{ marginLeft: 'auto', marginRight: 'auto', padding: 0 }}>
						<Link to={realityUrlBuilder(s)} onClick={this.beforeSubmit}>
							<Field
								type={
									this.props.preCounter === 0 || !this.isValid().ok
										? 'disabled'
										: 'default'
								}>
								<Button
									size="cover"
									loading={this.props.preCounterIsLoading}
									type={
										this.props.preCounter === 0 || !this.isValid().ok
											? 'disabled'
											: 'default'
									}>
									{!this.isValid().ok && 'Vyhledat'}
									{!this.props.preCounterIsLoading && this.isValid().ok && (
										<span>
											{(this.props.preCounter &&
												`Vyhledat (${
													this.props.preCounter <= 1000
														? this.props.preCounter
														: '1000+'
												})`) ||
												`Vyhledat`}
										</span>
									)}
								</Button>
							</Field>
						</Link>
					</Col>
				</Row>

				<Row>
					<Col xs="12/12">
						{s.address.length === 0 && (
							<div className={styles.zero}>
								Pro pokračování musíte zadat lokalitu.
							</div>
						)}

						{!this.props.preCounterIsLoading &&
							this.props.preCounter === 0 &&
							this.isValid().ok && (
								<div className={styles.zero}>
									Vašim požadavkům nevyhovuje žádná nemovitost.
								</div>
							)}

						<div className={styles.reset}>
							<span onClick={() => this.reset()}>Resetovat formulář</span>
						</div>
					</Col>
				</Row>
			</div>
		)
	}
}

SearchForm.defaultProps = {}

SearchForm.propTypes = {
	searchConfig: PropTypes.object.isRequired,
	actions: PropTypes.object,
	setRef: PropTypes.func,
	preCounter: PropTypes.any,
	preCounterIsLoading: PropTypes.bool,
}

export default SearchForm
