import React from 'react'
import PropTypes from 'prop-types'
import styles from './Heading.module.scss'
import { omit } from 'lodash'

const Heading = (props) => {
	const { priority, children } = props
	const HeadingTag = `h${priority}`
	return (
		<HeadingTag
			{...omit(props, ['priority', 'inverse'])}
			className={`${styles[HeadingTag]} ${props.className}`}>
			{children}
		</HeadingTag>
	)
}

Heading.defaultProps = {
	inverse: false,
}

Heading.propTypes = {
	children: PropTypes.node.isRequired,
	priority: PropTypes.number.isRequired,
	className: PropTypes.string,
}

export default Heading
