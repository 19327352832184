import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router'
import { load, empty, saveNote } from '../../../reducer/DetailApiReducer'
import { setReferrer } from '../../../reducer/SearchConfigReducer'
import Detail from '../component/Detail'
import { setOpenCard, closeOpenCard } from '../../../reducer/OpenCardReducer'
import { loadSettings } from '../../../reducer/SettingsReducer'
import { OFFER_TYPE } from '../../../constants/SearchConfigConstants'

const isValid = (id) => {
	return /^\w{3}-\w{6}$/gi.test(id)
}

export const onEnter = ({ dispatch }) => (props) => {
	const { params } = props
	const { id } = params
	dispatch(empty())

	// redirect to HomePage if the page are
	const redirectHome = [OFFER_TYPE.SELL, OFFER_TYPE.RENT]
	if (redirectHome.indexOf(id) >= 0 && typeof window !== 'undefined') {
		push('/')
		return
	}

	// do nothing if the ID is not valid
	if (!isValid(id)) {
		return
	}

	return dispatch(load(id))
}

const isLoading = (state) => {
	return state.detailApi.get('isLoading')
}

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators(
		{
			load,
			empty,
			setReferrer,
			setOpenCard,
			closeOpenCard,
			saveNote,
			loadSettings,
		},
		dispatch
	),
})

const mapStateToProps = (state, locals) => {
	let data = {
		err: 'Požadovanou stránku nemůžeme najít, ale můžete zkusit',
	}

	if (isValid(locals.params.id)) {
		data = state.detailApi.get('data')
	}

	const profile = state.settings.has('profile')
		? state.settings.get('profile')
		: null

	return {
		data,
		searchConfig: state.searchConfig,
		isLoading: isLoading(state),
		error: state.detailApi.get('error'),
		opencard: state.openCard.get('openCard'),
		isAuthenticated: state.authApi.get('isAuthenticated'),
		loggedUser: profile,
	}
}

export default connect(
	mapStateToProps,
	mapActionCreators
)(Detail)
