import React from 'react'
import PropTypes from 'prop-types'
import styles from './Table.module.scss'

function Table(props) {
	const { children } = props

	return (
		<table className={styles.base}>
			<tbody>{children}</tbody>
		</table>
	)
}

Table.defaultProps = {}

Table.propTypes = {
	children: PropTypes.node,
}

export default Table
