import ListCard from './ListCard'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setScrolltop } from '../../reducer/AppDataReducer'

const setScrollTop = (dispatch) => (index) => {
	dispatch(setScrolltop(index))
}

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators({ setScrolltop }, dispatch),
	setScrollTop: setScrollTop(dispatch),
})

const mapStateToProps = () => ({})

export default connect(
	mapStateToProps,
	mapActionCreators
)(ListCard)
