import { API_URL } from './AppConstants'
// -----------------------------------------------------------------------------
// Action constants
// -----------------------------------------------------------------------------

export const DETAIL_API_LOAD_START_ACTION = 'DETAIL_API_LOAD_START_ACTION'

export const DETAIL_API_LOAD_SUCCESS_ACTION = 'DETAIL_API_LOAD_SUCCESS_ACTION'

export const DETAIL_API_LOAD_FAILURE_ACTION = 'DETAIL_API_LOAD_FAILURE_ACTION'

export const DETAIL_API_SAVE_NOTE_START_ACTION = 'DETAIL_API_SAVE_NOTE_START_ACTION'

export const DETAIL_API_SAVE_NOTE_SUCCESS_ACTION = 'DETAIL_API_SAVE_NOTE_SUCCESS_ACTION'

export const DETAIL_API_SAVE_NOTE_FAILURE_ACTION = 'DETAIL_API_SAVE_NOTE_FAILURE_ACTION'

export const DETAIL_EMPTY_ACTION = 'DETAIL_EMPTY_ACTION'

// -----------------------------------------------------------------------------
// URL constants
// -----------------------------------------------------------------------------

export const DETAIL_URL = `${API_URL}/:id/`

export const SAVE_NOTE_URL = `${API_URL}/moje-reality/savenote-:advertisement_id/`
