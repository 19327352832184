import RealityUrlDebuilder from './realityUrlDeBuilder'
import {
	SelectionTypes,
	KindTypes,
	OfferTypes,
	PRICE,
} from '../constants/SearchConfigConstants'

const parseListUrl = (pathname) => {
	const pathParams = pathname.split('/').splice(1)
	const result = {}

	// recognition of special selections, remove first item from pathParams
	const selectionIndex = SelectionTypes.findIndex(
		(el) => el === pathParams[0].toLowerCase()
	)
	if (selectionIndex >= 0) {
		result.selection = pathParams.shift()
		if (selectionIndex < 2) {
			// read and remove second item from pathParams for /firma and /drazby
			result.seldata = pathParams.shift()
		}
	}

	for (const param of pathParams) {
		if (OfferTypes.includes(param)) {
			result.offer = param
		} else if (KindTypes.includes(param)) {
			result.kind = param
		} else if (param.indexOf(PRICE) === 0) {
			result.price = param
		} else if (param === '') {
			// nothing, ignore empty parameter
		} else {
			// anything unrecognized is an address
			result.address = param
		}
	}

	return result
}

export default ({ pathname, query, params }) => {
	const config = parseListUrl(pathname)
	return RealityUrlDebuilder(Object.assign({}, query, params, config))
}
