import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './Contact.module.scss'
import Button from '../Button'
import AdvertiserImage from '../AdvertiserImage'
import Row from '../Row'
import Col from '../Col'
import Page from '../Page'
import Heading from '../Heading'
import Title from '../Title'
import { trackGoogleAnalytics } from '../../App'

class Contact extends Component {
	getPhoneToCall() {
		let realEstate =
			this.props.data.real_estate &&
			this.props.data.real_estate.phones &&
			this.props.data.real_estate.phones[0]
		let broker =
			this.props.data.broker &&
			this.props.data.broker.phones &&
			this.props.data.broker.phones[0]
		let advertiser =
			this.props.data.advertiser &&
			this.props.data.advertiser.phones &&
			this.props.data.advertiser.phones[0]

		return advertiser || realEstate || broker || false
	}

	hasLogo(data) {
		return (
			(data.real_estate && data.real_estate.logo) ||
			(data.broker && data.broker.logo)
		)
	}

	render() {
		let { data, openContactForm } = this.props

		return (
			<div
				className={`${styles.base} ${
					this.hasLogo(this.props.data) ? styles.bgr : ''
				}`}>
				<Page>
					{((!data.advertiser ||
						(data.advertiser.has_name || data.advertiser.company)) && (
						<div>
							<Heading priority={2}>Nemovitost nabízí</Heading>
							<div className={styles.main}>
								<AdvertiserImage data={data} type="inRows" />
							</div>
						</div>
					)) || (
						<Title type="h4">
							Inzerent této nabídky si nepřeje uvádět svůj kontakt veřejně, pro
							jeho kontaktování nebo dotazy klikněte na tlačítko Napsat.
						</Title>
					)}

					{(this.getPhoneToCall() && (
						<Row className={styles.buttons}>
							<Col xs="6/12">
								<span onClick={openContactForm}>
									<Button size="cover" ico="pencl">
										Napsat
									</Button>
								</span>
							</Col>

							<Col xs="6/12">
								<a
									href={`tel:${this.getPhoneToCall()}`}
									onClick={() => {
										trackGoogleAnalytics(['send', 'event', 'Detail', 'Volat'])
									}}>
									<Button size="cover" ico="call">
										Volat
									</Button>
								</a>
							</Col>
						</Row>
					)) || (
						<Row className={styles.buttons}>
							<Col xs="12/12">
								<div className={styles.center}>
									<span onClick={openContactForm}>
										<Button size="cover" ico="pencl">
											Napsat
										</Button>
									</span>
								</div>
							</Col>
						</Row>
					)}
				</Page>
			</div>
		)
	}
}

Contact.defaultProps = {}

Contact.propTypes = {
	data: PropTypes.object.isRequired,
	openContactForm: PropTypes.func,
}

export default Contact
