import configs from '../config/config'

class ConfigurationService {

  /**
   * Static function to get app config for current build (dev, stable, prod)
   *
   * @returns {Object}
   */
  static getConfigByOrigin () {
    const currentUrl = window.location.origin
    return (currentUrl in configs) ? configs[currentUrl] : configs['production']
  }

  constructor () {
    this.config = null
  }

  get sentryKey () {
    if (!this.config) {
      this.config = ConfigurationService.getConfigByOrigin()
    }

    return this.config.sentry
  }

  get facebookAppId () {
    if (!this.config) {
      this.config = ConfigurationService.getConfigByOrigin()
    }

    return this.config.facebookAppId
  }

  get googleApiId () {
    if (!this.config) {
      this.config = ConfigurationService.getConfigByOrigin()
    }

    return this.config.googleApiId
  }

  get recaptchaKey () {
    if (!this.config) {
      this.config = ConfigurationService.getConfigByOrigin()
    }

    return this.config.recaptchaKey
  }
}

export default new ConfigurationService()
