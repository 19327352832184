import React from 'react'
import PropTypes from 'prop-types'
import styles from './Spinner.module.scss'
import preloaderBig from './preloader_big.gif'
import preloaderSmall from './preloader_small.gif'

function Spinner(props) {
	let inverseClass = props.hasOwnProperty('inverse') ? styles.inverse : ''
	return (
		<div
			className={`${styles.base} ${inverseClass} ${props.className} ${
				styles['size_' + props.size]
			}`}>
			{props.size === 'default' && <img src={preloaderBig} alt="loading..." />}

			{props.size === 'small' && <img src={preloaderSmall} alt="loading..." />}
		</div>
	)
}

Spinner.defaultProps = {
	size: 'default',
}

Spinner.propTypes = {
	size: PropTypes.string,
	className: PropTypes.string,
}

export default Spinner
