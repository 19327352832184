import React from 'react'
import PropTypes from 'prop-types'
import styles from './OpenCard.module.scss'

const OpenCard = ({ children, open }) => (
	<div className={open ? styles.openCard : styles.card}>{children}</div>
)

OpenCard.defaultProps = {}

OpenCard.propTypes = {
	children: PropTypes.node,
	open: PropTypes.bool.isRequired,
}

export default OpenCard
