import React from 'react'
import PropTypes from 'prop-types'
import styles from './Paragraph.module.scss'
import createClassName from '../../utils/createClassName'

function Paragraph(props) {
	let p = createClassName(props, styles)
	let paragraphClass = styles.base + p('type')

	return (
		<p
			className={paragraphClass + ' ' + props.className}
			style={
				props.type === 'oneline'
					? { display: '-webkit-box' }
					: { display: 'block' }
			}>
			{props.children}
		</p>
	)
}

Paragraph.defaultProps = {
	className: '',
}

Paragraph.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	type: PropTypes.string,
}

export default Paragraph
