import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { load } from '../../../reducer/AddressApiReducer'
import {
	setAddress,
	setOffer,
	setReferrer,
	reset,
} from '../../../reducer/SearchConfigReducer'
import { pushLast } from '../../../reducer/UserDataReducer'
import { push } from 'react-router-redux'

import Home from '../component/Home'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators(
		{ push, reset, load, setAddress, setOffer, pushLast, setReferrer },
		dispatch
	),
})

const mapStateToProps = (state) => ({
	addressApiData: state.addressApi.get('data'),
	addressApiDataIsLoading: state.addressApi.get('isLoading'),
	offer: state.searchConfig.get('offer'),
	geo: state.searchConfig.get('geo'),
	address: state.searchConfig.get('address'),
	last: state.userData.get('last'),
	opencard: state.openCard.get('openCard'),
})

export default connect(
	mapStateToProps,
	mapActionCreators
)(Home)
