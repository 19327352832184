import React from 'react'
import PropTypes from 'prop-types'
import styles from './Home.module.scss'
import Page from '../../../component/Page'
import Paragraph from '../../../component/Paragraph'
import AddressAutocomplete from '../../../component/AddressAutocomplete'
import LastSearchItem from '../../../component/LastSearchItem'
import realityUrlBuilder from '../../../utils/realityUrlBuilder'
import Button from '../../../component/Button'
import Row from '../../../component/Row'
import Col from '../../../component/Col'
import Title from '../../../component/Title'
import getAddressName from '../../../utils/getAddressName'
import { OFFER_TYPE } from '../../../constants/SearchConfigConstants'

class Home extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			valid: true,
			fail: false,
			address: props.address.size > 0 ? [props.address.get(0)] : [],
			text: '',
			isAddressSet: props.address.size > 0,
		}
	}

	onSubmit = (e) => {
		e.preventDefault()
		e.stopPropagation()

		let { addressApiData, addressApiDataIsLoading } = this.props
		let { valid } = this.state

		let urlparams = {
			address: this.state.address,
			offer: this.props.offer,
			geo: this.props.geo || {},
		}

		let resolve = () => {
			const {
				pushLast,
				setAddress,
				setOffer,
				setReferrer,
				push,
				reset,
			} = this.props.actions

			// reset all filters
			reset()

			// save filter offer
			setOffer(urlparams.offer)

			// save filter address
			setAddress(urlparams.address[0])

			// save referrer
			setReferrer('basic')

			// save last search item to list of searching
			pushLast(urlparams)

			// redirect
			push(realityUrlBuilder(urlparams))
		}

		if (valid && urlparams.address.length > 0) {
			resolve()
			return false
		}

		if (this.state.text === '') {
			alert('Zadejte prosím adresu na které chcete hledat nemovitosti')
			return false
		}

		// auto selected last value
		if (addressApiData.size > 0) {
			if (!addressApiDataIsLoading) {
				let address = {
					url: addressApiData.get(0).get('url'),
					fullname: addressApiData.get(0).get('fullname'),
				}
				this.setAddress(address)
				urlparams.address[0] = address
				resolve()
			} else {
				// alert ('čekejte prosím, data se načítají')
			}
		} else {
			this.setState({ fail: true })
			alert(
				'Adresa, kterou jste zadali nebyla nalezena. Zvolte prosím adresu z našeptávače.'
			)
		}

		return false
	}

	setAddress = (address) => {
		// only address from autocomplete list is allowed
		this.setState({
			valid: true,
			fail: false,
			address: [address],
			isAddressSet: true,
		})

		this.props.actions.setAddress(address)
	}

	onAddressChange = (val) => {
		// invalid form
		this.setState({
			valid: false,
			fail: false,
			text: val,
		})
	}

	render() {
		return (
			<div className={styles.base}>
				<div className={styles.section}>
					<Page>
						<Row className={styles.radio}>
							<Col xs="2/12" />
							<Col
								onClick={() => this.props.actions.setOffer(OFFER_TYPE.SELL)}
								xs="4/12"
								className={`${styles.radio__item} ${styles.left} ${
									this.props.offer === OFFER_TYPE.SELL
										? styles['radio__item--selected']
										: ''
								}`}>
								<span>Prodej</span>
							</Col>
							<Col
								onClick={() => this.props.actions.setOffer(OFFER_TYPE.RENT)}
								xs="4/12"
								className={`${styles.radio__item} ${
									this.props.offer === OFFER_TYPE.RENT
										? styles['radio__item--selected']
										: ''
								}`}>
								<span>Pronájem</span>
							</Col>
						</Row>

						<Row>
							<Col xs="1/12" />
							<Col xs="10/12">
								<form onSubmit={this.onSubmit} method="GET">
									<AddressAutocomplete
										saveValue={this.setAddress}
										value={
											this.state.address[0] &&
											(this.state.address[0].fullname ||
												getAddressName(this.state.address[0].url))
										}
										onChange={this.onAddressChange}
										className={styles.autocomplete}
										invalid={this.state.fail}
									/>
									<Button
										disabled={!this.state.isAddressSet}
										ico="magnifier"
										size="cover"
										formType="submit">
										Vyhledat
									</Button>
								</form>
							</Col>
						</Row>

						<div className={styles.footer}>
							{this.props.last.size > 0 && (
								<div className={styles.lastitems}>
									<Title
										type="h2"
										className={`${styles.last_title} ${
											this.props.last.size > 2 ? styles.last_title_compact : ''
										}`}
										display="block">
										Vaše poslední hledání
									</Title>

									<Paragraph type="large" className={styles.lastitems}>
										{this.props.last.reverse().map((value, index) => (
											<LastSearchItem data={value} key={index}>
												{index === 0 && <span>Poslední: </span>}
											</LastSearchItem>
										))}
									</Paragraph>
								</div>
							)}
						</div>
					</Page>
				</div>
			</div>
		)
	}
}

Home.propTypes = {
	actions: PropTypes.object,
	addressApiData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	last: PropTypes.object,
	offer: PropTypes.string,
	geo: PropTypes.object,
	address: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	addressApiDataIsLoading: PropTypes.bool,
}

export default Home
