import React from 'react'
import PropTypes from 'prop-types'
import styles from './ListSelectSort.module.scss'
import Select from '../Select'
import Spinner from '../Spinner'

function ListSelectSort({ onChange, loading, order }) {
	const onPreChange = (value) => {
		// this timeout resolves issue #4, problem with selectbox on IOS 10.0.2
		setTimeout(() => {
			onChange(value)
		}, 100)
	}

	return (
		<div className={styles.base}>
			{!loading && (
				<Select
					options={[
						{ label: 'Výchozí řazení', value: '0' },
						{ label: 'Nejlepší shoda', value: '1' },
						{ label: 'Nejnovější', value: '2' },
						{ label: 'Nejlevnější', value: '3' },
						{ label: 'Nejdražší', value: '4' },
						{ label: 'Nejbližší', value: '5' },
					]}
					onChange={(e) => onPreChange(e.target.value)}
					value={order}
					className={styles.sort}
				/>
			)}

			{loading && <Spinner size="small" />}
		</div>
	)
}

ListSelectSort.defaultProps = {
	loading: false,
}

ListSelectSort.propTypes = {
	onChange: PropTypes.func,
	order: PropTypes.string,
	loading: PropTypes.bool,
}

export default ListSelectSort
