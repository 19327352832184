import * as Immutable from 'immutable'
import { createAction, handleActions } from 'redux-actions'
import {
	OPEN_CARD_SET_ACTION,
	OPEN_CARD_CLOSE_ACTION,
} from '../constants/OpenCardConstants'

const initialState = Immutable.fromJS({
	openCard: '',
})

export const setOpenCard = createAction(OPEN_CARD_SET_ACTION)
export const closeOpenCard = createAction(OPEN_CARD_CLOSE_ACTION)

export default handleActions(
	{
		[OPEN_CARD_SET_ACTION]: (state, action) =>
			state.merge({
				openCard: action.payload,
			}),

		[OPEN_CARD_CLOSE_ACTION]: (state) =>
			state.merge({
				openCard: '',
			}),
	},
	initialState
)
