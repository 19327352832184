import AdaptiveFiltering from './AdaptiveFiltering'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggleAdaptiveFilter } from '../../reducer/AppDataReducer'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators({ toggleAdaptiveFilter }, dispatch),
})

const mapStateToProps = (state) => ({
	isOpen: state.appData.get('adaptiveFilterIsOpen'),
})

export default connect(
	mapStateToProps,
	mapActionCreators
)(AdaptiveFiltering)
