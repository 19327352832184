import React from 'react'
import Recaptcha from 'react-grecaptcha'
import PropTypes from 'prop-types'
import styles from './ContactForm.module.scss'
import { omit } from 'lodash'
import Button from '../../component/Button'
import Page from '../../component/Page'
import AdvertiserImage from '../../component/AdvertiserImage'
import { reduxForm } from 'redux-form'
import Row from '../../component/Row'
import Col from '../../component/Col'
import Field from '../../component/Field'
import { getUrl } from '../../utils/getApiUrl'
import request from 'superagent'
import superagentPromisePlugin from 'superagent-promise-plugin'
import ConfigurationService from '../../service/ConfigurationService'
import { trackGoogleAnalytics } from '../../App'
import { API_URL } from '../../constants/AppConstants'
superagentPromisePlugin.Promise = require('bluebird')

function onSubmit(
	data,
	adId,
	callback,
	errback,
	{ needsRecaptcha = false, token = null }
) {
	callback = callback || (() => {})
	errback = errback || (() => {})

	const dataForm = {
		realname: data.name,
		phone: data.phone,
		username: data.email,
		dotazComments: data.message,
		'g-recaptcha-response': token,
	}

	const endpoint = getUrl(API_URL + '/' + encodeURIComponent(adId) + '/dotaz/')

	trackGoogleAnalytics(['send', 'event', 'Detail', 'Dotaz odeslan'])

	return request
		.post(endpoint)
		.set('Check-recaptcha', needsRecaptcha)
		.use(superagentPromisePlugin)
		.type('application/x-www-form-urlencoded')
		.send(dataForm)
		.then(callback)
		.catch(errback)
}

const handleExpiredRecaptcha = () => {
	window.grecaptcha.reset()
}

let requestSent = false
let pendingDataToBeSent = null

const submitContactForm = (
	data,
	adId,
	onSendSuccess,
	onSendFailure,
	isAuthenticated
) => {
	if (isAuthenticated) {
		onSubmit(data, adId, onSendSuccess, onSendFailure, {
			needsRecaptcha: false,
		})
	} else if (!requestSent) {
		window.grecaptcha.execute()
		requestSent = true
		pendingDataToBeSent = data
	}
}

function ContactForm(props) {
	const {
		fields: { email, phone, name, message },
		handleSubmit,
		data,
		ad_id,
		onSendSuccess,
		onSendFailure,
		isAuthenticated,
	} = props

	const omitProps = (props) => {
		return omit(props, [
			'initialValue',
			'autofill',
			'onUpdate',
			'valid',
			'invalid',
			'dirty',
			'pristine',
			'active',
			'touched',
			'visited',
			'autofilled',
		])
	}

	const onRecaptchaSuccess = (result) => {
		requestSent = false
		onSubmit(pendingDataToBeSent, ad_id, onSendSuccess, onSendFailure, {
			needsRecaptcha: true,
			token: result,
		})
	}

	return (
		<div className={styles.form}>
			<AdvertiserImage data={data} type="inColumns" />
			<Page className={styles.base}>
				<form
					onSubmit={handleSubmit((data) =>
						submitContactForm(
							data,
							ad_id,
							onSendSuccess,
							onSendFailure,
							isAuthenticated
						)
					)}>
					<Row className={styles.row}>
						<Col xs="12/12">
							<Field label="Váš e-mail">
								<input type="email" placeholder="@" {...omitProps(email)} />
							</Field>
						</Col>
					</Row>

					<Row className={styles.row}>
						<Col xs="12/12">
							<Field label="Telefon">
								<input type="text" placeholder="+420" {...omitProps(phone)} />
							</Field>
						</Col>
					</Row>

					<Row className={styles.row}>
						<Col xs="12/12">
							<Field label="Jméno">
								<input type="text" placeholder="" {...omitProps(name)} />
							</Field>
						</Col>
					</Row>

					<Row className={styles.row}>
						<Col xs="12/12">
							<Field label="Zpráva">
								<textarea {...omitProps(message)} rows={5} />
							</Field>
						</Col>
					</Row>

					<Row className={styles.row}>
						<Col xs="12/12">
							<Button formType="submit" size="cover">
								Odeslat
							</Button>
						</Col>
					</Row>
				</form>

				{!isAuthenticated && (
					<div className={styles['recaptcha']}>
						<Recaptcha
							data-badge="inline"
							sitekey={ConfigurationService.recaptchaKey}
							size="invisible"
							callback={onRecaptchaSuccess}
							expiredCallback={handleExpiredRecaptcha}
							locale="cs"
							invisible
						/>
					</div>
				)}
			</Page>
		</div>
	)
}

ContactForm.defaultProps = {}

ContactForm.propTypes = {
	handleSubmit: PropTypes.func,
	onSendSuccess: PropTypes.func,
	onSendFailure: PropTypes.func,
	fields: PropTypes.object,
	isAuthenticated: PropTypes.bool,
	data: PropTypes.any,
	ad_id: PropTypes.any,
}

export default reduxForm({
	form: 'contactForm',
	fields: ['email', 'phone', 'name', 'message'],
})(ContactForm)
