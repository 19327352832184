import React from 'react'
import PropTypes from 'prop-types'
import styles from './Social.module.scss'
import { TwitterShareButton, FacebookShareButton } from 'react-share'
import Title from '../Title'
import Row from '../Row'
import Col from '../Col'
import Page from '../Page'
import { trackGoogleAnalytics } from '../../App'

function Social(props) {
	return (
		<Page>
			<Row className={styles.base}>
				<Col xs="6/12">
					<Title type="h2">Sdílet inzerát:</Title>
				</Col>
				<Col xs="6/12" className={styles.icons}>
					<span
						onClick={() => {
							trackGoogleAnalytics(['send', 'event', 'footer', 'facebook'])
						}}>
						<FacebookShareButton
							url={props.url}
							quote={props.title}
							className={styles.fb}
						/>
					</span>

					<span
						onClick={() => {
							trackGoogleAnalytics(['send', 'event', 'footer', 'twitter'])
						}}>
						<TwitterShareButton
							url={props.url}
							quote={props.title}
							className={styles.twitter}
						/>
					</span>
					<span /* eslint-disable-next-line */>
						<a
							href={`mailto:?subject=${props.title}&body=${props.url}`}
							className={styles.email}
							onClick={() => {
								trackGoogleAnalytics(['send', 'event', 'footer', 'email'])
							}}
							title="social"
						/>
					</span>
				</Col>
			</Row>
		</Page>
	)
}

Social.defaultProps = {}

Social.propTypes = {
	url: PropTypes.string,
	title: PropTypes.string,
}

export default Social
