import React from 'react'
import PropTypes from 'prop-types'
import styles from './Badge.module.scss'

const Badge = (props) => (
	<div className={styles.base} style={{ backgroundColor: props.color }}>
		{props.text.toLowerCase()}
	</div>
)

Badge.defaultProps = {
	color: 'transparent',
	text: '',
}

Badge.propTypes = {
	color: PropTypes.string,
	text: PropTypes.string,
}

export default Badge
