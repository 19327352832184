import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './ListCardSlider.module.scss'
import Slider from 'react-motion-slider'
import ApiImage from '../ApiImage'

class ListCardSlider extends Component {
	constructor(props) {
		super(props)

		this.state = {
			selected: 0,
		}
	}

	beforeSlide = (callback) => {
		return (currentIndex) => {
			this.setState({ selected: currentIndex[0] })

			callback && callback()
		}
	}

	getClass = (k, s) => {
		if (k === s) return 'selected'
		if (k === s - 1 || k === s - 2) return 'prev'
		if (k === s + 1 || k === s + 2) return 'next'
	}

	render() {
		const { className, first, nextPhotos, beforeSlide, afterSlide } = this.props

		return (
			<Slider
				ref="slider"
				currentKey="slide-0"
				beforeSlide={this.beforeSlide(beforeSlide)}
				afterSlide={afterSlide}>
				<div key={`slide-0`} className={styles.lazy}>
					<div className={`${styles.imgcontainer} ${styles.selected}`}>
						<ApiImage src={first} background className={className} />
					</div>
				</div>

				{nextPhotos.map((v, k) => (
					<div key={`slide-${k + 1}`} className={styles.lazy}>
						<div
							className={`${styles.imgcontainer} ${
								styles[this.getClass(k + 1, this.state.selected)]
							}`}>
							<ApiImage src={v} background className={className} />
						</div>
					</div>
				))}
			</Slider>
		)
	}
}

ListCardSlider.defaultProps = {}

ListCardSlider.propTypes = {
	nextPhotos: PropTypes.array,
	first: PropTypes.string,
	className: PropTypes.string,
	beforeSlide: PropTypes.func,
	afterSlide: PropTypes.func,
}

export default ListCardSlider
