import React from 'react'
import PropTypes from 'prop-types'
import styles from './ContactItem.module.scss'
import InlineSVG from 'svg-inline-react'

function ContactItem(props) {
	const { children, type, className } = props

	return (
		(type === 'email' && (
			<a href={'mailto:' + children}>
				<div className={styles.base + ' ' + className}>
					<InlineSVG className={styles.ico + ' ' + styles.email} />
					<p>{children}</p>
				</div>
			</a>
		)) ||
		(type === 'phone' && (
			<a href={'tel:' + children}>
				<div className={styles.base + ' ' + className}>
					<InlineSVG className={styles.ico + ' ' + styles.phone} />
					<p>{children}</p>
				</div>
			</a>
		)) ||
		(type === 'fax' && (
			<a href={'fax:' + children}>
				<div className={styles.base + ' ' + className}>
					<InlineSVG className={styles.ico + ' ' + styles.fax} />
					<p>{children}</p>
				</div>
			</a>
		))
	)
}

ContactItem.defaultProps = {
	className: '',
}

ContactItem.propTypes = {
	children: PropTypes.node,
	type: PropTypes.oneOf(['phone', 'email', 'fax']),
	iteration: PropTypes.number,
	className: PropTypes.string,
}

export default ContactItem
