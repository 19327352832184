import NewOffersConfirm from './NewOffersConfirm'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { closeOpenCard } from '../../reducer/OpenCardReducer'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators(
		{
			closeOpenCard,
		},
		dispatch
	),
})

const mapStateToProps = (state) => ({
	opencard: state.openCard.get('openCard'),
})

export default connect(
	mapStateToProps,
	mapActionCreators
)(NewOffersConfirm)
