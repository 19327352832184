import Favs from './container/FavsContainer'

export default Favs

// export default (store) => ({
//   path: 'favs', // Path should be dash-lower-case
//   getComponent (nextState, next) {
//     require.ensure(
//       ['./container/FavsContainer'],
//       (require) => {
//         // Don't forget to add router to `src/route/index`
//         const Favs = require('./container/FavsContainer').default

//         next(null, Favs)
//       })
//   }
// })
