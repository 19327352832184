import React from 'react'
import PropTypes from 'prop-types'
import styles from './Settings.module.scss'
import Page from '../../../component/Page'
import PageTitle from '../../../component/PageTitle'
import Form from '../../../component/Form'
import Field from '../../../component/Field'
import Row from '../../../component/Row'
import Col from '../../../component/Col'
import Title from '../../../component/Title'
import InputText from '../../../component/InputText'
import InputEmail from '../../../component/InputEmail'
import InputNumber from '../../../component/InputNumber'
import Button from '../../../component/Button'
import Select from '../../../component/Select'
import OpenCard from '../../../component/OpenCard'
import DeleteAccount from '../../../component/DeleteAccount'
import ChangePassword from '../../../component/ChangePassword'
import LogoutConfirm from '../../../component/LogoutConfirm'
import Overlay from '../../../component/Overlay'
import {
	OPEN_CARD_DELETE_ACCOUNT,
	OPEN_CARD_CHANGE_PASSWORD,
	OPEN_CARD_LOGOUT_CONFIRM,
} from '../../../constants/OpenCardConstants'
import {
	EMAIL_FREQUENCY,
	ADVERTISER_TYPE,
	ADVERTISER_SHOW,
	ADS_DELETE_AFTER,
	VERIFICATION_STATUS,
	PUSH_NOTIFICATIONS,
	SETTINGS_LOAD_SUCCESS_ACTION,
} from '../../../constants/SettingsConstants'

class Settings extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			settingsData: {
				first_name: '',
				last_name: '',
				title1: '',
				title2: '',
				email: '',
				firm: '',
				phone: '',
				phone2_pre: '',
				phone2_nr: '',
				period: EMAIL_FREQUENCY.NEVER.value,
				push: PUSH_NOTIFICATIONS.DISABLED.value,
				advertiser_type: ADVERTISER_TYPE.NOT_SPECIFIED.value,
				ads_delete_after: ADS_DELETE_AFTER.ONE_WEEK.value,
				advertiser_show: ADVERTISER_SHOW.NOTHING.value,
			},
			errors: {
				email: false,
			},
			wasRequestSent: false,
			isLoading: false,
			emailSent: false,
		}
	}

	componentDidMount() {
		this.setState({ isLoading: true })
		this.props.actions.loadSettings().then((actionType) => {
			if (actionType === SETTINGS_LOAD_SUCCESS_ACTION) {
				let data = this.props.profile
				let phones = data.phones || []

				let czechPhone = ''
				let phoneCode = ''
				let phoneNumber = ''
				phones.forEach((phone) => {
					if (phone.startsWith('+420')) {
						czechPhone = phone.substring(5, phone.length).replace(/\s+/g, '')
					} else {
						phoneCode = phone
							.substring(1, phone.indexOf(' '))
							.replace(/\s+/g, '')
						phoneNumber = phone
							.substring(phone.indexOf(' '), phone.length)
							.replace(/\s+/g, '')
					}
				})

				this.setState({
					settingsData: {
						first_name: data.first_name || '',
						last_name: data.last_name || '',
						title1: data.title1 || '',
						title2: data.title2 || '',
						email: data.email || '',
						firm: data.firm || '',
						phone: czechPhone,
						phone2_pre: phoneCode,
						phone2_nr: phoneNumber,
						period: data.period || EMAIL_FREQUENCY.NEVER.value,
						push: data.push || PUSH_NOTIFICATIONS.DISABLED.value,
						advertiser_type:
							data.advertiser_type || ADVERTISER_TYPE.NOT_SPECIFIED.value,
						ads_delete_after:
							data.ads_delete_after || ADS_DELETE_AFTER.ONE_WEEK.value,
						advertiser_show:
							data.advertiser_show || ADVERTISER_SHOW.NOTHING.value,
					},
					isLoading: false,
				})
			}
		})
	}

	handleChange = (e) => {
		let target = e.nativeEvent.target
		let valid = target.validity.valid
		let value = target.value

		this.setState((prevState) => ({
			settingsData: {
				...prevState.settingsData,
				[target.id]: value,
			},
			errors: {
				...prevState.errors,
				[target.id]: !valid,
			},
		}))
	}

	saveSettings = (e) => {
		e.preventDefault()

		if (!this.state.settingsData.email || this.state.errors.email) {
			return
		}

		this.setState({ isLoading: true })
		this.props.actions.saveSettings(this.state.settingsData).then(() => {
			this.setState({ isLoading: false, wasRequestSent: true })

			if (this.props.error) {
				document.querySelector('input#email').focus()
			}
		})
	}

	isCardOpened = () => {
		const opencard = this.props.opencard
		return (
			opencard === OPEN_CARD_CHANGE_PASSWORD ||
			opencard === OPEN_CARD_DELETE_ACCOUNT ||
			opencard === OPEN_CARD_LOGOUT_CONFIRM
		)
	}

	openLogoutConfirmCard = () => {
		this.props.actions.setOpenCard(OPEN_CARD_LOGOUT_CONFIRM)
	}

	openDeleteAccountCard = () => {
		this.props.actions.setOpenCard(OPEN_CARD_DELETE_ACCOUNT)
	}

	openChangePasswordCard = () => {
		this.props.actions.setOpenCard(OPEN_CARD_CHANGE_PASSWORD)
	}

	sendVerificationEmail = () => {
		this.setState({ emailSent: true })
		this.props.actions.sendVerificationEmail()
	}

	render() {
		const settingsData = this.state.settingsData
		const verified = this.props.profile && this.props.profile.verified

		return (
			<Page className={styles.base}>
				<PageTitle>Nastavení</PageTitle>
				<Form onSubmit={this.saveSettings}>
					{/* Auth data section */}
					<section>
						<div className={styles['form-group--full-size-row-wrap']}>
							<Title type="h2" className={styles.title} display="block">
								Přihlašovací údaje
							</Title>
						</div>

						<div className={styles['form-group--full-size-row-wrap']}>
							<div className={styles.label}>Email</div>
							<Field label="">
								<InputEmail
									id="email"
									onChange={this.handleChange}
									value={settingsData.email}
									required
								/>
								{this.state.wasRequestSent && (
									<div className={styles.warning}>{this.props.error}</div>
								)}
							</Field>
						</div>

						<Row>
							{verified !== VERIFICATION_STATUS.VERIFIED_BY_SN.value && (
								<Col xs="6/12" className={styles['form-group']}>
									<div className={styles.label}>Heslo</div>
									<span /* eslint-disable-next-line */>
										<a
											/* eslint-disable-next-line */
											href="javascript:void(0)"
											className={styles['change-password']}
											onClick={this.openChangePasswordCard}>
											změnit
										</a>
									</span>
								</Col>
							)}

							<Col xs="6/12" className={styles['form-group']}>
								<div className={styles.label}>Ověření</div>
								{verified !== VERIFICATION_STATUS.NOT_VERIFIED.value ? (
									<div className={styles['sent']}>Učet je oveřen</div>
								) : (
									<div>
										<div className={styles['warning']}>Učet není oveřen</div>

										{this.state.emailSent && (
											<span className={styles['sent']}>
												Ověřovací email Vám byl zaslán
											</span>
										)}

										{!this.state.emailSent && (
											<span
												onClick={this.sendVerificationEmail}
												className={styles['not-sent']}>
												Odeslat ověřovací e-mail
											</span>
										)}
									</div>
								)}
							</Col>
						</Row>
					</section>

					{/* Personal data section */}
					<section>
						<div className={styles['form-group--full-size-row-wrap']}>
							<Title type="h2" className={styles.title} display="block">
								Osobní údaje
							</Title>
						</div>

						<div className={styles['form-group--full-size-row-wrap']}>
							<div className={styles.label}>Jméno</div>
							<Field label="">
								<InputText
									id="first_name"
									onChange={this.handleChange}
									value={settingsData.first_name}
								/>
							</Field>
						</div>

						<div className={styles['form-group--full-size-row-wrap']}>
							<div className={styles.label}>Příjmení</div>
							<Field label="">
								<InputText
									id="last_name"
									onChange={this.handleChange}
									value={settingsData.last_name}
								/>
							</Field>
						</div>

						<Row>
							<Col xs="6/12" className={styles.label}>
								Titul před jménem
							</Col>
							<Col xs="6/12" className={styles.label}>
								Titul za jménem
							</Col>
						</Row>

						<Row>
							<Col xs="6/12" className={styles['form-group']}>
								<Field label="">
									<InputText
										id="title1"
										onChange={this.handleChange}
										value={settingsData.title1}
									/>
								</Field>
							</Col>

							<Col xs="6/12" className={styles['form-group']}>
								<Field label="">
									<InputText
										id="title2"
										onChange={this.handleChange}
										value={settingsData.title2}
									/>
								</Field>
							</Col>
						</Row>

						<div className={styles['form-group--full-size-row-wrap']}>
							Telefon
						</div>

						<div className={styles['form-group--full-size-row-wrap']}>
							<Field label="">
								<Row className={styles['phone-inputs']}>
									<Col xs="3/12">
										<InputText value="+420" readOnly />
									</Col>
									<Col xs="9/12">
										<InputNumber
											id="phone"
											onChange={this.handleChange}
											value={settingsData.phone}
										/>
									</Col>
								</Row>
							</Field>
						</div>

						<div className={styles['form-group--full-size-row-wrap']}>
							<Field label="">
								<Row className={styles['phone-inputs']}>
									<Col xs="3/12">
										<InputNumber
											id="phone2_pre"
											onChange={this.handleChange}
											value={settingsData.phone2_pre}
										/>
									</Col>
									<Col xs="9/12">
										<InputNumber
											id="phone2_nr"
											onChange={this.handleChange}
											value={settingsData.phone2_nr}
										/>
									</Col>
								</Row>
							</Field>
						</div>
					</section>

					{/* Notifications section */}
					<section>
						<div className={styles['form-group--full-size-row-wrap']}>
							<Title type="h2" className={styles.title} display="block">
								Upozorňování (novinky, změny)
							</Title>
						</div>

						<div className={styles['form-group--full-size-row-wrap']}>
							<div className={styles.label}>Frekvence</div>
							<Select
								id="period"
								value={settingsData.period}
								options={Object.values(EMAIL_FREQUENCY)}
								onChange={this.handleChange}
							/>
						</div>

						<div className={styles['form-group--full-size-row-wrap']}>
							<div className={styles.label}>Push notifikace</div>
							<Select
								id="push"
								value={settingsData.push}
								options={Object.values(PUSH_NOTIFICATIONS)}
								onChange={this.handleChange}
							/>
							{settingsData.push === PUSH_NOTIFICATIONS.ENABLED.value && (
								<div className={styles.info}>
									Vyžaduje instalaci mobilní aplikace Reality.cz na Váš telefon.
								</div>
							)}
						</div>

						<div className={styles['form-group--full-size-row-wrap']}>
							<div className={styles.label}>Mazat neaktivní nabídky</div>
							<Select
								id="ads_delete_after"
								value={settingsData.ads_delete_after}
								options={Object.values(ADS_DELETE_AFTER)}
								onChange={this.handleChange}
							/>
						</div>
					</section>

					{/* Save and logout */}
					<section>
						<Row className={styles['button-row']}>
							<Col xs="6/12">
								<Button formType="submit" size="cover" type="positive">
									<i className="fa fa-check" />
									Uložit
								</Button>
							</Col>

							<Col xs="6/12">
								<Button
									formType="button"
									size="cover"
									type="negative"
									onClick={this.openLogoutConfirmCard}>
									<i className="fa fa-sign-out" />
									Odhlásit se
								</Button>
							</Col>
						</Row>
					</section>

					{/* Delete account */}
					<section>
						<div className={styles['form-group--full-size-row-wrap']}>
							<Title
								type="h2"
								className={styles.title + ' ' + styles.warning}
								display="block">
								Zrušení účtu
							</Title>
							<div className={styles.warning}>Zrušený účet nelze obnovit!</div>
						</div>

						<div
							className={`${styles['button-row']} ${
								styles['form-group--full-size-row-wrap']
							}`}>
							<Button
								formType="button"
								size="cover"
								type="negative"
								onClick={this.openDeleteAccountCard}>
								<i className="fa fa-trash-o" />
								Zrušit účet
							</Button>
						</div>
					</section>
				</Form>
				<OpenCard open={this.isCardOpened()}>
					<div>
						{this.props.opencard === OPEN_CARD_DELETE_ACCOUNT && (
							<DeleteAccount
								verifiedBySN={
									verified === VERIFICATION_STATUS.VERIFIED_BY_SN.value
								}
							/>
						)}
						{this.props.opencard === OPEN_CARD_CHANGE_PASSWORD && (
							<ChangePassword />
						)}
						{this.props.opencard === OPEN_CARD_LOGOUT_CONFIRM && (
							<LogoutConfirm />
						)}
					</div>
				</OpenCard>

				<Overlay shown={this.state.isLoading} withSpinner />
			</Page>
		)
	}
}

Settings.propTypes = {
	opencard: PropTypes.string,
	actions: PropTypes.any,
	error: PropTypes.string,
	profile: PropTypes.object,
}

export default Settings
