import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GoogleMap, Marker, Polyline } from 'react-google-maps'
import { default as ScriptjsLoader } from 'react-google-maps/lib/async/ScriptjsLoader'
import Spinner from '../Spinner'
import styles from './Map.module.scss'
import markerIcon from './marker5.png'

class Map extends Component {
	render() {
		const { markers, streets, zoom, center, showLocation } = this.props

		return (
			<div className={styles.base}>
				<ScriptjsLoader
					hostname={'maps.googleapis.com'}
					pathname={'/maps/api/js'}
					query={{
						v: 3,
						libraries: 'geometry,drawing,places',
						key: 'AIzaSyAz7kmPHuOwvpIvVGpKZf2l1oZo_FMlqvw',
					}}
					loadingElement={<Spinner />}
					containerElement={<div style={{ height: '100%' }} />}
					googleMapElement={
						<GoogleMap defaultZoom={zoom} defaultCenter={center}>
							{showLocation &&
								markers.map((v, k) => {
									return (
										<Marker
											position={v}
											defaultAnimation="2"
											key={k}
											icon={markerIcon}
										/>
									)
								})}

							{streets &&
								streets.map((v, k) => {
									return (
										<Polyline
											path={v}
											key={k}
											options={{
												strokeColor: '#db0000',
												strokeOpacity: 0.6,
												strokeWeight: 5,
											}}
										/>
									)
								})}
						</GoogleMap>
					}
				/>

				{!showLocation && (
					<div className={styles.undisclosedLocation}>
						Je nám líto, ale inzerent si{' '}
						<span>nepřeje zobrazit přesnou lokalitu</span> v mapě.
					</div>
				)}
			</div>
		)
	}
}

Map.defaultProps = {
	center: { lat: 59.938043, lng: 30.337157 },
	zoom: 16,
	greatPlaceCoords: { lat: 59.724465, lng: 30.080121 },
}

Map.propTypes = {
	markers: PropTypes.array,
	center: PropTypes.object,
	streets: PropTypes.array,
	zoom: PropTypes.any,
	showLocation: PropTypes.any,
}

export default Map
