import React from 'react'

import './App.scss'
import './style/index.scss'

import { Provider } from 'react-redux'
import { Router, browserHistory, Route } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import { configureStore } from './store'

import routes from './route'
import { onEnter as detailOnEnter } from './route/Detail/container/DetailContainer'
import { onEnter as listOnEnter } from './route/List/container/ListContainer'
import CoreLayout from './layout/CoreLayout'

import configs from './config/config'
import { SelectionTypes, OfferTypes } from './constants/SearchConfigConstants'

const initialState = {}
const store = configureStore(initialState, browserHistory)
const history = syncHistoryWithStore(browserHistory, store)

const isProduction = () => !configs[window.location.origin]

export const trackGoogleAnalytics = (params) => {
	if (isProduction()) {
		window.ga(...params)
	}
}

const trackAnalytics = () => {
	if (isProduction()) {
		if (window.pp_gemius_hit) {
			const isHomePage = window.location.pathname === '/'
			// Feature #25477 - Vložení měřícího kódu Netmonitoru
			window.pp_gemius_hit(
				isHomePage
					? '0ssw3aMAJ_b.mdywTV1y53ZH7DQN50Plb7MzK6npatz.Z7'
					: 'zNFKtfxm81AOzvHNjcuvveVFj1hFegN4ImoLgDzVUvr.H7'
			)
		}

		window.ga('set', 'page', window.location.pathname)
		window.ga('send', 'pageview')
	}
}

const App = () => {
	const allRoutes = [
		<Route path="/" key="default" component={routes.Home} />,
		<Route
			path="/moje-reality/verify/:verificationToken/"
			key="verification"
			component={routes.VerifyAccount}
		/>,
		<Route path="/lists" key="lists" component={routes.Lists} />,
		<Route path="/search" key="search" component={routes.Search} />,
		<Route path="/favs" key="favs" component={routes.Favs} />,
		<Route path="/settings" key="settings" component={routes.Settings} />,
		<Route
			path="/forgotten-password"
			key="forgotten-password"
			component={routes.ForgottenPassword}
		/>,
		<Route path="/auth" key="auth" component={routes.Auth} />,
	]
	for (const route of [...SelectionTypes, ...OfferTypes]) {
		allRoutes.push(
			<Route
				key={route}
				path={'/' + route + '(/:par2)(/:par3)(/:par4)(/:par5)(/:par6)(/:par7)'}
				onEnter={listOnEnter(store)}
				component={routes.List}
			/>
		)
	}
	allRoutes.push(
		<Route
			path="/:id"
			key="detail"
			onEnter={detailOnEnter(store)}
			component={routes.Detail}
		/>
	)
	allRoutes.push(
		<Route path="/404" key="not-found" component={routes.NotFound} />
	)
	return (
		<Provider store={store}>
			<Router history={history} onUpdate={trackAnalytics}>
				<Route component={CoreLayout}>{allRoutes}</Route>
			</Router>
		</Provider>
	)
}

Route.propTypes = {}

export default App
