import React from 'react'
import PropTypes from 'prop-types'
import styles from './OpenCardHeader.module.scss'
import Close from '../Close'
import Back from '../Back'
import Title from '../Title'
import Row from '../Row'
import Col from '../Col'

const OpenCardHeader = (props) => (
	<div className={styles.base}>
		<Row>
			<Col xs="2/12" className={styles.backWrapper} onClick={props.onBack}>
				{props.onBack && <Back onClick={props.onBack} />}
			</Col>
			<Col xs="8/12">
				{props.title && (
					<Title
						className={styles.title + ' ' + props.titleClassName}
						type="h1"
						display="inline-block">
						{props.title}
					</Title>
				)}
			</Col>
			<Col xs="2/12" className={styles.close}>
				{props.onClose && <Close onClick={props.onClose} />}
			</Col>
			{props.children}
		</Row>
	</div>
)

OpenCardHeader.defaultProps = {
	titleClassName: '',
}

OpenCardHeader.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func,
	title: PropTypes.string,
	onBack: PropTypes.func,
	titleClassName: PropTypes.string,
}

export default OpenCardHeader
