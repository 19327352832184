const DEV_CONFIG = {
	facebookAppId: '1274511299311467',
	googleApiId:
		'1064377620731-cis12fsia4vk8klocic8nc1tfuqknfa3.apps.googleusercontent.com',
	sentry: 'https://00f1b1ad60944f6784f1babdbf11c1c6@sentry.io/112948',
	recaptchaKey: '6Les0B4UAAAAABXto7uS9F7mqJwRZBNJYeWDHg2b',
}

const TEST_CONFIG = {
	facebookAppId: '161503760593851',
	googleApiId:
		'1064377620731-27tob82pcguo6fu9023huah5qskr5mki.apps.googleusercontent.com',
	sentry: 'https://00f1b1ad60944f6784f1babdbf11c1c6@sentry.io/112948',
	recaptchaKey: '6Les0B4UAAAAABXto7uS9F7mqJwRZBNJYeWDHg2b',
}

const PROD_CONFIG = {
	facebookAppId: '161503760593851',
	googleApiId:
		'1064377620731-cis12fsia4vk8klocic8nc1tfuqknfa3.apps.googleusercontent.com',
	sentry: 'https://00f1b1ad60944f6784f1babdbf11c1c6@sentry.io/112948',
	recaptchaKey: '6Les0B4UAAAAABXto7uS9F7mqJwRZBNJYeWDHg2b',
}

export default {
	production: PROD_CONFIG,
	'https://beta.m.reality.cz': TEST_CONFIG,
	'http://localhost:3000': DEV_CONFIG,
}
