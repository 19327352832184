import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Favs from '../component/Favs'
import {
	emptyFavsList,
	emptyFavsListLocally,
	loadFavs,
	loadUpdatedFavs,
} from '../../../reducer/UserDataReducer'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators(
		{
			emptyFavsList,
			emptyFavsListLocally,
			loadFavs,
			loadUpdatedFavs,
		},
		dispatch
	),
})

const mapStateToProps = (state) => ({
	favs: state.userData.get('favs'),
	favsSize: state.userData.get('favsSize'),
	updatedFavs: state.userData.get('updatedFavs'),
	updatedFavsSize: state.userData.get('updatedFavsSize'),
	display: state.display.toJS(),
	isAuthenticated: state.authApi.get('isAuthenticated'),
	location: state.routing.locationBeforeTransitions,
})

export default connect(
	mapStateToProps,
	mapActionCreators
)(Favs)
