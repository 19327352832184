import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Header from '../../component/Header'
import classes from './CoreLayout.module.scss'
import CookieBanner from '../../component/CookieBanner'
import Wrapper from '../../component/Wrapper'
import { trackGoogleAnalytics } from '../../App'

if (typeof window !== 'undefined') {
	window.addEventListener('orientationchange', function() {
		switch (window.orientation) {
			case -90:
			case 90:
				trackGoogleAnalytics([
					'send',
					'event',
					'global',
					'zmena orientace v landscape',
				])
				break
			default:
				trackGoogleAnalytics([
					'send',
					'event',
					'global',
					'zmena orientace v portrait',
				])
				break
		}
	})
}

let setDisplay = false
let resizeTimeoutDebounce = 0

class CoreLayout extends React.Component {
	render() {
		// first binding redux action on onResize event,
		if (!setDisplay && typeof window !== 'undefined') {
			setDisplay = true

			let resize = () => {
				let w = document.documentElement.clientWidth
				let h = document.documentElement.clientHeight
				this.props.actions.setDisplay({
					width: w,
					height: h,
				})
			}

			resize()
			window.addEventListener('resize', () => {
				clearTimeout(resizeTimeoutDebounce)
				resize()
			})
		}

		return (
			<div className="container text-center">
				<Helmet
					title="Reality.cz"
					meta={[
						{
							name: 'description',
							content: `Reality.cz - nabídka nemovitostí na webu i v mobilu.
          Byty, domy, pozemky, chaty, chalupy k pronájmu i prodeji. Realitní inzerce pro všechny.`,
						},
						{ name: 'author', content: 'Server Reality.CZ s.r.o' },
						{ name: 'robots', content: 'index,follow' },
						{
							name: 'keywords',
							content: `Reality, Realitní kanceláře, Česká republika, Nemovitosti,
          Byty, Prodej, Pronájem, Pozemky, Dražby, Domy, Zahrady, Pole, Lesy, Garáže`,
						},
					]}
				/>
				<Header />
				<Wrapper>
					<div className={classes.mainContainer}>{this.props.children}</div>
				</Wrapper>
				<CookieBanner />
			</div>
		)
	}
}

CoreLayout.propTypes = {
	children: PropTypes.element,
	actions: PropTypes.shape({
		saveToFavs: PropTypes.func,
		saveToFavsLocally: PropTypes.func,
		setDisplay: PropTypes.func,
	}),
}

export default CoreLayout
