import React from 'react'
import logoimg from './logo.jpg'
import styles from './logo.module.scss'

const Logo = (props) => (
	<img src={logoimg} {...props} className={styles.base} alt="" />
)

Logo.defaultProps = {}

export default Logo
