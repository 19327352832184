import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import CoreLayout from './CoreLayout'
import { setDisplay } from '../../reducer/DisplayReducer'
import { getNewOffersCount, getUpdatedFavsCount } from '../../reducer/UserDataReducer'

const mapActionCreators = (dispatch) => ({
  actions: bindActionCreators({
    setDisplay,
    getNewOffersCount,
    getUpdatedFavsCount
  }, dispatch)
})

const mapStateToProps = (state) => ({
  isAuthenticated: state.authApi.get('isAuthenticated')
})

export default connect(mapStateToProps, mapActionCreators)(CoreLayout)
