import LogoutConfirm from './LogoutConfirm'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logout } from '../../reducer/AuthApiReducer'
import {
	emptyFavsListLocally,
	emptySearchesListLocally,
} from '../../reducer/UserDataReducer'
import { closeOpenCard } from '../../reducer/OpenCardReducer'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators(
		{
			emptySearchesListLocally,
			emptyFavsListLocally,
			closeOpenCard,
			logout,
		},
		dispatch
	),
})

const mapStateToProps = (state) => ({
	opencard: state.openCard.get('openCard'),
	error: state.authApi.get('error'),
})

export default connect(
	mapStateToProps,
	mapActionCreators
)(LogoutConfirm)
