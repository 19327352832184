import CookieBanner from './CookieBanner'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { acceptCookies } from '../../reducer/UserDataReducer'

const mapActionCreators = (dispatch) => ({
  actions: bindActionCreators({ acceptCookies }, dispatch)
})

const mapStateToProps = (state) => ({
  cookie: state.userData.get('cookie')
})

export default connect(mapStateToProps, mapActionCreators)(CookieBanner)

