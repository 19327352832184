import React from 'react'
import PropTypes from 'prop-types'
import styles from './Page404.module.scss'
import home from './home.png'
import magnifier from './magnifier.png'
import Page from '../Page'
import { Link } from 'react-router'
import Title from '../Title'
import BottomLinks from '../BottomLinks'
import { WWW_URL } from '../../constants/AppConstants'

const TEXT = 'Požadovanou stránku nemůžeme najít, ale můžete zkusit'

function Page404(props) {
	const { children, noRedirect, redirectTo, timeout } = props

	if (!noRedirect) {
		setTimeout(() => {
			const patternNestedDetail = /(.+)?\/(\w{3}-\w{6})(\/(.+))?/gi
			if (
				typeof window !== 'undefined' &&
				!patternNestedDetail.test(window.location.pathname)
			) {
				window.location = redirectTo || WWW_URL + window.location.pathname
			}
		}, timeout)
	}

	return (
		<Page className={styles.base}>
			<Link to="/">
				<img src={home} alt="home" className={styles.home} />
			</Link>

			<Title type="p2" display="block" className={styles.para}>
				{props.title || TEXT}
			</Title>

			<Link to="/search/" className={styles.newsearch}>
				<img src={magnifier} alt="magnifier" className={styles.magn} />
				<Title type="p2">nové hledání</Title>
			</Link>

			<Title display="block" type="p2" className={styles.callus}>
				Nebo nám zavolejte na
			</Title>

			<a href="tel:731118470" className={styles.tel}>
				<Title type="p2">731 118 470</Title>
			</a>

			{children}

			<BottomLinks />
		</Page>
	)
}

Page404.defaultProps = {
	title: TEXT,
	noRedirect: false,
	redirectTo: null,
	timeout: 3000,
}

Page404.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
	noRedirect: PropTypes.bool,
	redirectTo: PropTypes.string,
	timeout: PropTypes.number,
}

export default Page404
