import React from 'react'
import PropTypes from 'prop-types'
import styles from './Search.module.scss'
import Page from '../../../component/Page'
import PageTitle from '../../../component/PageTitle'
import SearchForm from '../../../component/SearchForm'
import OpenCard from '../../../component/OpenCard'
import LocationAdder from '../../../component/LocationAdder'
import { OPEN_CARD_LOCATION } from '../../../constants/OpenCardConstants'
import { sendAxiosErrorValue } from '../../../utils/fetcher'

class Search extends React.Component {
	render() {
		return (
			<Page className={styles.base}>
				<PageTitle>Podrobné hledání</PageTitle>
				<SearchForm
					setRef={(self) => {
						this.searchForm = self
					}}
				/>

				<OpenCard open={this.props.opencard === OPEN_CARD_LOCATION}>
					<div>
						{this.props.opencard === OPEN_CARD_LOCATION && (
							<LocationAdder
								success={() => {
									if (this.searchForm && this.searchForm.onIChange) {
										this.searchForm.onIChange()
									} else {
										sendAxiosErrorValue({
											context: 'searchForm.onIChange-Search',
											type: typeof this.searchForm,
											data: this.searchForm,
										})
									}
								}}
							/>
						)}
					</div>
				</OpenCard>
			</Page>
		)
	}
}

Search.propTypes = {
	opencard: PropTypes.string,
}

export default Search
