import React from 'react'
import PropTypes from 'prop-types'
import styles from './ListTitle.module.scss'
import PageTitle from '../PageTitle'

function ListTitle(props) {
	const getSizeClass = (text) => {
		let length = text.length
		if (length < 20) {
			return styles.size1
		} else if (length < 30) {
			return styles.size2
		} else if (length < 40) {
			return styles.size3
		}

		return styles.size4
	}

	return (
		<PageTitle
			className={`${props.description ? getSizeClass(props.description) : ''} ${
				!props.description ? styles.hidden : ''
			}`}>
			{props.description &&
				props.description
					.split(' ')
					.map((char) => (!isNaN(char) ? `___${char}` : char))
					.join(' ')
					.replace(/ ___/g, '\u00a0')}
			<br />
			<span>
				{props.additional.map((additional, i) => {
					return (
						additional &&
						additional.map((value, j) => {
							return <span key={i + '_' + j}>{value.v} </span>
						})
					)
				})}
			</span>

			<span>
				{' '}
				{props.count && `(${props.count <= 1000 ? props.count : '1000+'})`}
			</span>
		</PageTitle>
	)
}

ListTitle.defaultProps = {}

ListTitle.propTypes = {
	description: PropTypes.string,
	additional: PropTypes.array,
	count: PropTypes.number,
}

export default ListTitle
