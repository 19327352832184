import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import styles from './Lists.module.scss'
import Page from '../../../component/Page'
import PageTitle from '../../../component/PageTitle'
import Paragraph from '../../../component/Paragraph'
import Title from '../../../component/Title'
import Overlay from '../../../component/Overlay'
import OpenCard from '../../../component/OpenCard'
import ListCard from '../../../component/ListCard'
import getListCardHeight from '../../../utils/getListCardHeight'
import NewOffersConfirm from '../../../component/NewOffersConfirm'
import InfinityList from '../../../component/InfinityList'
import { OPEN_CARD_NEW_OFFERS_CONFIRM } from '../../../constants/OpenCardConstants'
import { trackGoogleAnalytics } from '../../../App'
import { getUrl } from '../../../utils/getApiUrl'
import { MARK_NEW_AS_READ_URL } from '../../../constants/UserDataConstants'
import { sendAxiosGetRequest } from '../../../utils/fetcher'

class Lists extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isLoading: false,
			showingNew: Boolean(
				props.location.state && props.location.state.notifications
			),
			newOffersSize:
				(props.location.state && props.location.state.newOffersSize) || 0,
		}
	}

	componentDidMount() {
		if (!this.state.showingNew) {
			this.context.router.replace({
				pathname: '/lists',
				state: { notifications: false },
			})
		}

		if (
			this.props.newOffersSize > 0 &&
			this.props.isAuthenticated &&
			!this.state.showingNew
		) {
			this.confirmNewOffers()
		}

		if (this.props.isAuthenticated && !this.state.showingNew) {
			this.loadSavedSearches()
		}
	}

	componentWillReceiveProps(nextProps) {
		/**
		 * Routing reducer is used to store state of the current route.
		 * It is done so user can return to the new offers page even if
		 * all items have already been marked as read.
		 */
		if (nextProps.location.state) {
			this.setState({
				showingNew: Boolean(nextProps.location.state.notifications),
				newOffersSize: nextProps.location.state.newOffersSize || 0,
			})
		}
	}

	loadSavedSearches = () => {
		this.setState({ isLoading: true })
		this.props.actions.loadSearches().then(() => {
			this.setState({ isLoading: false })
		})
	}

	loadNewOffers = (config) => {
		this.setState({ isLoading: true })
		this.props.actions.loadNewOffers(config).then(() => {
			this.setState({ isLoading: false })
			this.markNewAsRead()
		})
	}

	markNewAsRead = () => {
		const endpoint = getUrl(MARK_NEW_AS_READ_URL)
		sendAxiosGetRequest(endpoint)
	}

	showNewOffers = () => {
		/**
		 * Before showing new offers to the user, we need to create new history item
		 * and push it so we can use back button to return to Favs page. We also have to
		 * save number of updated favs because after the UpdatedFavs page is loaded the request
		 * will be sent to mark all favs as read and we will have no way to get updated favs count.
		 */
		this.context.router.push({
			pathname: '/lists',
			state: {
				notifications: true,
				newOffersSize: this.props.newOffersSize,
			},
		})

		this.loadNewOffers({ push: false })
	}

	showSavedSearches = () => {
		/**
		 * Since we had added new history item, we can now simply use goBack.
		 */
		this.context.router.goBack()
	}

	/**
	 * Remove all saved searches.
	 */
	emptySearchesList = () => {
		if (this.props.isAuthenticated) {
			this.props.actions.emptySearchesList()
		}

		this.props.actions.emptySearchesListLocally()
	}

	removeSavedSearch = (item, index) => {
		if (this.props.isAuthenticated) {
			this.props.actions.removeSearch(item, index)
		} else {
			this.props.actions.removeSearchLocally(item)
		}
	}

	isCardOpened = () => {
		return this.props.opencard === OPEN_CARD_NEW_OFFERS_CONFIRM
	}

	confirmNewOffers = () => {
		this.props.actions.setOpenCard(OPEN_CARD_NEW_OFFERS_CONFIRM)
	}

	render() {
		const size = this.state.showingNew
			? this.state.newOffersSize
			: this.props.listSize

		let elements = []
		if (this.state.showingNew) {
			elements =
				this.props.newOffers &&
				this.props.newOffers
					.map((value) => (
						<div
							style={{ height: getListCardHeight(this.props.display) + 100 }}
							key={value.id}>
							<ListCard
								data={value}
								beforeRedirect={() => {
									trackGoogleAnalytics([
										'send',
										'event',
										'Oblibene',
										'proklik na detail',
									])
								}}
							/>
						</div>
					))
					.toJS()
		}

		return (
			<Page className={styles.base}>
				{!this.state.showingNew && (
					<div>
						<div className={styles['page-header']}>
							<PageTitle>Uložená hledání ({size})</PageTitle>

							{this.props.listSize > 0 && (
								<div
									onClick={this.emptySearchesList}
									className={styles.removeAll}>
									<Title type="p5">odstranit všechny položky</Title>
								</div>
							)}

							{this.props.listSize === 0 && (
								<div className={styles.noItems}>
									nemáte uložená žádná hledání
								</div>
							)}

							{this.props.newOffersSize > 0 && (
								<div
									onClick={this.confirmNewOffers}
									className={
										styles.removeAll + ' ' + styles['new-offers-link']
									}>
									<Title type="p5">
										zobrazit nové nabídky ({this.props.newOffersSize})
									</Title>
								</div>
							)}
						</div>

						{this.props.lists &&
							this.props.lists.map((value, index) => {
								const fixedUrl = value.url.replace(/profil-\d+\//, '')
								return (
									<div className={styles.saved} key={value.url}>
										<Link
											to={fixedUrl}
											onClick={() => {
												trackGoogleAnalytics([
													'send',
													'event',
													'UlozeneHledani',
													'otevreni odkazu',
												])
											}}>
											<Title type="p2" display="block" className={styles.title}>
												{value.desc}
											</Title>
											<Paragraph className={styles.details}>
												{value.config.get('kind') && (
													<span>{value.config.get('kind')}</span>
												)}
												{value.config.get('disposition') &&
													value.config
														.get('disposition')
														.map((a, k) => <span key={k}>{a.v}</span>)}
												{value.config.get('dispositionHouse') &&
													value.config
														.get('dispositionHouse')
														.map((a, k) => <span key={k}>{a.v}</span>)}
												{value.config.get('buildingtype') &&
													value.config
														.get('buildingtype')
														.map((a, k) => <span key={k}>{a.v}</span>)}
												{value.config.get('others') &&
													value.config
														.get('others')
														.map((a, k) => <span key={k}>{a.v}</span>)}
												{value.config.get('othersHouse') &&
													value.config
														.get('othersHouse')
														.map((a, k) => <span key={k}>{a.v}</span>)}
												{value.config.get('kindofplot') &&
													value.config
														.get('kindofplot')
														.map((a, k) => <span key={k}>{a.v}</span>)}
												{value.config.get('address') &&
													value.config
														.get('address')
														.map((a, k) => <span key={k}>{a.fullname}</span>)}
											</Paragraph>
										</Link>

										<span
											className={styles.removeOne}
											onClick={() => this.removeSavedSearch(value, index)}
										/>
									</div>
								)
							})}
					</div>
				)}

				{this.state.showingNew && elements.length > 0 && (
					<InfinityList
						isLoading={this.state.isLoading}
						rowsCount={size}
						list={elements}
						loadData={(config) => this.loadNewOffers({ ...config, push: true })}
						setInstance={(inst) => {
							this.infinityList = inst
						}}>
						<div className={styles['page-header']}>
							<PageTitle>Nové nabídky ({size})</PageTitle>

							<div
								onClick={this.showSavedSearches}
								className={styles.removeAll}>
								<Title type="p5">zobrazit uložená hledání</Title>
							</div>
						</div>
					</InfinityList>
				)}

				<OpenCard open={this.isCardOpened()}>
					<div>
						{this.props.opencard === OPEN_CARD_NEW_OFFERS_CONFIRM && (
							<NewOffersConfirm callback={this.showNewOffers} />
						)}
					</div>
				</OpenCard>

				<Overlay shown={this.state.isLoading} withSpinner />
			</Page>
		)
	}
}

Lists.propTypes = {
	lists: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	opencard: PropTypes.string,
	newOffersSize: PropTypes.number,
	listSize: PropTypes.number,
	isAuthenticated: PropTypes.bool,
	actions: PropTypes.object,
	newOffers: PropTypes.object,
	display: PropTypes.object,
	location: PropTypes.object,
}

Lists.contextTypes = {
	router: PropTypes.object,
}

export default Lists
