import React from 'react'
import PropTypes from 'prop-types'
import styles from './Footer.module.scss'
import Title from '../Title'
import Page from '../Page'
import Row from '../Row'
import Col from '../Col'
import {
	APPLE_APP_LINK,
	GOOGLE_APP_LINK,
	FACEBOOK_PAGE_LINK,
	TERMS_PAGE_LINK,
	CONTACTS_PAGE_LINK,
} from '../../constants/AppConstants'
import { trackGoogleAnalytics } from '../../App'

const Footer = () => {
	const currentYear = new Date().getFullYear()

	return (
		<div className={styles.base}>
			<div className={styles.apps}>
				<Page>
					<Title type="h4" display="block">
						Zkusili jste naši mobilní aplikaci?
					</Title>
					<span /* eslint-disable */>
						<a
							href={APPLE_APP_LINK}
							className={styles.appstore}
							target="_blank"
							aria-label="apple"
							rel="noopener noreferrer"
							onClick={() => {
								trackGoogleAnalytics([
									'send',
									'event',
									'footer',
									'stahnout ios footer',
								])
							}}
						/>
						<a
							href={GOOGLE_APP_LINK}
							className={styles.googleplay}
							target="_blank"
							aria-label="google"
							rel="noopener noreferrer"
							onClick={() => {
								trackGoogleAnalytics([
									'send',
									'event',
									'footer',
									'stahnout android footer',
								])
							}}
						/>
					</span>
				</Page>
			</div>

			<div className={styles.watchUS}>
				<Page>
					<Title type="p2" display="block">
						Sledujte nás na <a href={FACEBOOK_PAGE_LINK}>Facebooku</a>
						<span className={styles.facebook} />
					</Title>
				</Page>
			</div>

			<div className={styles.last}>
				<Page>
					<Row>
						<Col xs="1/12" />
						<Col xs="7/12">
							<a href={TERMS_PAGE_LINK} className={styles.last__left}>
								<Title type="p2">Smluvní podmínky</Title>
							</a>
						</Col>
						<Col xs="3/12">
							<a href={CONTACTS_PAGE_LINK} className={styles.last__right}>
								<Title type="p2">Kontakt</Title>
							</a>
						</Col>
					</Row>
					<Title type="p2" className={styles.copyright}>
						Copyright 1996&ndash;{currentYear} Reality.cz
					</Title>
				</Page>
			</div>
		</div>
	)
}

Footer.defaultProps = {}

Footer.propTypes = {
	children: PropTypes.node,
}

export default Footer
