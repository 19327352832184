import React from 'react'
import PropTypes from 'prop-types'
import styles from './Close.module.scss'

const Close = (props) => (
	<div
		className={props.className + ' ' + styles.base}
		onClick={props.onClick}
	/>
)

Close.defaultProps = {
	className: '',
}

Close.propTypes = {
	onClick: PropTypes.func.isRequired,
	className: PropTypes.string,
}

export default Close
