import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './ChangePassword.module.scss'
import Page from '../../component/Page'
import Button from '../../component/Button'
import OpenCardHeader from '../../component/OpenCardHeader'
import Form from '../../component/Form'
import Title from '../../component/Title'
import Field from '../../component/Field'
import Row from '../../component/Row'
import Col from '../../component/Col'
import InputPassword from '../../component/InputPassword'
import Overlay from '../../component/Overlay'
import { SETTINGS_CHANGE_PASSWORD_SUCCESS_ACTION } from '../../constants/SettingsConstants'

class ChangePassword extends Component {
	constructor(props) {
		super(props)

		this.state = {
			data: {
				oldPassword: '',
				newPassword: '',
				confirmPassword: '',
			},
			wasSent: false,
			passwordChanged: false,
			isLoading: false,
		}
	}

	onClose = () => {
		this.props.actions.closeOpenCard()
	}

	changePassword = (e) => {
		e.preventDefault()

		let oldP = this.state.data.oldPassword
		let newP = this.state.data.newPassword
		let confirm = this.state.data.confirmPassword

		if (!oldP || !newP || confirm !== newP) {
			return
		}

		this.setState({ isLoading: true })
		this.props.actions
			.changePassword({ oldPassword: oldP, newPassword: newP })
			.then((actionType) => {
				this.setState({ wasSent: true, isLoading: false })

				if (actionType === SETTINGS_CHANGE_PASSWORD_SUCCESS_ACTION) {
					this.setState({ passwordChanged: true })
				}
			})
	}

	handleChange = (e) => {
		e.persist()

		this.setState((prevState) => ({
			data: {
				...prevState.data,
				[e.target.id]: e.target.value,
			},
		}))
	}

	render() {
		let confirm = this.state.data.confirmPassword
		let newP = this.state.data.newPassword

		return (
			<div className={styles.base}>
				<div className={styles.section}>
					<Page>
						<OpenCardHeader onBack={this.onClose} title="Změna hesla" />

						{!this.state.passwordChanged && (
							<Form onSubmit={this.changePassword}>
								<Row className={styles['form-group']}>
									<Col xs="12/12">
										<Title type="p6" className={styles.title} display="block">
											Aktuální heslo
										</Title>
										<Field label="">
											<InputPassword
												id="oldPassword"
												onChange={this.handleChange}
												required
											/>
										</Field>
									</Col>
								</Row>

								<Row className={styles['form-group']}>
									<Col xs="12/12">
										<Title type="p6" className={styles.title} display="block">
											Nové heslo
										</Title>
										<Field label="">
											<InputPassword
												id="newPassword"
												onChange={this.handleChange}
												required
											/>
										</Field>
									</Col>
								</Row>

								<Row className={styles['form-group']}>
									<Col xs="12/12">
										<Title type="p6" className={styles.title} display="block">
											Opakujte nové heslo
										</Title>
										<Field label="">
											<InputPassword
												id="confirmPassword"
												onChange={this.handleChange}
												required
											/>
											{confirm !== newP && (
												<div className={styles.error}>
													Hodnoty musí být stejné.
												</div>
											)}
										</Field>
									</Col>
								</Row>

								{this.state.wasSent && (
									<div className={styles['error']}>{this.props.error}</div>
								)}

								<Row className={styles['button-row']}>
									<Col xs="12/12">
										<Button formType="submit" type="info" size="cover">
											Změnit heslo
										</Button>
									</Col>
								</Row>
							</Form>
						)}

						{this.state.passwordChanged && (
							<div>
								<Row>
									<Col xs="12/12">
										<div className={styles['password-changed']}>
											Heslo bylo úspěšně změněno.
											<div>
												<i className="fa fa-check" />
											</div>
											<Button
												formType="button"
												type="positive"
												onClick={this.onClose}
												size="cover">
												Zpět
											</Button>
										</div>
									</Col>
								</Row>
							</div>
						)}

						<Overlay shown={this.state.isLoading} withSpinner />
					</Page>
				</div>
			</div>
		)
	}
}

ChangePassword.propTypes = {
	actions: PropTypes.object,
	error: PropTypes.string,
}

export default ChangePassword
