import React from 'react'
import PropTypes from 'prop-types'
import Page from '../Page'
import ContactItems from '../ContactItems'

import styles from './Contacts.module.scss'

function Contacts(props) {
	const { data } = props

	return (
		<div className={styles.base}>
			<Page>
				<div className={styles.contacts}>
					{data.broker && <ContactItems data={data.broker} section="Makléř" />}

					{data.real_estate && (
						<ContactItems data={data.real_estate} section="Realitní kancelář" />
					)}

					{data.advertiser && (
						<ContactItems
							data={Object.assign({}, data.advertiser, {
								name: data.advertiser.has_name ? data.advertiser.name : '',
							})}
							section="Soukromý inzerent"
						/>
					)}
				</div>
			</Page>
		</div>
	)
}

Contacts.defaultProps = {}

Contacts.propTypes = {
	data: PropTypes.object,
}

export default Contacts
