/**
 * Create data for application/x-www-form-urlencoded.
 *
 * @param data
 */
export default function createFormUrlEncoded (data) {
  let array = []
  for (let p in data) {
    if (data.hasOwnProperty(p)) {
      array.push(`${encodeURIComponent(p)}=${encodeURIComponent(data[p])}`)
    }
  }
  return array.join('&')
}
