import React from 'react'
import PropTypes from 'prop-types'
import styles from './Input.module.scss'

const Input = (props) => <input {...props} className={styles.Input} />

Input.propTypes = {
	type: PropTypes.oneOf(['text', 'number', 'date', 'password', 'email']),
	name: PropTypes.string,
	size: PropTypes.oneOf(['default', 'cover', 'large', 'small']),
}

export default Input
