import React from 'react'
import PropTypes from 'prop-types'
import styles from './Overlay.module.scss'
import Spinner from '../Spinner'

const Overlay = (props) => (
	<div>
		{props.shown && (
			<div className={styles.overlay}>
				{props.withSpinner && <Spinner size="default" />}
			</div>
		)}
	</div>
)

Overlay.propTypes = {
	withSpinner: PropTypes.bool,
	shown: PropTypes.bool,
}

export default Overlay
