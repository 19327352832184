import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './SelectHint.module.scss'
import Select from '../Select'
import RealityUrlBuilder from '../../utils/realityUrlBuilder'

class SelectHint extends Component {
	redirect = (event) => {
		let v = event.target.value

		// this attributes we don't want
		let newValue = {
			topped: '',
		}

		newValue[this.props.attr] = this.props.multiple ? [{ url: v }] : v

		// set new values to state
		switch (this.props.attr) {
			case 'kind':
				this.props.actions.setKind(v)
				break

			case 'disposition':
				this.props.actions.setDisposition({ url: v - 0 })
				break

			case 'dispositionHouse':
				this.props.actions.setDispositionHouse({ url: v - 0 })
				break

			case 'apartmentArea':
				this.props.actions.setApartmentArea({ url: v - 0 })
				break

			case 'floor':
				this.props.actions.setFloor({ url: v - 0 })
				break
			default:
				break
		}

		// this timeout resolves issue #4, problem with selectbox on IOS 10.0.2
		setTimeout(() => {
			this.props.actions.push(
				RealityUrlBuilder(Object.assign({}, this.props.config, newValue))
			)
		}, 100)
	}

	render() {
		return (
			<div className={styles.base}>
				<Select
					options={[{ label: 'Vyberte hodnotu', value: '' }].concat(
						this.props.options
					)}
					onChange={this.redirect}
				/>
			</div>
		)
	}
}

SelectHint.defaultProps = {}

SelectHint.propTypes = {
	attr: PropTypes.string.isRequired,
	multiple: PropTypes.bool.isRequired,
	config: PropTypes.object.isRequired,
	actions: PropTypes.object,
	options: PropTypes.any,
}

export default SelectHint
