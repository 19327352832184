import InfinityList from './InfinityList'

import { connect } from 'react-redux'
import { sendAxiosErrorValue } from '../../utils/fetcher'

const mapStateToProps = (state) => {
	if (state.display && state.display.toJS) {
		return {
			scrollTop: state.appData.get('scrollTop'),
			display: state.display.toJS(),
		}
	} else {
		sendAxiosErrorValue({
			context: 'state.display-Infinity',
			type: typeof state.display,
			data: state.display,
		})
	}
}

export default connect(
	mapStateToProps,
	null
)(InfinityList)
