import React from 'react'
import Input from '../Input'

const InputNumber = (props) => (
  <Input type='number' {...props} />
)

InputNumber.propTypes = {}

export default InputNumber
