import { sendAxiosGetRequest } from '../utils/fetcher'
import * as Immutable from 'immutable'
import { createAction, handleActions } from 'redux-actions'
import { concat, uniqBy } from 'lodash'
import { getUrl } from '../utils/getApiUrl'
import { API_URL } from '../constants/AppConstants'

import realityUrlBuilder from '../utils/realityUrlBuilder'

const initialState = Immutable.fromJS({
	isLoading: false,
	lastFetched: null,
	error: null,
	data: {
		topped: [],
		advertisements: [],
		count: 0,
	},
})

const loadStart = createAction('LIST_API_LOAD_START')
const loadSuccess = createAction('LIST_API_LOAD_SUCCESS')
const loadFailure = createAction('LIST_API_LOAD_FAILED')
export const emptyList = createAction('EMPTY_LIST')
export const clearCounter = createAction('CLEAR_COUNTER')

/**
 * @param params
 * @param count <Boolean>: if TRUE result is only a count of returned items
 * @param push <Boolean>: if TRUE result items are pushed to list of items
 */
export const load = (params, count = false, push = false) => {
	return async (dispatch) => {
		const endpoint = getUrl(
			API_URL +
				realityUrlBuilder(
					!count ? params : Object.assign({}, params, { count: 1 })
				)
		)

		dispatch(loadStart())

		try {
			const response = await sendAxiosGetRequest(endpoint)
			dispatch(loadSuccess({ ...response.data, push }))
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

export default handleActions(
	{
		LIST_API_LOAD_START: (state) => {
			return state.merge({
				isLoading: true,
			})
		},

		LIST_API_LOAD_SUCCESS: (state, action) => {
			const topped = action.payload.topped
				? concat(state.get('data').topped, action.payload.topped)
				: state.get('data').topped

			const advertisements = action.payload.advertisements
				? concat(
						state.get('data').advertisements,
						action.payload.advertisements
				  )
				: state.get('data').advertisements

			return state.merge({
				isLoading: false,
				data: Object.assign({}, action.payload, {
					topped: uniqBy(topped, 'id'),
					advertisements: uniqBy(advertisements, 'id'),
				}),
				lastFetched: new Date().getTime(),
				error: null,
			})
		},

		LIST_API_LOAD_FAILED: (state, action) => {
			return state.merge({
				isLoading: false,
				error: action.payload,
			})
		},

		/**
		 * every empting list set state isLoading === true to prepare state before call action load data
		 */
		EMPTY_LIST: (state) => {
			return state.merge({
				isLoading: true,
				data: {
					topped: [],
					advertisements: [],
					count: 0,
				},
			})
		},

		CLEAR_COUNTER: (state) => {
			return state.merge({
				data: {
					count: 0,
				},
			})
		},
	},
	initialState
)
