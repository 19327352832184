import SelectHint from './SelectHint'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
	setKind,
	setDisposition,
	setApartmentArea,
	updateConfigAndRedirect,
	setDispositionHouse,
	setFloor,
	setFulltext,
	setOthers,
} from '../../reducer/SearchConfigReducer'
import { push } from 'react-router-redux'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators(
		{
			setKind,
			setDisposition,
			setApartmentArea,
			setDispositionHouse,
			updateConfigAndRedirect,
			setFloor,
			setFulltext,
			setOthers,
			push,
		},
		dispatch
	),
})

const mapStateToProps = (state) => ({
	searchConfig: state.searchConfig.toJS(),
})

export default connect(
	mapStateToProps,
	mapActionCreators
)(SelectHint)
