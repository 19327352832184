import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Settings from '../component/Settings'
import { setOpenCard } from '../../../reducer/OpenCardReducer'
import { logout } from '../../../reducer/AuthApiReducer'
import {
	loadSettings,
	saveSettings,
	sendVerificationEmail,
} from '../../../reducer/SettingsReducer'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators(
		{
			loadSettings,
			saveSettings,
			sendVerificationEmail,
			logout,
			setOpenCard,
		},
		dispatch
	),
})

const mapStateToProps = (state) => {
	return {
		profile: state.settings.get('profile'),
		error: state.settings.get('error'),
		opencard: state.openCard.get('openCard'),
	}
}

export default connect(
	mapStateToProps,
	mapActionCreators
)(Settings)
