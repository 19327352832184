import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import VerifyAccount from '../component/VerifyAccount'
import {
  verifyAccount
} from '../../../reducer/AuthApiReducer'

const mapActionCreators = (dispatch) => ({
  actions: bindActionCreators({
    verifyAccount
  }, dispatch)
})

const mapStateToProps = (state) => {
  return {
    isBeingVerified: state.authApi.get('isBeingVerified'),
    verificationError: state.authApi.get('verificationError')
  }
}

export default connect(mapStateToProps, mapActionCreators)(VerifyAccount)
