import React from 'react'
import PropTypes from 'prop-types'
import styles from './ContactItems.module.scss'
import ContactItem from '../ContactItem'
import Paragraph from '../Paragraph'
import Title from '../Title'
import { WWW_URL } from '../../constants/AppConstants'

function ContactItems(props) {
	let { phones, email, title, name, address, photo, logo, company } = props.data
	let { section } = props

	return (
		<div>
			<Title type="h2" display="block" className={styles.title}>
				{section}
			</Title>

			{photo && <img alt="" src={WWW_URL + photo} className={styles.logo} />}

			{logo && <img alt="" src={WWW_URL + logo} className={styles.logo} />}

			{title && (
				<Title type="h3" display="block" className={styles.name}>
					{title}
				</Title>
			)}

			{company && (
				<Title type="h3" display="block" className={styles.name}>
					{company}
				</Title>
			)}

			{name && (
				<Title type="h3" display="block" className={styles.name}>
					{name}
				</Title>
			)}

			{address && (
				<Paragraph className={styles.para}>
					{address
						.split(' ')
						.map((v) => (!isNaN(v) ? `___${v}` : v))
						.join(' ')
						.replace(/ ___/g, '\u00a0')}
				</Paragraph>
			)}

			<div className={styles.items}>
				{phones &&
					phones.map((v, k) => (
						<ContactItem
							key={'phone_' + k}
							type="phone"
							iteration={k + 1}
							className={
								phones.length % 2 === 1 && k === phones.length - 1
									? styles.cover
									: ''
							}>
							{v}
						</ContactItem>
					))}

				{email && <ContactItem type="email">{email}</ContactItem>}
			</div>
		</div>
	)
}

ContactItems.defaultProps = {}

ContactItems.propTypes = {
	data: PropTypes.object,
	section: PropTypes.any,
}

export default ContactItems
