import React from 'react'
import PropTypes from 'prop-types'
import styles from './PageTitle.module.scss'

const PageTitle = (props) => (
	<h1 className={styles.base + ' ' + props.className}>{props.children}</h1>
)

PageTitle.defaultProps = {
	className: '',
}

PageTitle.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
}

export default PageTitle
