import ListSubTitle from './ListSubTitle'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
	removeSearch,
	removeSearchLocally,
} from '../../reducer/UserDataReducer'
import { setModal, setModalInput } from '../../reducer/AppDataReducer'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators(
		{
			setModal,
			setModalInput,
			removeSearch,
			removeSearchLocally,
		},
		dispatch
	),
})

const mapStateToProps = (state) => ({
	lists: state.userData.get('list'),
	modal: state.appData.get('modal'),
	isAuthenticated: state.authApi.get('isAuthenticated'),
})

export default connect(
	mapStateToProps,
	mapActionCreators
)(ListSubTitle)
