import * as Immutable from 'immutable'
import { createAction, handleActions } from 'redux-actions'
import { sendAxiosErrorValue } from '../utils/fetcher'

// -----------------------------------------------------------------------------
// Initial state
// -----------------------------------------------------------------------------

const initialState = Immutable.fromJS({
	width: 640,
	height: 480,
})

// -----------------------------------------------------------------------------
// Actions
// -----------------------------------------------------------------------------

export const setDisplay = createAction('SET_DISPLAY')

// -----------------------------------------------------------------------------
// Action handlers
// -----------------------------------------------------------------------------

export default handleActions(
	{
		// SET_DISPLAY: (state, action) => state.merge(action.payload),
		SET_DISPLAY: (state, action) => {
			if (!state || !state.merge) {
				sendAxiosErrorValue({
					context: 'state.merge-DisplayReducer',
					type: typeof state,
					data: state,
				})
				return state
			}
			return state.merge(action.payload)
		},
	},
	initialState
)
