import createMigration from 'redux-persist-migrate'

// VERSION_REDUCER_KEY is the key of the reducer that will be used to store the state
// version in.
export const VERSION_REDUCER_KEY = 'app'

export function migration(initialState) {
	// This is a list of changes to make to the state being rehydrated.
	// The keys must be integers, and migrations will be performed in ascending key order.
	// Note: blacklisted reducers will not be present in this state.
	const manifest = {
		// 1 is the first version where migration were enabled so the whole
		// persisted store is ignored and the initialState is used
		1: () => initialState,
		// Everytime when the store structure is somehow changed, it is needed to add a new mutation here,
		// so that all users can be migrated to the actual version.
		// Example:
		// 2: state => (state.userData || initialState.userData).merge(Immutable.fromJS({ newAttribute: 'new' })
	}

	return createMigration(manifest, VERSION_REDUCER_KEY)
}
