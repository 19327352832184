import ChangePassword from './ChangePassword'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changePassword } from '../../reducer/SettingsReducer'
import { closeOpenCard } from '../../reducer/OpenCardReducer'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators(
		{
			closeOpenCard,
			changePassword,
		},
		dispatch
	),
})

const mapStateToProps = (state) => ({
	opencard: state.openCard.get('openCard'),
	error: state.settings.get('error'),
})

export default connect(
	mapStateToProps,
	mapActionCreators
)(ChangePassword)
