import React from 'react'
import PropTypes from 'prop-types'
import styles from './Back.module.scss'

const Back = (props) => (
	<div className={styles.base} onClick={props.onClick}>
		{props.children}
	</div>
)

Back.defaultProps = {}

Back.propTypes = {
	children: PropTypes.node,
	onClick: PropTypes.func,
}

export default Back
