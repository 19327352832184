import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { load, emptyList } from '../../../reducer/ListApiReducer'
import {
	saveSearch,
	saveSearchLocally,
	removeSearchLocally,
} from '../../../reducer/UserDataReducer'
import {
	setOrder,
	setMultipleAddress,
	setAll,
	setReferrer,
} from '../../../reducer/SearchConfigReducer'
import {
	setModal,
	setModalInput,
	setModalState,
	setScrolltop,
} from '../../../reducer/AppDataReducer'
import List from '../component/List'
import { push } from 'react-router-redux'
import parseUrl from '../../../utils/urlParser'
import get from 'lodash/get'

const onDone = (dispatch, getState) => {
	const locations = getState().listApi.getIn(['data', 'locations']) || []
	const addresses = locations.map((address) => ({
		url: address.url,
		fullname: address.name,
	}))

	dispatch(setMultipleAddress(addresses))
}

const fetchOnEnter = async (dispatch, getState, props) => {
	const { params, location } = props
	const { query, pathname } = location

	const patternNestedDetail = /(.+)?\/(\w{3}-\w{6})(\/(.+))?/gi
	let testPath = get(
		getState(),
		'routing.locationBeforeTransitions.pathname',
		''
	)

	const parseData = parseUrl({ pathname, query, params })
	const referrer = getState().searchConfig.get('referrer')

	if (patternNestedDetail.test(testPath)) {
		testPath = testPath.replace(patternNestedDetail, '$2')
		if (typeof window !== 'undefined') {
			window.location = `/${testPath}`
			return
		}
		return dispatch(push(`/${testPath}`))
	}

	// set all params from URL to state, keep referrer
	dispatch(setAll(Object.assign({}, parseData, { referrer })))

	// load list of advertisements except user come from detail
	const isLoading = getState().listApi.get('isLoading')
	if (
		!isLoading &&
		(referrer !== 'detail' || !getState().listApi.get('data').advertisements)
	) {
		dispatch(emptyList())
		return dispatch(
			load(Object.assign({}, parseData, { s: 0, skip: 0 }), false, false)
		)
	}
}

export const onEnter = ({ getState, dispatch }) => (props) => {
	fetchOnEnter(dispatch, getState, props).then(() => onDone(dispatch, getState))
}

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators(
		{
			setMultipleAddress,
			setModal,
			setModalInput,
			setModalState,
			setScrolltop,
			load,
			saveSearch,
			saveSearchLocally,
			removeSearchLocally,
			setOrder,
			setReferrer,
			emptyList,
			push,
		},
		dispatch
	),
})

const mapStateToProps = (state) => {
	return {
		data: state.listApi.get('data'),
		searchConfig: state.searchConfig,
		userData: state.userData,
		isLoading: state.listApi.get('isLoading'),
		lastFetched: state.listApi.get('lastFetched'),
		scrollTop: state.appData.get('scrollTop'),
		modal: state.appData.get('modal'),
		modal_state: state.appData.get('modal_state'),
		modal_input: state.appData.get('modal_input'),
		isAuthenticated: state.authApi.get('isAuthenticated'),
	}
}
export default connect(
	mapStateToProps,
	mapActionCreators
)(List)
