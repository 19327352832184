import React from 'react'
import PropTypes from 'prop-types'
import styles from './Button.module.scss'
import { omit } from 'lodash'
import createClassName from '../../utils/createClassName'
import Spinner from '../Spinner'

const icons = {
	magnifier: require('./magnifier.png'),
	pencl: require('./pencl.png'),
	call: require('./call.png'),
}

const Button = (props) => {
	let p = createClassName(props, styles)
	let buttonClass = styles.base + p('type') + p('size') + p('className', true)
	let classes = [buttonClass]

	if (props.loading) {
		classes.push(styles.loading)
	}

	return (
		<button
			{...omit(props, ['size', 'type', 'formType', 'ico', 'loading'])}
			type={props.formType}
			className={classes.join(' ')}>
			{!props.loading && props.ico && (
				<img src={icons[props.ico]} className={styles.ico} alt="icon" />
			)}
			{!props.loading && props.children}
			{props.loading && <Spinner inverse size="small" />}
		</button>
	)
}

Button.defaultProps = {
	size: 'default',
	type: 'default',
	ico: '',
}

Button.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	size: PropTypes.oneOf(['small', 'large', 'default', 'cover']),
	// eslint-disable-next-line react/no-unused-prop-types
	type: PropTypes.oneOf([
		'default',
		'positive',
		'negative',
		'info',
		'warning',
		'clear',
		'disabled',
		'secondary',
		'gray',
	]),
	ico: PropTypes.oneOf(['', ...Object.keys(icons)]),
	// eslint-disable-next-line react/no-unused-prop-types
	className: PropTypes.string,
	loading: PropTypes.bool,
	// eslint-disable-next-line react/no-unused-prop-types
	disabled: PropTypes.bool,
	formType: PropTypes.string,
	children: PropTypes.any,
}

export default Button
