import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { omit } from 'lodash'

class NumberFormat extends Component {
	constructor(props) {
		super(props)

		this.state = this.InitialState
	}

	get InitialState() {
		return {
			value: this.formatInput(this.props.value).formattedValue,
		}
	}

	componentWillReceiveProps(newProps) {
		this.setState({
			value: this.formatInput(newProps.value).formattedValue,
		})
	}

	shouldComponentUpdate(newProps) {
		return !(newProps !== this.props && newProps.value === this.props.value)
	}

	setCaretPosition(caretPos) {
		const el = this.refs.input
		// eslint-disable-next-line
		el.value = el.value
		// ^ this is used to not only get "focus", but
		// to make sure we don't have it everything -selected-
		// (it causes an issue in chrome, and having it doesn't hurt any other browser)
		if (el !== null) {
			if (el.createTextRange) {
				const range = el.createTextRange()
				range.move('character', caretPos)
				range.select()
				return true
			}
			// (el.selectionStart === 0 added for Firefox bug)
			if (el.selectionStart || el.selectionStart === 0) {
				el.focus()
				el.setSelectionRange(caretPos, caretPos)
				return true
			}

			// fail city, fortunately this never happens (as far as I've tested) :)
			el.focus()
			return false
		}
	}

	formatWithPattern(str) {
		const { format, mask } = this.props
		if (!format) return str
		const hashCount = format.split('#').length - 1
		let hashIdx = 0
		let frmtdStr = format

		for (let i = 0, ln = str.length; i < ln; i++) {
			if (i < hashCount) {
				hashIdx = frmtdStr.indexOf('#')
				frmtdStr = frmtdStr.replace('#', str[i])
			}
		}

		const lastIdx = frmtdStr.lastIndexOf('#')

		if (mask) {
			return frmtdStr.replace(/#/g, mask)
		}
		return (
			frmtdStr.substring(0, hashIdx + 1) +
			(lastIdx !== -1 ? frmtdStr.substring(lastIdx + 1, frmtdStr.length) : '')
		)
	}

	formatInput(val) {
		const {
			prefix,
			thousandSeperator,
			suffix,
			mask,
			format,
			separatorChar,
		} = this.props
		const maskPattern = format && typeof format === 'string' && !!mask

		if (!val || !(val + '').match(/\d/g))
			return { value: '', formattedValue: maskPattern ? '' : '' }
		const num = (val + '').match(/\d/g).join('')

		let formattedValue = num

		if (format) {
			if (typeof format === 'string') {
				formattedValue = this.formatWithPattern(formattedValue)
			} else if (typeof format === 'function') {
				formattedValue = format(formattedValue)
			}
		} else {
			if (thousandSeperator)
				formattedValue = formattedValue.replace(
					/(\d)(?=(\d{3})+(?!\d))/g,
					'$1' + separatorChar
				)

			// add prefix and suffix
			if (prefix) formattedValue = prefix + formattedValue
			if (suffix) formattedValue = formattedValue + suffix
		}

		return {
			value: formattedValue.match(/\d/g).join(''),
			formattedValue: formattedValue,
		}
	}

	getCursorPosition(inputValue, formattedValue, cursorPos) {
		let j = 0
		for (let i = 0; i < cursorPos; i++) {
			if (!inputValue[i].match(/\d/) && inputValue[i] !== formattedValue[j])
				continue
			while (inputValue[i] !== formattedValue[j] && j < formattedValue.length)
				j++
			j++
		}

		// check if there is no number before caret position
		while (j > 0 && formattedValue[j]) {
			if (!formattedValue[j - 1].match(/\d/)) j--
			else break
		}
		return j
	}
	onChangeHandler(e, callback) {
		const inputValue = e.target.value
		const { formattedValue, value } = this.formatInput(inputValue)
		let cursorPos = this.refs.input.selectionStart

		// change the state
		this.setState({ value: formattedValue }, () => {
			cursorPos = this.getCursorPosition(inputValue, formattedValue, cursorPos)
			this.setCaretPosition(cursorPos)
			if (callback) callback(e, value)
		})

		return value
	}
	onChange(e) {
		this.onChangeHandler(e, this.props.onChange)
	}
	onInput(e) {
		this.onChangeHandler(e, this.props.onInput)
	}

	render() {
		const { props } = this
		// eslint-disable-next-line react/prop-types
		if (props.displayType === 'text') {
			return (
				<span
					{...omit(this.props, [
						'displayType',
						'suffix',
						'thousandSeperator',
						'separatorChar',
					])}>
					{this.state.value}
				</span>
			)
		}
		return (
			<input
				{...this.props}
				type="tel"
				value={this.state.value}
				ref="input"
				onInput={this.onChange}
				onChange={this.onChange}
			/>
		)
	}
}

NumberFormat.propTypes = {
	displayType: PropTypes.string,
	separatorChar: PropTypes.string,
	prefix: PropTypes.string,
	thousandSeperator: PropTypes.bool,
	suffix: PropTypes.string,
	mask: PropTypes.any,
	format: PropTypes.any,
	value: PropTypes.number,
	onChange: PropTypes.func,
	onInput: PropTypes.func,
}

NumberFormat.defaultProps = {
	displayType: 'input',
	separatorChar: ',',
}

export default NumberFormat
