import React from 'react'
import PropTypes from 'prop-types'
import styles from './CheckBox.module.scss'
import createClassName from '../../utils/createClassName'

const CheckBox = (props) => {
	let p = createClassName(props, styles)
	let buttonClass = styles.base + p('type') + p('className', false)

	return (
		<div className={buttonClass}>
			<input
				type="checkbox"
				id={props.id}
				checked={props.checked}
				onChange={props.onClick}
			/>
			<label htmlFor={props.id}>{props.label}</label>
		</div>
	)
}

CheckBox.defaultProps = {
	type: 'default',
}
CheckBox.propTypes = {
	// eslint-disable-next-line
	type: PropTypes.oneOf(['default', 'positive', 'negative']),
	onClick: PropTypes.func,
	id: PropTypes.any,
	label: PropTypes.string,
	checked: PropTypes.bool,
}

export default CheckBox
