import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './ListCard.module.scss'
import Price from '../Price'
import Add2Favs from '../Add2Favs'
import Row from '../Row'
import Col from '../Col'
import { Link } from 'react-router'
import Title from '../Title'
import ApiImage from '../ApiImage'
import ListCardSlider from '../ListCardSlider'
import Paragraph from '../Paragraph'
import Badge from '../Badge'
import { trackGoogleAnalytics } from '../../App'

class ListCard extends Component {
	constructor(props) {
		super(props)

		this.state = {
			slider: false,
			loading: true,
			imageSrc: '',
		}
	}

	componentDidMount() {
		const { data } = this.props
		let imageSrc = ''

		if (data.photo_id) {
			imageSrc = data.photo_id
		} else if (data.photos && data.photos[0]) {
			imageSrc = data.photos[0].name
		}

		this.setState({ imageSrc })
	}

	onLoad = () => {
		this.setState({ loading: false })
	}

	activeSlider = () => {
		this.setState({ slider: true })
	}

	onLeave = () => {
		this.props.setScrollTop(this.props.order)
		this.props.beforeRedirect && this.props.beforeRedirect()
	}

	render() {
		const { data } = this.props
		const nextPhotos = data.photos ? data.photos.slice(1) : []

		return (
			<div className={styles.card}>
				{!this.state.slider && (
					<div
						className={this.state.loading ? styles.lazy : styles.ratio}
						onTouchStart={this.activeSlider}>
						<Link
							to={'/' + this.props.data.id + '/'}
							className={styles.imgcontainer}
							onClick={this.onLeave}>
							{this.state.loading && (
								<ApiImage
									src={this.state.imageSrc}
									onLoad={this.onLoad}
									className={styles.preloadimg}
								/>
							)}

							{!this.state.loading && (
								<ApiImage
									src={this.state.imageSrc}
									background
									className={styles.imgmain}
								/>
							)}
						</Link>
					</div>
				)}

				{this.state.slider && (
					<div className={styles.ratio}>
						<div className={styles.imgcontainer} onClick={this.onLeave}>
							<Link to={'/' + data.id + '/'}>
								<ListCardSlider
									first={this.state.imageSrc}
									nextPhotos={nextPhotos}
									className={styles.imgmain}
									afterSlide={() => {
										trackGoogleAnalytics(['send', 'event', 'Hledani', 'swipe'])
									}}
								/>
							</Link>
						</div>
					</div>
				)}

				<Badge {...data.badge} />

				{data.featured !== 'N' && data.unread && (
					<div className={styles['unread-badge']}>
						<i className="fa fa-repeat" />
					</div>
				)}

				<Row>
					<Col xs="10/12">
						<Link to={'/' + this.props.data.id + '/'} onClick={this.onLeave}>
							<Title
								type="h3"
								display="block"
								className={styles.title}
								styles={{ display: '-webkit-box' }}>
								{this.props.data.place}
							</Title>
						</Link>

						<Paragraph type="oneline" className={styles.about}>
							{this.props.data.type}
						</Paragraph>

						<Paragraph className={styles.about}>
							<Price price={this.props.data.price} className={styles.price} />
						</Paragraph>
					</Col>
					<Col xs="2/12">
						<Add2Favs data={this.props.data} parent="Hledani" />
					</Col>
				</Row>
			</div>
		)
	}
}

ListCard.defaultProps = {
	data: {},
	order: 0,
}

ListCard.propTypes = {
	data: PropTypes.object.isRequired,
	order: PropTypes.number,
	beforeRedirect: PropTypes.func,
	setScrollTop: PropTypes.func,
}

export default ListCard
