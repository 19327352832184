import React from 'react'
import PropTypes from 'prop-types'
import FacebookAuth from 'react-fb-auth'
import GoogleAuth from 'react-google-login'
import Recaptcha from 'react-grecaptcha'
import styles from './Auth.module.scss'
import gpLogo from './g-logo.png'
import Page from '../../../component/Page'
import PageTitle from '../../../component/PageTitle'
import Form from '../../../component/Form'
import InputEmail from '../../../component/InputEmail'
import InputPassword from '../../../component/InputPassword'
import Button from '../../../component/Button'
import Overlay from '../../../component/Overlay'
import ConfigurationService from '../../../service/ConfigurationService'
import { LOGIN_EVENT, REGISTER_EVENT } from '../../../constants/AuthConstants'

class Auth extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			authData: {
				email: null,
				password: null,
			},
			errors: {
				email: false,
				password: false,
			},
			wasRequestSent: false,
			isLoading: false,
			facebookAppId: null,
			googleApiId: null,
			recaptchaKey: null,
			event: LOGIN_EVENT,
		}
	}

	componentDidMount() {
		if (this.props.isAuthenticated) {
			this.context.router.push('/')
		} else {
			this.setState({
				facebookAppId: ConfigurationService.facebookAppId,
				googleApiId: ConfigurationService.googleApiId,
				recaptchaKey: ConfigurationService.recaptchaKey,
			})
		}
	}

	login = async (token) => {
		const { authData } = this.state

		if (this.isFormValid()) {
			return
		}

		this.setState({ isLoading: true })

		await this.props.actions.login({
			email: authData.email,
			password: authData.password,
			token: token,
		})
		this.handleAuthResponse(false)
	}

	handleAuthResponse = (isRegistration) => {
		if (this.props.isAuthenticated === true) {
			isRegistration &&
				this.props.actions.synchronizeData(this.props.favs, this.props.list)
			this.context.router.push('/')
		}

		this.setState({ isLoading: false, wasRequestSent: true })
	}

	executeRecaptcha = (event) => {
		this.setState({ event: event }, () => {
			if (this.state.wasRequestSent) {
				window.grecaptcha.reset()
			}
			window.grecaptcha.execute()
		})
	}

	handleExpiredRecaptcha = () => {
		window.grecaptcha.reset()
	}

	recaptchaCallback = (token) => {
		switch (this.state.event) {
			case LOGIN_EVENT:
				this.login(token)
				break
			case REGISTER_EVENT:
				this.register(token)
				break
			default:
				break
		}
	}

	register = async (token) => {
		if (this.isFormValid()) {
			return
		}

		this.setState({ isLoading: true })

		await this.props.actions.register({
			email: this.state.authData.email,
			password: this.state.authData.password,
			token: token,
		})
		this.handleAuthResponse(true)
	}

	navigateToForgottenPasswordPage = () => {
		this.context.router.push('/forgotten-password')
	}

	handleChange = (e) => {
		let target = e.nativeEvent.target
		let valid = target.validity.valid

		if (valid) {
			this.setState((prevState) => ({
				authData: {
					...prevState.authData,
					[target.id]: target.value,
				},
				errors: {
					...prevState.errors,
					[target.id]: !valid,
				},
			}))
		} else {
			this.setState((prevState) => ({
				errors: {
					...prevState.errors,
					[target.id]: !valid,
				},
			}))
		}
	}

	handleFacebookResponse = async (response) => {
		this.setState({ isLoading: true })
		let token = response.accessToken
		await this.props.actions.loginWithFacebook(token)
		this.handleAuthResponse(false)
	}

	handleGoogleResponse = async (response) => {
		this.setState({ isLoading: true })
		let token = response.tokenId
		await this.props.actions.loginWithGoogle(token)
		this.handleAuthResponse(false)
	}

	isFormValid = () => {
		return (
			this.state.errors.email ||
			this.state.errors.password ||
			!this.state.authData.email ||
			!this.state.authData.password
		)
	}

	render() {
		return (
			<Page className={styles.base}>
				<PageTitle>Registrace / Přihlášení do Moje reality</PageTitle>

				<Form className={styles['auth-form']}>
					<div className={styles['form-group']}>
						<label htmlFor="email">E-mail</label>
						<InputEmail
							id="email"
							disabled={this.state.isLoading}
							onChange={this.handleChange}
							required
						/>
						<div>
							&nbsp;
							{this.state.errors.email &&
								'Zadejte prosím platnou emailovou adresu'}
						</div>
					</div>

					<div className={styles['form-group']}>
						<label htmlFor="password">Heslo</label>
						<InputPassword
							id="password"
							disabled={this.state.isLoading}
							onChange={this.handleChange}
							pattern=".{4,}"
							required
						/>
						<div>
							{this.state.errors.password &&
								'Minimální délka hesla by měla být alespoň 4 znaky'}
						</div>
						<div
							className={styles['forgotten-password']}
							onClick={this.navigateToForgottenPasswordPage}>
							Zapomenuté heslo
						</div>
					</div>

					{this.state.wasRequestSent && (
						<div className={styles['error-message']}>{this.props.error}</div>
					)}

					<div className={styles['buttons-inline']}>
						<Button
							className="btn-sign-in"
							type="default"
							formType="button"
							disabled={this.state.isLoading}
							loading={false}
							onClick={() => {
								this.executeRecaptcha(LOGIN_EVENT)
							}}>
							Přihlásit
						</Button>
						{this.state.recaptchaKey && (
							<Button
								className="btn-sign-up"
								type="gray"
								value="register"
								formType="button"
								disabled={this.state.isLoading}
								loading={false}
								onClick={() => {
									this.executeRecaptcha(REGISTER_EVENT)
								}}>
								Registrovat
							</Button>
						)}
					</div>
				</Form>

				<div className={styles['or-tag']}>nebo</div>

				<div className={styles['social-networks']}>
					{this.state.facebookAppId && (
						<FacebookAuth
							appId={this.state.facebookAppId}
							autoLoad={false}
							scope="public_profile, email"
							fields="email"
							callback={this.handleFacebookResponse}
							component={({ onClick }) => {
								return (
									<span className={styles['fb-button-wrapper']}>
										<Button
											size="cover"
											type="info"
											disabled={this.state.isLoading}
											loading={false}
											onClick={onClick}>
											<i className="fa fa-facebook" />
											Přihlásit se přes Facebook
										</Button>
									</span>
								)
							}}
						/>
					)}

					{this.state.googleApiId && (
						<GoogleAuth
							clientId={this.state.googleApiId}
							className={styles['gp-button-wrapper']}
							buttonText=" "
							autoLoad={false}
							onSuccess={this.handleGoogleResponse}
							onFailure={() => this.setState({ isLoading: false })}
							style={{}}
							tag="span">
							<Button
								size="cover"
								type="default"
								disabled={this.state.isLoading}
								loading={false}>
								<img src={gpLogo} alt="google" />
								Přihlásit se přes Google
							</Button>
						</GoogleAuth>
					)}
				</div>

				<Overlay shown={this.state.isLoading} withSpinner />

				{this.state.recaptchaKey && (
					<div id="recaptcha" className={styles['recaptcha']}>
						<Recaptcha
							data-badge="inline"
							sitekey={this.state.recaptchaKey}
							size="invisible"
							callback={this.recaptchaCallback}
							expiredCallback={this.handleExpiredRecaptcha}
							locale="cs"
							invisible
						/>
					</div>
				)}
			</Page>
		)
	}
}

Auth.propTypes = {
	isAuthenticated: PropTypes.bool,
	actions: PropTypes.object,
	error: PropTypes.string,
	favs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

Auth.contextTypes = {
	router: PropTypes.object,
}

export default Auth
