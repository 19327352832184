import axios from 'axios'
import createFormUrlEncoded from '../utils/createFormUrlEncoded'
import { ERROR_REPORT_URL } from '../constants/AppConstants'
import { getUrl } from './getApiUrl'

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export const bind = (fn, success, error) => (dispatch) => {
	return fn()
		.then((response) => {
			return dispatch(success(response.data))
		})
		.catch((e) => {
			return dispatch(error(e.toString()))
		})
}

const CONFIG_GET = {
	method: 'GET',
	headers: {
		Accept: 'application/json',
	},
	withCredentials: true,
}

export const sendAxiosGetRequest = (url) => axios(url, CONFIG_GET)

export const sendAxiosPostRequest = (url, body = null) => {
	let config = {
		mode: 'no-cors',
		method: 'POST',
		headers: {
			Accept: 'application/json',
		},
		withCredentials: true,
	}

	if (body) {
		config.data = typeof body === 'string' ? body : createFormUrlEncoded(body)
		config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
	}

	return axios(url, config)
}

export const sendAxiosErrorValue = (data) => {
	try {
		sendAxiosPostRequest(getUrl(ERROR_REPORT_URL), data)
	} catch (exception) {}
}
