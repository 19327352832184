import React from 'react'
import PropTypes from 'prop-types'
import styles from './FieldError.module.scss'

const FieldError = (props) => (
	<div className={styles.base}>{props.children}</div>
)

FieldError.propTypes = {
	children: PropTypes.any,
}

export default FieldError
