import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setOpenCard } from '../../../reducer/OpenCardReducer'
import Search from '../component/Search'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators({ setOpenCard }, dispatch),
})

const mapStateToProps = (state) => ({
	opencard: state.openCard.get('openCard'),
})

export default connect(
	mapStateToProps,
	mapActionCreators
)(Search)
