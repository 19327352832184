import React from 'react'
import PropTypes from 'prop-types'
import styles from './Favs.module.scss'
import Page from '../../../component/Page'
import PageTitle from '../../../component/PageTitle'
import ListCard from '../../../component/ListCard'
import Title from '../../../component/Title'
import getListCardHeight from '../../../utils/getListCardHeight'
import Overlay from '../../../component/Overlay'
import InfinityList from '../../../component/InfinityList'
import { trackGoogleAnalytics } from '../../../App'
import { getUrl } from '../../../utils/getApiUrl'
import { MARK_UPDATED_AS_READ_URL } from '../../../constants/UserDataConstants'
import { sendAxiosGetRequest } from '../../../utils/fetcher'

class Favs extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isLoading: false,
			showingUpdated: Boolean(
				props.location.state && props.location.state.notifications
			),
			updatedFavsSize:
				(props.location.state && props.location.state.updatedFavsSize) || 0,
		}
	}

	componentDidMount() {
		if (!this.state.showingUpdated) {
			this.context.router.replace({
				pathname: '/favs',
				state: { notifications: false },
			})
		}

		if (this.props.isAuthenticated && !this.state.showingUpdated) {
			this.loadData({ push: false })
		}
	}

	componentWillReceiveProps(nextProps) {
		/**
		 * Routing reducer is used to store state of the current route.
		 * It is done so user can return to the updated favs page even if
		 * all items have already been marked as read.
		 */
		if (nextProps.location.state) {
			this.setState({
				showingUpdated: Boolean(nextProps.location.state.notifications),
				updatedFavsSize: nextProps.location.state.updatedFavsSize || 0,
			})
		}
	}

	loadData = (config) => {
		if (!this.state.showingUpdated) {
			this.loadFavs(config)
		} else {
			this.loadUpdatedFavs(config).then(() => {
				this.markUpdatedAsRead()
			})
		}
	}

	loadFavs = (config) => {
		this.setState({ isLoading: true })
		return this.props.actions.loadFavs(config).then(() => {
			this.setState({ isLoading: false })
		})
	}

	loadUpdatedFavs = (config) => {
		this.setState({ isLoading: true })
		return this.props.actions.loadUpdatedFavs(config).then(() => {
			this.setState({ isLoading: false })
		})
	}

	markUpdatedAsRead = () => {
		const endpoint = getUrl(MARK_UPDATED_AS_READ_URL)
		sendAxiosGetRequest(endpoint)
	}

	toggleUpdated = () => {
		if (!this.state.showingUpdated) {
			/**
			 * Before showing updated favs to the user, we need to create new history item
			 * and push it so we can use back button to return to Favs page. We also have to
			 * save number of updated favs because after the UpdatedFavs page is loaded the request
			 * will be sent to mark all favs as read and we will have no way to get updated favs count.
			 */
			this.context.router.push({
				pathname: '/favs',
				state: {
					notifications: true,
					updatedFavsSize: this.props.updatedFavsSize,
				},
			})
		} else {
			/**
			 * Since we had added new history item, we can now simply use goBack.
			 */
			this.context.router.goBack()
		}

		this.setState(
			(prevState) => ({
				showingUpdated: !prevState.showingUpdated,
			}),
			() => this.loadData({ push: false })
		)
	}

	/**
	 * If user is authenticated the request will be send to the backend.
	 * Otherwise local favs list will be cleared.
	 */
	emptyFavsList = () => {
		if (this.props.isAuthenticated) {
			this.props.actions.emptyFavsList()
		} else {
			this.props.actions.emptyFavsListLocally()
		}
	}

	createElements = (items) => {
		const elements = items.map((value) => (
			<div
				style={{ height: getListCardHeight(this.props.display) + 100 }}
				key={value.id}>
				<ListCard
					data={value}
					beforeRedirect={() => {
						trackGoogleAnalytics([
							'send',
							'event',
							'Oblibene',
							'proklik na detail',
						])
					}}
				/>
			</div>
		))

		if (items.hasOwnProperty('size')) {
			return elements.toJS()
		}

		return elements
	}

	createPageHeader = (size) => {
		return (
			<div className={styles['page-header']}>
				<PageTitle>
					{`${
						this.state.showingUpdated ? 'Aktualizované položky' : 'Oblíbené'
					} (${size})`}
				</PageTitle>

				<div onClick={this.emptyFavsList} className={styles.removeAll}>
					{this.props.favsSize > 0 && !this.state.showingUpdated && (
						<Title type="p5">odstranit všechny položky</Title>
					)}
				</div>

				{this.props.updatedFavsSize > 0 && (
					<div
						onClick={this.toggleUpdated}
						className={
							styles.removeAll +
							' ' +
							(!this.state.showingUpdated && styles['updated-favs-link'])
						}>
						<Title type="p5">
							{`zobrazit ${
								this.state.showingUpdated
									? 'všechny položky'
									: 'aktualizované nabídky (' + this.props.updatedFavsSize + ')'
							}`}
						</Title>
					</div>
				)}

				{this.props.favsSize === 0 && (
					<div className={styles.noItems}>
						Momentálně nemáte žádné položky v&nbsp;oblíbených
					</div>
				)}
			</div>
		)
	}

	render() {
		const isAuthenticated = this.props.isAuthenticated

		let items = this.state.showingUpdated
			? this.props.updatedFavs
			: this.props.favs
		let size = this.state.showingUpdated
			? this.state.updatedFavsSize
			: this.props.favsSize

		const elements = this.createElements(items)
		const pageHeader = this.createPageHeader(size)

		return (
			<Page className={styles.base}>
				{isAuthenticated && elements.length > 0 && (
					<InfinityList
						isLoading={this.state.isLoading}
						rowsCount={size}
						list={elements}
						loadData={(config) => this.loadData({ ...config, push: true })}
						setInstance={(inst) => {
							this.infinityList = inst
						}}>
						{pageHeader}
					</InfinityList>
				)}

				{(!isAuthenticated || elements.length === 0) && (
					<div>
						{pageHeader}
						{elements}
					</div>
				)}

				<Overlay shown={this.state.isLoading} withSpinner />
			</Page>
		)
	}
}

Favs.propTypes = {
	actions: PropTypes.object,
	display: PropTypes.object,
	isAuthenticated: PropTypes.bool,
	updatedFavs: PropTypes.object,
	updatedFavsSize: PropTypes.number,
	favs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	favsSize: PropTypes.number,
	location: PropTypes.object,
}

Favs.contextTypes = {
	router: PropTypes.object,
}

export default Favs
