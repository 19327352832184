import Lists from '../component/Lists'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setOpenCard } from '../../../reducer/OpenCardReducer'
import {
	emptySearchesList,
	emptySearchesListLocally,
	removeSearch,
	removeSearchLocally,
	loadSearches,
	loadNewOffers,
} from '../../../reducer/UserDataReducer'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators(
		{
			emptySearchesList,
			emptySearchesListLocally,
			removeSearch,
			removeSearchLocally,
			loadSearches,
			loadNewOffers,
			setOpenCard,
		},
		dispatch
	),
})

const mapStateToProps = (state) => ({
	lists: state.userData.get('list'),
	listSize: state.userData.get('listSize'),
	newOffers: state.userData.get('newOffers'),
	newOffersSize: state.userData.get('newOffersSize'),
	isAuthenticated: state.authApi.get('isAuthenticated'),
	opencard: state.openCard.get('openCard'),
	display: state.display.toJS(),
	location: state.routing.locationBeforeTransitions,
})

export default connect(
	mapStateToProps,
	mapActionCreators
)(Lists)
