import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Field from '../Field'
import InputText from '../InputText'
import Spinner from '../Spinner'
import styles from './AddressAutocomplete.module.scss'

class AddressAutocomplete extends Component {
	constructor(props) {
		super(props)

		this.selected = false // flag for onBlur, set true in onSelect
		this.state = {
			open: false,
			value: props.value
				? props.value.fullname
					? props.value.fullname
					: props.value
				: '',
			geoWaiting: false,
			error: '',
		}
	}

	onChange = (val) => {
		this.setState({
			value: val,
			geoWaiting: false,
			error: '',
		})

		if (val.length >= 2) {
			this.setState({ open: true })
			clearTimeout(this.to)
			this.to = setTimeout(() => {
				this.props.actions.load(val)
			}, 100)
		}

		this.props.onChange && this.props.onChange(val)
	}

	onBlur = () => {
		// timeout keep on click event where target
		setTimeout(() => {
			if (this.selected) {
				// avoid setState after onSelect
				this.selected = false
			} else {
				this.setState({ open: false })
			}
		}, 500)
	}

	onClick = () => {
		// open the dialog
		this.setState({ open: true })
	}

	onSelect = (pos) => {
		this.selected = true
		// select value and set state
		this.setState({
			open: false,
			value: pos.fullname,
		})
		this.props.saveValue({ url: pos.url, fullname: pos.fullname })
	}

	useGeoData = (position) => {
		this.setState({ geoWaiting: false })
		this.props.saveValue({
			url: 'V-teto-lokalite',
			fullname: 'Aktuální poloha',
		})
		this.props.actions.setGeoLocation({
			maplat: position.coords.latitude,
			maplng: position.coords.longitude,
		})
	}

	error = (e) => {
		let errorMsg = ''
		console.error(e)
		switch (e.code) {
			// PERMISSION_DENIED
			case 1:
				errorMsg =
					'Využití polohy není povoleno. Zkontrolujte prosím nastavení vašeho prohlížeče nebo nastavení zařízení.'
				break
			//  POSITION_UNAVAILABLE
			case 2:
				errorMsg = 'Informace o poloze nejsou k dispozici..'
				break
			// TIMEOUT
			case 3:
				errorMsg = 'Data o poloze se nepodařila v časovém limitu načíst.'
				break
			default:
				errorMsg = 'Vaše zařízení neumožňuje sdílet polohu.'
		}

		this.setState({
			value: '',
			error: errorMsg,
		})
	}

	onSelectGeo = () => {
		this.setState({
			open: false,
			value: 'Aktuální poloha',
			geoWaiting: true,
		})

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(this.useGeoData, this.error)
		} else {
			this.error({ code: 2 })
		}
	}

	render() {
		return (
			<div className={`${styles.base} ${this.props.className}`}>
				<Field
					type={
						this.state.error !== '' || this.props.invalid
							? 'negative'
							: this.state.geoWaiting
							? 'loading'
							: 'default'
					}
					label=""
					fieldError={this.state.error}
					size="small"
					emptyAble={
						!this.state.geoWaiting &&
						this.state.value.length > 0 &&
						(() => this.onChange(''))
					}>
					<InputText
						placeholder="Zadejte kraj, město, ulici"
						onChange={(e) => this.onChange(e.target.value)}
						onBlur={this.onBlur}
						onClick={this.onClick}
						value={this.state.value}
					/>
				</Field>

				<ul className={this.state.open ? styles.address : styles.hidden}>
					<li onClick={this.onSelectGeo}>
						Aktuální poloha
						<br />
						<small>Hledá ve vašem okolí</small>
						{this.props.isLoading && (
							<Spinner size="small" className={styles.spinner} />
						)}
					</li>

					{this.state.value.length > 1 &&
						this.props.data.map &&
						this.props.data.map((v, k) => {
							return (
								<li key={k} onClick={() => this.onSelect(v)}>
									{v.fullname}
								</li>
							)
						})}
				</ul>
			</div>
		)
	}
}

AddressAutocomplete.defaultProps = {
	className: '',
}

AddressAutocomplete.propTypes = {
	saveValue: PropTypes.func,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			fullname: PropTypes.string,
		}),
	]),
	onChange: PropTypes.func,
	className: PropTypes.string,
	invalid: PropTypes.bool,
	actions: PropTypes.object,
	isLoading: PropTypes.bool,
	data: PropTypes.any,
}

export default AddressAutocomplete
