import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './Gallery.module.scss'
import ApiImage from '../ApiImage'
import SwipeableViews from 'react-swipeable-views'
import LazyLoad from 'react-lazy-load'
import getBiggerDisplaySize from '../../utils/getBiggerDisplaySize'
import { trackGoogleAnalytics } from '../../App'
import { WWW_URL } from '../../constants/AppConstants'

class Gallery extends Component {
	constructor(props) {
		super(props)

		this.state = {
			detail: false,
			selected: 0,
		}
	}

	componentWillUpdate() {
		// close detail if gallery is not visible
		if (!this.props.isVisible && this.state.detail) {
			this.setState({ detail: false })
		}
	}

	showDetail = (k) => {
		this.props.onDetailVisible(this.closeDetail)
		this.setState({ detail: true, selected: k })
	}

	closeDetail = () => {
		this.props.onDetailHidden()
		this.setState({ detail: false })
	}

	select = (index) => {
		this.setState({ selected: index })
		trackGoogleAnalytics(['send', 'event', 'Detail', 'swipe'])
	}

	getClass = (k, s) => {
		if (k === s) return 'selected'
		if (k === s - 1) return 'prev'
		if (k === s + 1) return 'next'
	}

	render() {
		return (
			<div className={styles.gallery}>
				{this.props.isVisible && this.props.photos && (
					<div className={styles.clearfix}>
						{!this.state.detail &&
							this.props.photos.map((v, k) => {
								return (
									<div
										onClick={() => this.showDetail(k)}
										key={k}
										className={styles.one}>
										<LazyLoad height={150} className={styles.lazy}>
											<ApiImage
												src={v.name}
												height={150}
												div={2}
												className={styles.oneimg}
											/>
										</LazyLoad>
									</div>
								)
							})}

						{this.state.detail && (
							<div className={styles.detail}>
								<div className={styles.gallery_header}>
									{this.state.selected + 1} / {this.props.photos.length}
								</div>

								<SwipeableViews
									className={styles.carousel}
									index={this.state.selected}
									onChangeIndex={this.select}
									enableMouseEvents={true}>
									{this.props.photos.map((v, k) => {
										return (
											<div className={styles.card} key={k}>
												<div
													className={
														styles.imgcontainer +
														' ' +
														styles[this.getClass(k, this.state.selected)]
													}>
													<div
														style={{
															backgroundImage:
																'url(' +
																WWW_URL +
																v.name +
																'?w' +
																getBiggerDisplaySize() +
																')',
														}}
														className={styles.bigone}
													/>
												</div>
											</div>
										)
									})}
								</SwipeableViews>
							</div>
						)}
					</div>
				)}
			</div>
		)
	}
}

Gallery.defaultProps = {
	isVisible: false,
}

Gallery.propTypes = {
	isVisible: PropTypes.bool,
	onDetailVisible: PropTypes.func,
	onDetailHidden: PropTypes.func,
	photos: PropTypes.any,
}

export default Gallery
