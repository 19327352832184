import { sendAxiosGetRequest, sendAxiosPostRequest } from '../utils/fetcher'
import * as Immutable from 'immutable'
import { createAction, handleActions } from 'redux-actions'
import { getUrl } from '../utils/getApiUrl'

import {
	DETAIL_API_LOAD_FAILURE_ACTION,
	DETAIL_API_LOAD_START_ACTION,
	DETAIL_API_LOAD_SUCCESS_ACTION,
	DETAIL_API_SAVE_NOTE_FAILURE_ACTION,
	DETAIL_API_SAVE_NOTE_SUCCESS_ACTION,
	DETAIL_API_SAVE_NOTE_START_ACTION,
	DETAIL_EMPTY_ACTION,
	DETAIL_URL,
	SAVE_NOTE_URL,
} from '../constants/DetailConstants'

// -----------------------------------------------------------------------------
// Initial state
// -----------------------------------------------------------------------------
const initialState = Immutable.fromJS({
	isLoading: false,
	lastFetched: null,
	error: null,
	data: {},
})

// -----------------------------------------------------------------------------
// Actions
// -----------------------------------------------------------------------------
const loadStart = createAction(DETAIL_API_LOAD_START_ACTION)
const loadSuccess = createAction(DETAIL_API_LOAD_SUCCESS_ACTION)
const loadFailure = createAction(DETAIL_API_LOAD_FAILURE_ACTION)
export const empty = createAction(DETAIL_EMPTY_ACTION)

const saveNoteStart = createAction(DETAIL_API_SAVE_NOTE_START_ACTION)
const saveNoteSuccess = createAction(DETAIL_API_SAVE_NOTE_SUCCESS_ACTION)
const saveNoteFailure = createAction(DETAIL_API_SAVE_NOTE_FAILURE_ACTION)

// -----------------------------------------------------------------------------
// Local functions
// -----------------------------------------------------------------------------
function handleSaveNoteResponse(response) {
	return response.err ? saveNoteFailure(response) : saveNoteSuccess(response)
}

// -----------------------------------------------------------------------------
// Side effects
// -----------------------------------------------------------------------------
export function load(id) {
	return async (dispatch) => {
		const endpoint = getUrl(DETAIL_URL.replace(':id', id))
		dispatch(loadStart())

		try {
			const { data } = await sendAxiosGetRequest(endpoint)
			dispatch(loadSuccess(data))
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

export function saveNote(advertisementId, note) {
	return async (dispatch) => {
		let endpoint = getUrl(
			SAVE_NOTE_URL.replace(':advertisement_id', advertisementId)
		)
		dispatch(saveNoteStart())

		try {
			const { data } = await sendAxiosPostRequest(endpoint, { note: note })
			dispatch(handleSaveNoteResponse(data))
		} catch (exception) {
			dispatch(saveNoteFailure(exception))
		}
	}
}

// -----------------------------------------------------------------------------
// Action handlers
// -----------------------------------------------------------------------------
export default handleActions(
	{
		[DETAIL_API_LOAD_START_ACTION]: (state) =>
			state.merge({
				isLoading: true,
				data: {},
			}),

		[DETAIL_API_LOAD_SUCCESS_ACTION]: (state, action) =>
			state.merge({
				isLoading: false,
				data: action.payload,
				lastFetched: new Date().getTime(),
				error: null,
			}),

		[DETAIL_API_LOAD_FAILURE_ACTION]: (state, action) =>
			state.merge({
				isLoading: false,
				error: action.payload,
				data: {},
			}),

		[DETAIL_EMPTY_ACTION]: (state) =>
			state.merge({
				isLoading: false,
				data: {},
			}),

		[DETAIL_API_SAVE_NOTE_START_ACTION]: (state) =>
			state.merge({
				isLoading: true,
			}),

		[DETAIL_API_SAVE_NOTE_SUCCESS_ACTION]: (state) =>
			state.merge({
				isLoading: false,
				error: null,
			}),

		[DETAIL_API_SAVE_NOTE_FAILURE_ACTION]: (state, action) =>
			state.merge({
				isLoading: false,
				error: action.payload.err,
			}),
	},
	initialState
)
