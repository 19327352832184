import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import InlineSVG from 'svg-inline-react'
import * as _ from 'lodash'
import styles from './Detail.module.scss'
import Page from '../../../component/Page'
import Paragraph from '../../../component/Paragraph'
import Price from '../../../component/Price'
import Add2Favs from '../../../component/Add2Favs'
import Gallery from '../../../component/Gallery'
import Information from '../../../component/Information'
import Row from '../../../component/Row'
import Col from '../../../component/Col'
import Heading from '../../../component/Heading'
import Map from '../../../component/Map'
import Contact from '../../../component/Contact'
import Contacts from '../../../component/Contacts'
import Social from '../../../component/Social'
import Footer from '../../../component/Footer'
import OpenCard from '../../../component/OpenCard'
import Field from '../../../component/Field'
import Overlay from '../../../component/Overlay'
import TextArea from '../../../component/TextArea'
import OpenCardHeader from '../../../component/OpenCardHeader'
import Title from '../../../component/Title'
import Page404 from '../../../component/Page404'
import ContactForm from '../../../forms/ContactForm'
import ListCardSlider from '../../../component/ListCardSlider'
import Badge from '../../../component/Badge'
import { WWW_URL } from '../../../constants/AppConstants'
import { DETAIL_API_SAVE_NOTE_SUCCESS_ACTION } from '../../../constants/DetailConstants'
import {
	OPEN_CARD_MAP,
	OPEN_CARD_GALLERY,
	OPEN_CARD_CONTACT,
	OPEN_CARD_CONTACTS,
} from '../../../constants/OpenCardConstants'
import { trackGoogleAnalytics } from '../../../App'

// min length of text to clamp
const MIN_CLAMP_SIZE = 350

class Detail extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			text: false,
			open_card_back: null,
			noteSaved: false,
			editingNote: false,
		}

		// to control OpenCardHeader back button
		this.props.actions.setReferrer('detail')
	}

	componentWillMount() {
		this.saveNote = _.debounce(async () => {
			const actionType = await this.props.actions.saveNote(
				this.props.data.id,
				this.state.note
			)
			if (actionType === DETAIL_API_SAVE_NOTE_SUCCESS_ACTION) {
				this.setState({ noteSaved: true })
				this.hideSavedNoteMessage()
			}
		}, 4000)

		this.hideSavedNoteMessage = _.debounce(() => {
			this.setState({ noteSaved: false })
		}, 3000)
	}

	componentDidMount() {
		if (this.props.isAuthenticated) {
			this.props.actions.loadSettings()
		}
	}

	componentWillReceiveProps(nextProps) {
		let note = nextProps.data.note
		if (note && typeof this.state.note === 'undefined') {
			this.setState({ note: note })
		}
	}

	componentWillUnmount() {
		this.closeModal()
	}

	handleNoteChange = (e) => {
		this.setState({ note: e.target.value, noteSaved: false }, this.saveNote)
	}

	openGallery = () => {
		this.props.actions.setOpenCard(OPEN_CARD_GALLERY)
		trackGoogleAnalytics(['send', 'event', 'Detail', 'Fotogalerie'])
	}

	openMap = () => {
		this.props.actions.setOpenCard(OPEN_CARD_MAP)
		trackGoogleAnalytics(['send', 'event', 'Detail', 'Mapa'])
	}

	openContact = () => {
		this.props.actions.setOpenCard(OPEN_CARD_CONTACT)
		trackGoogleAnalytics(['send', 'event', 'Detail', 'Kontakty'])
	}

	openContacts = () => {
		trackGoogleAnalytics(['send', 'event', 'Detail', 'Otevreni dotazu'])
		this.props.actions.setOpenCard(OPEN_CARD_CONTACTS)
	}

	closeModal = () => {
		this.props.actions.closeOpenCard()
	}

	showText = () => {
		this.setState({ text: true })
	}

	onGalleryDetailVisible = (closeFN) => {
		this.setState({ open_card_back: closeFN })
	}

	onGalleryDetailHidden = () => {
		this.setState({ open_card_back: null })
	}

	toggleNoteInput = () => {
		this.setState((prevState) => ({
			editingNote: !prevState.editingNote,
		}))
	}

	render() {
		let { data, error, params, isAuthenticated, loggedUser } = this.props
		let initialContactData = {
			message: `Při prohlížení reality.cz mě zaujala tato Vaše nabídka.
      Kontaktujte mě prosím pro podrobnější informace. Děkuji.`,
		}

		if (loggedUser) {
			const firstName = loggedUser.first_name || ''
			const lastName = loggedUser.last_name || ''
			const firstTitle = loggedUser.title1 || ''
			const secondTitle = loggedUser.title2 || ''
			const userPhones = loggedUser.phones || ''

			initialContactData.name = `${firstTitle} ${firstName} ${lastName} ${secondTitle}`
			initialContactData.email = loggedUser.email || ''
			initialContactData.phone = (userPhones && userPhones[0]) || ''
		} else if (data.visitor) {
			initialContactData.email = data.visitor.email
			initialContactData.name = data.visitor.name
			initialContactData.phone = data.visitor.phone
		}

		// JSON data neco obsahuje, ale neobsahuji ID => neexistujici nabidka nebo nabidka ktera uz neni platna
		let show404 = error || (Object.keys(data).length !== 0 && !data.id)
		// proved redirect na www.reality.cz pokud predane ID neni ve formatu detailu nabidy
		let noRedirect = /^\w{3}-\w{6}$/gi.test(params.id)
		// chybove hlasky z API
		let errorMessage =
			(this.props.data.outdated_text || '') + (this.props.data.err || '')

		let noteClasses =
			styles.note +
			((this.state.noteSaved && ' ' + styles['note-saved']) || '') +
			((this.state.editingNote && ' ' + styles['note-editing']) || '') +
			((this.state.note && ' ' + styles['no-note']) || '')

		return (
			<div>
				{Object.keys(data).length !== 0 && !show404 && (
					<div>
						<Page className={styles.base}>
							<Helmet
								title={`${this.props.data.type} ${data.place}`}
								meta={[
									{ name: 'description', content: data.title },
									{ name: 'keywords', content: data.title },
								]}
							/>

							<Row className={styles.mainimg}>
								<Col xs="12/12">
									{(data.photos && (
										<div onClick={this.openGallery} className={styles.photos}>
											<Badge {...data.badge} />
											<ListCardSlider
												first={data.photos[0].name}
												nextPhotos={data.photos.slice(1).map((v) => v.name)}
												id={'1'}
												className={styles.detailimg}
											/>
										</div>
									)) || (
										<div className={styles.noImg}>
											Fotografie není k dispozici
										</div>
									)}
								</Col>
							</Row>

							<Row className={styles.fotolocation}>
								<Col xs="6/12">
									{data.photos && (
										<span
											onClick={this.openGallery}
											className={styles.wrapper__icon}>
											<InlineSVG className={styles.ico__photo} />
											<Title type="p3">Fotografie ({data.photos.length})</Title>
										</span>
									)}
								</Col>

								<Col xs="4/12">
									<span onClick={this.openMap} className={styles.wrapper__icon}>
										<InlineSVG className={styles.ico__map} />
										<Title type="p3">Mapa</Title>
									</span>
								</Col>
								<Col xs="2/12" className={styles['add-to-favs-container']}>
									<Add2Favs data={this.props.data} parent="Detail" />
								</Col>
							</Row>

							<Row className={styles.Heading}>
								<Col xs="10/12">
									{isAuthenticated && (
										<Field label="">
											<TextArea
												id="note"
												className={noteClasses}
												placeholder="přidat poznámku k nemovitosti"
												rows={3}
												maxRows={1000}
												useCacheForDOMMeasurements
												title="přidat poznámku k nemovitosti"
												value={this.state.note || ''}
												onChange={this.handleNoteChange}
												onClick={() => this.setState({ editingNote: true })}
												readOnly={!this.state.editingNote}
											/>

											{this.state.editingNote && (
												<div
													className={
														styles['note-saved-message'] +
														' ' +
														(this.state.noteSaved && styles['note-saved-shown'])
													}>
													&nbsp;
													{this.state.noteSaved &&
														`Poznámka k nabídce ${data.id} uložena.`}
												</div>
											)}
										</Field>
									)}

									<Heading priority={1}>{data.place}</Heading>
									<Title type="p2" display="block">
										{data.type}
									</Title>
									<Title type="p2" display="block">
										{((!data.price ||
											(!data.price.sale && !data.price.rent)) && (
											<span onClick={this.openContact}>
												<Price price={data.price} />
											</span>
										)) || <Price price={data.price} />}
									</Title>

									{data.price && data.price.note && (
										<span className={styles.price_note}>
											{' '}
											{data.price.note}{' '}
										</span>
									)}
								</Col>
								<Col xs="2/12" className={styles['pencil-container']}>
									{isAuthenticated && (
										<i
											className="fa fa-pencil"
											onClick={this.toggleNoteInput}
										/>
									)}
								</Col>
							</Row>

							<Row>
								<Col xs="12/12">
									<Paragraph>
										{(this.state.text ||
											this.props.data.description.length <= MIN_CLAMP_SIZE) &&
											this.props.data.description}
										{!this.state.text &&
											this.props.data.description.length > MIN_CLAMP_SIZE &&
											this.props.data.description.substr(0, MIN_CLAMP_SIZE) +
												'...'}
									</Paragraph>
								</Col>
							</Row>

							<Row>
								<Col xs="12/12">
									<span
										className={
											this.state.text ||
											this.props.data.description.length <= MIN_CLAMP_SIZE
												? styles.showTextHidden
												: styles.showText
										}
										onClick={this.showText}>
										Celý text
									</span>
								</Col>
							</Row>

							{this.props.data.information && (
								<Information
									information={this.props.data.information}
									price={this.props.data.price}
									idadvert={this.props.params.id}
								/>
							)}

							<OpenCard open={this.props.opencard === OPEN_CARD_GALLERY}>
								<div>
									{this.props.opencard === OPEN_CARD_GALLERY && (
										<OpenCardHeader
											onClose={this.closeModal}
											open={this.props.opencard === OPEN_CARD_GALLERY}
											title={`Fotogalerie (${this.props.data.photos.length})`}
											onBack={this.state.open_card_back}
											titleClassName={styles.galleryTitle}
										/>
									)}
									<Gallery
										photos={this.props.data.photos}
										isVisible={this.props.opencard === OPEN_CARD_GALLERY}
										onDetailVisible={this.onGalleryDetailVisible}
										onDetailHidden={this.onGalleryDetailHidden}
									/>
								</div>
							</OpenCard>

							<OpenCard open={this.props.opencard === OPEN_CARD_MAP}>
								<div className={styles.mapcontainer}>
									{this.props.opencard === OPEN_CARD_MAP && (
										<OpenCardHeader
											onClose={this.closeModal}
											open={this.props.opencard === OPEN_CARD_MAP}
											title={'Lokalita'}
										/>
									)}

									{this.props.opencard === OPEN_CARD_MAP && (
										<Map
											center={this.props.data.location.gps}
											markers={[this.props.data.location.gps]}
											streets={this.props.data.location.street}
											showLocation={this.props.data.location.showLocation}
										/>
									)}
								</div>
							</OpenCard>

							<OpenCard open={this.props.opencard === OPEN_CARD_CONTACTS}>
								<div className={styles.contactcontainer}>
									{this.props.opencard === OPEN_CARD_CONTACTS && (
										<OpenCardHeader
											onClose={this.closeModal}
											open={this.props.opencard === OPEN_CARD_CONTACTS}
											title="Nemovitost nabízí"
											onBack={this.state.open_card_back}
											titleClassName={styles.galleryTitle}
										/>
									)}
									<Contacts
										data={this.props.data.contact}
										isVisible={this.props.opencard === OPEN_CARD_CONTACTS}
									/>
								</div>
							</OpenCard>

							<OpenCard open={this.props.opencard === OPEN_CARD_CONTACT}>
								<div>
									{this.props.opencard === OPEN_CARD_CONTACT && (
										<OpenCardHeader
											open={this.props.open}
											onClose={this.closeModal}
											title={'Napsat'}
										/>
									)}
									<ContactForm
										data={data.contact}
										ad_id={data.id}
										onClose={this.closeModal}
										onSendSuccess={this.closeModal}
										isAuthenticated={isAuthenticated}
										onSendFailure={() => {
											alert(
												'Omlouváme se, při odesílání dotazu nastala chyba. Zkuste to, prosím, později.'
											)
										}}
										initialValues={initialContactData}
									/>
								</div>
							</OpenCard>
						</Page>

						<div className={styles.fullwidth}>
							<aside className={styles.offerId}>
								ID nabídky: {this.props.params.id}
							</aside>
							<Contact
								onClose={this.closeModal}
								openContactForm={this.openContact}
								data={data.contact}
								location={this.props.location}
							/>

							{(!data.contact.advertiser ||
								data.contact.advertiser.has_name ||
									data.contact.advertiser.phones ||
									data.contact.advertiser.company) && (
								<div
									onClick={this.toggleContact}
									className={styles.contactOpener}>
									<span onClick={this.openContacts}>
										<span className={styles.contactOpener}>
											zobrazit kontakty
										</span>
									</span>
								</div>
							)}
							<Social
								url={`${WWW_URL}/${this.props.params.id}/`}
								title={`${this.props.data.place} ${this.props.data.type}`}
							/>

							<Footer />
						</div>
					</div>
				)}

				{show404 && <Page404 title={errorMessage} noRedirect={noRedirect} />}

				<Overlay withSpinner shown={this.props.isLoading} />
			</div>
		)
	}
}

Detail.contextTypes = {
	router: PropTypes.object,
}

Detail.defaultProps = {
	error: false,
}

Detail.propTypes = {
	actions: PropTypes.object,
	data: PropTypes.object,
	loggedUser: PropTypes.object,
	isLoading: PropTypes.bool,
	params: PropTypes.object,
	isAuthenticated: PropTypes.bool,
	opencard: PropTypes.string,
	error: PropTypes.any,
	location: PropTypes.any,
	open: PropTypes.any,
}

export default Detail
