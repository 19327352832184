import { API_URL } from './AppConstants'

// -----------------------------------------------------------------------------
// URL constants
// -----------------------------------------------------------------------------

export const SETTINGS_URL = `${API_URL}/ajax/profil/`

export const VERIFICATION_EMAIL_URL = `${API_URL}/moje-reality/verifemail/`

export const CHANGE_PASSWORD_URL = `${API_URL}/moje-reality/zmenah2/`

// -----------------------------------------------------------------------------
// Action constants
// -----------------------------------------------------------------------------

export const SETTINGS_LOAD_START_ACTION = 'SETTINGS_LOAD_START_ACTION'

export const SETTINGS_LOAD_SUCCESS_ACTION = 'SETTINGS_LOAD_SUCCESS_ACTION'

export const SETTINGS_LOAD_FAILURE_ACTION = 'SETTINGS_LOAD_FAILURE_ACTION'

export const SETTINGS_SAVE_START_ACTION = 'SETTINGS_SAVE_START_ACTION'

export const SETTINGS_SAVE_SUCCESS_ACTION = 'SETTINGS_SAVE_SUCCESS_ACTION'

export const SETTINGS_SAVE_FAILURE_ACTION = 'SETTINGS_SAVE_FAILURE_ACTION'

export const SETTINGS_SEND_VER_EMAIL_ACTION = 'SETTINGS_SEND_VER_EMAIL_ACTION'

export const SETTINGS_CHANGE_PASSWORD_SUCCESS_ACTION =
	'SETTINGS_SEND_PASSWORD_SUCCESS'

// -----------------------------------------------------------------------------
// Settings constants
// -----------------------------------------------------------------------------

export const EMAIL_FREQUENCY = {
	NEVER: { label: 'Neposílat', value: 0 },
	IMMEDIATELY: { label: 'Okamžitě', value: 1 },
	DAILY: { label: 'Denně', value: 2 },
	WEEKLY: { label: 'Týdně', value: 3 },
}

export const ADVERTISER_TYPE = {
	NOT_SPECIFIED: { label: 'Neuvedeno', value: 0 },
	REAL_ESTATE_AGENCY: { label: 'Realitní kancelář', value: 1 },
	ESTATE_MANAGER: { label: 'Správce majetku', value: 2 },
	DEVELOPER: { label: 'Developer', value: 3 },
	ESTATE_OWNER: { label: 'Majitel nemovitosti', value: 4 },
	AUCTIONER: { label: 'Dražebník', value: 5 },
}

export const ADVERTISER_SHOW = {
	NOTHING: { label: 'Nic', value: 0 },
	NAME: { label: 'Jméno', value: 1 },
	PHONE: { label: 'Telefon', value: 2 },
	FIRM: { label: 'Firma', value: 3 },
}

export const ADS_DELETE_AFTER = {
	ONE_WEEK: { label: 'Po týdnu', value: 1 },
	TWO_WEEKS: { label: 'Po dvou týdnech', value: 2 },
	THREE_WEEKS: { label: 'Po třech týdnech', value: 3 },
}

export const VERIFICATION_STATUS = {
	NOT_VERIFIED: { label: 'Ne, nebyl', value: 0 },
	VERIFIED_BY_LINK: {
		label: 'Ano, byl odkliknut link ve verifikačním mailu',
		value: 1,
	},
	VERIFIED_BY_SN: {
		label: 'Ano, účet byl založen přihlášením přes Facebook nebo Google',
		value: 2,
	},
}

export const PUSH_NOTIFICATIONS = {
	DISABLED: { label: 'Vypnuto', value: '0' },
	ENABLED: { label: 'Zapnuto', value: '1' },
}
