import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Auth from '../component/Auth'
import { synchronizeData } from '../../../reducer/UserDataReducer'
import {
	login,
	register,
	logout,
	loginWithFacebook,
	loginWithGoogle,
} from '../../../reducer/AuthApiReducer'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators(
		{
			login,
			loginWithGoogle,
			loginWithFacebook,
			register,
			logout,
			synchronizeData,
		},
		dispatch
	),
})

const mapStateToProps = (state) => {
	return {
		loggedUser: state.authApi.get('loggedUser'),
		isAuthenticated: state.authApi.get('isAuthenticated'),
		error: state.authApi.get('error'),
		favs: state.userData.get('favs'),
		list: state.userData.get('list'),
	}
}

export default connect(
	mapStateToProps,
	mapActionCreators
)(Auth)
