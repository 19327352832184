import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './Information.module.scss'
import Table from '../Table'

class Information extends Component {
	constructor(props) {
		super(props)

		this.state = {
			fullsize: false,
		}
	}

	showMore = () => {
		this.setState({ fullsize: true })
	}

	render() {
		let { information } = this.props
		let list = this.state.fullsize ? information : information.slice(0, 4)

		return (
			<div className={styles.base}>
				<Table>
					{/*
           <tr>
           <td>
           Cena:
           </td>
           <td>
           <Price price={price} />
           </td>
           </tr>
           */}

					{list.map((v, k) => {
						return (
							<tr key={k}>
								<td>{v.key}</td>
								<td>{v.value}</td>
							</tr>
						)
					})}

					{/*
           this.state.fullsize && (
           <tr>
           <td>ID nabídky</td>
           <td>{idadvert}</td>
           </tr>
           ) */}
				</Table>
				{list.length < information.length && (
					<div onClick={this.showMore} className={styles.showmore}>
						Zobrazit více informací
					</div>
				)}
			</div>
		)
	}
}

Information.defaultProps = {}

Information.propTypes = {
	information: PropTypes.array,
}

export default Information
