import SearchFormKinds from './SearchFormKinds'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setKind } from '../../reducer/SearchConfigReducer'

const mapActionCreators = (dispatch) => ({
  actions: bindActionCreators({ setKind }, dispatch)
})

const mapStateToProps = (state) => ({
  kind: state.searchConfig.get('kind')
})

export default connect(mapStateToProps, mapActionCreators)(SearchFormKinds)
