import * as Immutable from 'immutable'
import { createAction, handleActions } from 'redux-actions'
import { getUrl } from '../utils/getApiUrl'
import {
	SETTINGS_LOAD_START_ACTION,
	SETTINGS_LOAD_SUCCESS_ACTION,
	SETTINGS_LOAD_FAILURE_ACTION,
	SETTINGS_SAVE_START_ACTION,
	SETTINGS_SAVE_SUCCESS_ACTION,
	SETTINGS_SAVE_FAILURE_ACTION,
	SETTINGS_SEND_VER_EMAIL_ACTION,
	SETTINGS_CHANGE_PASSWORD_SUCCESS_ACTION,
	VERIFICATION_EMAIL_URL,
	CHANGE_PASSWORD_URL,
	SETTINGS_URL,
} from '../constants/SettingsConstants'
import { sendAxiosGetRequest, sendAxiosPostRequest } from '../utils/fetcher'

// -----------------------------------------------------------------------------
// Initial state
// -----------------------------------------------------------------------------
const initialState = Immutable.fromJS({
	profile: null,
	error: null,
})

// -----------------------------------------------------------------------------
// Actions
// -----------------------------------------------------------------------------
const loadStart = createAction(SETTINGS_LOAD_START_ACTION)
const loadSuccess = createAction(SETTINGS_LOAD_SUCCESS_ACTION)
const loadFailure = createAction(SETTINGS_LOAD_FAILURE_ACTION)
const saveStart = createAction(SETTINGS_SAVE_START_ACTION)
const saveSuccess = createAction(SETTINGS_SAVE_SUCCESS_ACTION)
const saveFailure = createAction(SETTINGS_SAVE_FAILURE_ACTION)
const sendVerEmail = createAction(SETTINGS_SEND_VER_EMAIL_ACTION)
const changePasswordSuccess = createAction(
	SETTINGS_CHANGE_PASSWORD_SUCCESS_ACTION
)

function handleLoadResponse(response) {
	return response.err && response.err.length > 0
		? loadFailure(response)
		: loadSuccess(response)
}

function handleSaveResponse(response) {
	return response.err && response.err.length > 0
		? saveFailure(response)
		: saveSuccess(response)
}

function handleChangePasswordResponse(response) {
	return response.err && response.err.length > 0
		? loadFailure(response)
		: changePasswordSuccess(response)
}

// -----------------------------------------------------------------------------
// Side effects
// -----------------------------------------------------------------------------
export function loadSettings() {
	return async (dispatch) => {
		const endpoint = getUrl(SETTINGS_URL)
		dispatch(loadStart())

		try {
			const { data } = await sendAxiosPostRequest(endpoint)
			dispatch(handleLoadResponse(data))
			return data.err && data.err.length > 0
				? SETTINGS_LOAD_FAILURE_ACTION
				: SETTINGS_LOAD_SUCCESS_ACTION
		} catch (exception) {
			dispatch(loadFailure(exception))
			return SETTINGS_LOAD_FAILURE_ACTION
		}
	}
}

export function saveSettings(settings) {
	return async (dispatch) => {
		const endpoint = getUrl(SETTINGS_URL)
		dispatch(saveStart())

		try {
			const { data } = await sendAxiosPostRequest(endpoint, settings)
			dispatch(handleSaveResponse(data))
		} catch (exception) {
			dispatch(saveFailure(exception))
		}
	}
}

export function sendVerificationEmail() {
	return async (dispatch) => {
		const endpoint = getUrl(VERIFICATION_EMAIL_URL)

		try {
			await sendAxiosGetRequest(endpoint)
			dispatch(sendVerEmail())
		} catch (exception) {
			dispatch(loadFailure(exception))
		}
	}
}

export function changePassword({ oldPassword, newPassword }) {
	return async (dispatch) => {
		const endpoint = getUrl(CHANGE_PASSWORD_URL)

		try {
			const { data } = await sendAxiosPostRequest(endpoint, {
				mrregh: oldPassword,
				mrnewh: newPassword,
			})
			dispatch(handleChangePasswordResponse(data))
			return SETTINGS_CHANGE_PASSWORD_SUCCESS_ACTION
		} catch (exception) {
			dispatch(loadFailure(exception))
			return null
		}
	}
}

// -----------------------------------------------------------------------------
// Action handlers
// -----------------------------------------------------------------------------
export default handleActions(
	{
		[SETTINGS_LOAD_START_ACTION]: (state) => {
			return state.merge({
				profile: null,
				error: null,
			})
		},

		[SETTINGS_LOAD_SUCCESS_ACTION]: (state, action) => {
			return state.merge({
				profile: action.payload.profile,
				error: null,
			})
		},

		[SETTINGS_LOAD_FAILURE_ACTION]: (state, action) => {
			return state.merge({
				error: action.payload.err,
			})
		},

		[SETTINGS_SAVE_START_ACTION]: (state) => {
			return state.merge({
				error: null,
			})
		},

		[SETTINGS_SAVE_SUCCESS_ACTION]: (state, action) => {
			return state.merge({
				profile: action.payload.profile,
				error: null,
			})
		},

		[SETTINGS_SAVE_FAILURE_ACTION]: (state, action) => {
			return state.merge({
				error: action.payload.err,
			})
		},

		[SETTINGS_CHANGE_PASSWORD_SUCCESS_ACTION]: (state) => {
			return state
		},
	},
	initialState
)
