import DeleteAccount from './DeleteAccount'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { deleteAccount } from '../../reducer/AuthApiReducer'
import { closeOpenCard } from '../../reducer/OpenCardReducer'

const mapActionCreators = (dispatch) => ({
	actions: bindActionCreators(
		{
			closeOpenCard,
			deleteAccount,
		},
		dispatch
	),
})

const mapStateToProps = (state) => ({
	opencard: state.openCard.get('openCard'),
	error: state.authApi.get('error'),
})

export default connect(
	mapStateToProps,
	mapActionCreators
)(DeleteAccount)
