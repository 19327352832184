import React from 'react'
import PropTypes from 'prop-types'
import styles from './VerifyAccount.module.scss'
import Page from '../../../component/Page'
import PageTitle from '../../../component/PageTitle'
import Row from '../../../component/Row'
import Col from '../../../component/Col'

class VerifyAccount extends React.Component {
	constructor(props) {
		super(props)

		this.state = {}
	}

	componentDidMount() {
		const { verificationToken } = this.props.routeParams
		this.props.actions.verifyAccount(verificationToken)
	}

	render() {
		let content = null
		let iconClass
		let iconStyleClass
		if (!this.props.isBeingVerified) {
			content = this.props.verificationError
				? this.props.verificationError
				: 'Účet byl úspešne oveřen.'
			iconClass = this.props.verificationError ? 'fa fa-times' : 'fa fa-check'
			iconStyleClass = this.props.verificationError ? 'success' : 'fail'
		}

		return (
			<Page>
				<PageTitle>Verifikace</PageTitle>

				<Row>
					<Col xs="12/12">
						<div className={styles['verification-content']}>
							{content}
							<div>
								<i className={`${iconClass} ${styles[iconStyleClass]}`} />
							</div>
						</div>
					</Col>
				</Row>
			</Page>
		)
	}
}

VerifyAccount.propTypes = {
	isBeingVerified: PropTypes.bool,
	verificationError: PropTypes.string,
	actions: PropTypes.shape({
		verifyAccount: PropTypes.func,
	}),
	routeParams: PropTypes.shape({
		verificationToken: PropTypes.string,
	}),
}

export default VerifyAccount
