import Menu from './Menu'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reset } from '../../reducer/SearchConfigReducer'

const mapActionCreators = (dispatch) => ({
  actions: bindActionCreators({ reset }, dispatch)
})

const mapStateToProps = (state) => ({
  listSize: state.userData.get('listSize'),
  favsSize: state.userData.get('favsSize'),
  updatedFavsSize: state.userData.get('updatedFavsSize'),
  newOffersSize: state.userData.get('newOffersSize'),
  isAuthenticated: state.authApi.get('isAuthenticated'),
  loggedUser: state.authApi.get('loggedUser')
})

export default connect(mapStateToProps, mapActionCreators)(Menu)

