import Search from './container/SearchContainer'
export default Search


// export default (store) => ({
//   path: '/search', // Path should be dash-lower-case
//   getComponent (nextState, next) {
//     require.ensure(
//       ['./container/SearchContainer'],
//       (require) => {
//         // Don't forget to add router to `src/route/index`
//         const Search = require('./container/SearchContainer').default

//         next(null, Search)
//       })
//   }
// })
