import React from 'react'
import styles from './BottomLinks.module.scss'
import { trackGoogleAnalytics } from '../../App'
import { WWW_URL, MOBILE_APP_LINK } from '../../constants/AppConstants'

const BottomLinks = () => {
	let url2app = MOBILE_APP_LINK
	let device = 'unknown device'

	const ref = ''

	const userAgent =
		window.navigator &&
		(window.navigator.userAgent || window.navigator.vendor || window.opera)
	device =
		userAgent.match(/iPad/i) ||
		userAgent.match(/iPhone/i) ||
		userAgent.match(/iPod/i)
			? 'iOS'
			: userAgent.match(/Android/i)
			? 'android'
			: userAgent.match(/iemobile/i)
			? 'wPhone'
			: ''

	switch (device) {
		case 'iOS':
			url2app = 'https://itunes.apple.com/cz/app/apple-store/id397680650?mt=8'
			break
		case 'android':
			url2app =
				'https://play.google.com/store/apps/details?id=cz.ulikeit.reality'
			break
		case 'wPhone':
			url2app =
				'https://www.microsoft.com/en-us/store/apps/realitycz/9nblggh081rt'
			break
		default:
			break
	}

	return (
		<div className={styles.base}>
			<a
				href={WWW_URL + ref}
				onClick={() => {
					document.cookie = 'showfull=1; domain=reality.cz; path=/'
					trackGoogleAnalytics(['send', 'event', 'menu', 'desktop'])
				}}>
				Zobrazit desktop verzi{' '}
			</a>
			<a
				href={url2app}
				target="_blank"
				rel="noopener noreferrer"
				onClick={() => {
					trackGoogleAnalytics([
						'send',
						'event',
						'menu',
						'stahnout ' + device + ' menu',
					])
				}}>
				Stáhnout aplikaci Reality.cz
			</a>
		</div>
	)
}

BottomLinks.defaultProps = {}

BottomLinks.propTypes = {}

export default BottomLinks
