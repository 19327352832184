import React from 'react'
import PropTypes from 'prop-types'
import styles from './AdvertiserImage.module.scss'
import Title from '../Title'
import { WWW_URL } from '../../constants/AppConstants'

function AdvertiserImage(props) {
	return (
		<div className={styles.base}>
			{props.data.broker && (
				<div type={props.type}>
					{props.data.broker.photo && (
						<img
							src={WWW_URL + props.data.broker.photo}
							className={styles.logo}
							alt={props.data.broker.name}
						/>
					)}

					{!props.data.broker.photo && props.data.real_estate.logo && (
						<img
							src={WWW_URL + props.data.real_estate.logo}
							className={styles.logo}
							alt={props.data.real_estate.name}
						/>
					)}

					<div>
						<Title type="h2" display="block">
							{props.data.broker.name}
						</Title>

						{props.data.real_estate.name && (
							<Title type="p2" display="block">
								{props.data.real_estate.name}
							</Title>
						)}

						{!props.data.real_estate.name && (
							<Title type="p2" display="block">
								{props.data.broker.title}
							</Title>
						)}
					</div>
				</div>
			)}

			{!props.data.broker && props.data.real_estate && (
				<div type={props.type}>
					{props.data.real_estate.logo && (
						<img
							src={WWW_URL + props.data.real_estate.logo}
							className={styles.logo}
							alt={props.data.real_estate.name}
						/>
					)}

					<div>
						<Title type="h2" display="block">
							{props.data.real_estate.title}
						</Title>
						<Title type="p2" display="block">
							{props.data.real_estate.name}
						</Title>
					</div>
				</div>
			)}

			{props.data.advertiser && (
				<div type={props.type}>
					{props.data.advertiser.photo && (
						<img
							src={WWW_URL + props.data.advertiser.photo}
							className={styles.logo}
							alt={props.data.advertiser.name}
						/>
					)}

					<div>
						{props.data.advertiser.title &&
							props.data.advertiser.title !== 'Nemovitost nabízí' && (
								<Title type="h2" display="block">
									{props.data.advertiser.title}
								</Title>
							)}

						{props.data.advertiser.company && (
							<Title type="h2" display="block">
								{props.data.advertiser.company}
							</Title>
						)}

						<Title type="p2" display="block">
							{(props.data.advertiser.has_name && props.data.advertiser.name) ||
								''}
						</Title>
					</div>
				</div>
			)}
		</div>
	)
}

AdvertiserImage.defaultProps = {}

AdvertiserImage.propTypes = {
	data: PropTypes.object.isRequired,
	type: PropTypes.oneOf(['inRows', 'inColumns']),
}

export default AdvertiserImage
