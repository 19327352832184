import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetPassword } from '../../../reducer/AuthApiReducer'
import ForgottenPassword from '../component/ForgottenPassword'

const mapActionCreators = (dispatch) => ({
  actions: bindActionCreators({ resetPassword }, dispatch)
})

const mapStateToProps = (state) => ({
  isAuthenticated: state.authApi.get('isAuthenticated'),
  error: state.authApi.get('resetPasswordError')
})

export default connect(mapStateToProps, mapActionCreators)(ForgottenPassword)
