import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './Add2Favs.module.scss'
import { trackGoogleAnalytics } from '../../App'

class Add2Favs extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isInFavs: this.isIncludedInFavourites(this.props.data.id),
		}
	}

	/**
	 * If user is authenticated the request will be send to the backend.
	 * Otherwise item will be saved to favs in localStorage.
	 */
	saveToFavs = (item) => {
		if (this.props.isAuthenticated) {
			this.props.actions.saveToFavs(item)
		} else {
			this.props.actions.saveToFavsLocally(item)
		}

		if (!this.isIncludedInFavourites(item.id)) {
			trackGoogleAnalytics([
				'send',
				'event',
				this.props.parent,
				'Ulozeni nabidky',
			])
		}
		this.setState((prevState) => ({
			isInFavs: !prevState.isInFavs,
		}))
	}

	isIncludedInFavourites = (id) => {
		return this.props.favs.some((v) => v.id === id)
	}

	render() {
		return (
			<div className={styles.base}>
				<div
					onClick={() => {
						this.saveToFavs(this.props.data)
					}}
					className={this.state.isInFavs ? styles.selected : styles.star}
					alt="do oblibenych"
				/>
			</div>
		)
	}
}

Add2Favs.defaultProps = {
	parent: 'unknown',
}

Add2Favs.propTypes = {
	parent: PropTypes.string,
	favs: PropTypes.any,
	data: PropTypes.object,
	isAuthenticated: PropTypes.bool,
	actions: PropTypes.object,
}

export default Add2Favs
